import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { InterviewsFullScreenModal } from '../../components/interview2/common';
import { InterviewScriptDocumentEditor } from '../../components/interviewScript/InterviewScriptDocumentEditor';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { ReactComponent as InterviewScriptIcon } from '../../icons/script.svg';
import { documentsService, EditorDocument } from '../../services/documentsService';
import { interviewScripts2Service } from '../../services/interviewScripts2Service';

export const InterviewScriptViewModal = ({
    ideaId,
    scriptId,
    scriptName,
    onClose
}: {
    ideaId: string;
    scriptId: number;
    scriptName: string;
    onClose: () => void;
}) => {
    const [document, setDocument] = useState<EditorDocument | null>(null);

    useEffect(() => {
        const loadScriptDocument = async () => {
            const script = await interviewScripts2Service.getInterviewScript(ideaId, scriptId);
            if (!script.documentId) return;
            const document = await documentsService.getDocument(ideaId, script.documentId);
            setDocument(document);
        };

        loadScriptDocument();
    }, [ideaId, scriptId]);

    const loading = document === null;
    return (
        <InterviewsFullScreenModal title={scriptName} icon={InterviewScriptIcon} onClose={onClose}>
            {loading ? (
                <StackLayout align={{ horizontal: 'center', vertical: 'middle' }}>
                    <LoadingIndicator size="big" className="k-centered" />
                </StackLayout>
            ) : (
                <div className="page-content-middle page-content--xxl k-h-full document-editor-no-overflow">
                    <InterviewScriptDocumentEditor ideaId={ideaId} editorDocument={document} readonly />
                </div>
            )}
        </InterviewsFullScreenModal>
    );
};
