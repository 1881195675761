import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar, DialogCloseEvent } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactElement } from 'react';
import { GraphicData } from '../../services/common';
import { ModalData } from '../../state/ui/modalSlice';
import { H1 } from '../ui/typography';

export function NoTitleModal({
    data,
    onMainAction,
    onClose,
    mainButton,
    btnsDisabled,
    noCloseIcon
}: {
    data: ModalData;
    onMainAction?: () => void;
    onClose?: () => void;
    mainButton?: ReactElement;
    btnsDisabled?: boolean;
    noCloseIcon?: boolean;
}) {
    return (
        <Dialog title=" " className="k-icp-dialog-no-title" width={480} onClose={onClose} closeIcon={!noCloseIcon}>
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-text-center k-mx-8 k-mb-4">
                <img src={data.graphic.url} width={data.graphic.width} height={data.graphic.height} alt={data.graphic.description} className="k-mb-4" />
                <H1 className="!k-mb-3">{data.heading}</H1>
                <div>{data.description}</div>
            </StackLayout>

            <DialogActionsBar layout="center">
                {mainButton || (
                    <Button type="button" themeColor="primary" onClick={onMainAction} disabled={btnsDisabled}>
                        {data.mainButtonText}
                    </Button>
                )}
                <Button type="button" onClick={onClose} disabled={btnsDisabled}>
                    {data.cancelButtonText}
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}

export function GraphicModal({ heading, graphic, onClose }: { heading: string; graphic: GraphicData; onClose?: () => void }) {
    return (
        <Dialog title=" " className="k-icp-dialog-no-title" contentStyle={{ padding: 0 }} onClose={onClose}>
            <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-mx-16 k-mb-12 k-gap-8">
                <H1>{heading}</H1>
                <img src={graphic.url} width={graphic.width} height={graphic.height} alt={graphic.description} />
            </StackLayout>
        </Dialog>
    );
}

export function preventCloseOfParentDialog(e: DialogCloseEvent) {
    if (e.syntheticEvent.type === 'keydown') e.syntheticEvent.stopPropagation();
}
