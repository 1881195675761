import { Avatar, AvatarProps } from '@progress/kendo-react-layout';
import { ReactComponent as UserIcon } from '../../icons/user.svg';

export default function UserAvatar({
    picture,
    initials,
    colorIndex = 0,
    className,
    empty,
    size
}: {
    picture?: string | null;
    initials?: string;
    colorIndex?: number;
    className?: string;
    empty?: boolean;
    size?: AvatarProps['size'];
}) {
    if (picture) {
        return (
            <Avatar className={className} rounded="full" type="image" themeColor="base" border={true} size={size}>
                <img src={picture} alt="avatar" referrerPolicy="no-referrer" />
            </Avatar>
        );
    }

    if (empty) {
        return (
            <Avatar className={`${className || ''} k-icp-bg-hover-bg`} border rounded="full" type="text" themeColor="base" size={size}>
                <UserIcon className="k-icp-icon" />
            </Avatar>
        );
    }

    return (
        <Avatar className={`${className || ''} k-icp-avatar-simple-bg-${colorIndex}`} rounded="full" type="text" themeColor="base" size={size}>
            {initials}
        </Avatar>
    );
}
