import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { PopupPropsContext } from '@progress/kendo-react-popup';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AILedChatComponent } from '../../components/ai/aiLedChat';
import { ChatMessageSimple, ChatPreview } from '../../components/ai/chatView';
import { BoundDropDownButton, DropDownButtonItem } from '../../components/common/boundDropDownButton';
import { InlineTimer } from '../../components/common/inlineTimer';
import { ScriptQuestionCard, ScriptQuestionsSection, ScriptSection, StickyStamp } from '../../components/interview2/common';
import { StartupMembershipList } from '../../components/startup/startupMembershipList';
import { ExtendedRichTextEditor } from '../../components/ui/ExtendedRichTextEditor';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { ChatMessage, ChatMessageBlockType, ChatMessageType } from '../../services/chatsService';
import { combineClassNames } from '../../services/common';
import { ReducedUserViewModel } from '../../services/usersService';
import { MockFollowUpMessages, MockHumanLedChatMessages, MockNotes, MockScriptPendingSections, UserRole } from './mockData';

export type InterviewPageData = {
    interviewTitle: string;
    isAILedInterview: boolean;
    interviewStatus: 'listening' | 'reconnecting' | 'notListening';
    userRole: UserRole;
};

const mockMessages: ChatMessage[] = [
    {
        id: 1,
        type: ChatMessageType.User,
        createdOn: new Date('2024-03-20T10:00:00'),
        user: { userId: '1', emailAddress: 'john.doe@example.com', firstName: 'John', lastName: 'Doe' },
        content: 'Hi!'
    },
    {
        id: 2,
        type: ChatMessageType.Agent,
        createdOn: new Date('2024-03-20T10:00:00'),
        blocks: [{ type: ChatMessageBlockType.Text, data: "Hello! I will be conducting your interview today. Let's start with your background." }]
    },
    {
        id: 3,
        type: ChatMessageType.User,
        createdOn: new Date('2024-03-20T10:01:00'),
        user: { userId: '1', emailAddress: 'john.doe@example.com', firstName: 'John', lastName: 'Doe' },
        content: 'Hi! I have 5 years of experience in frontend development.'
    },
    {
        id: 4,
        type: ChatMessageType.Agent,
        createdOn: new Date('2024-03-20T10:02:00'),
        blocks: [
            {
                type: ChatMessageBlockType.Text,
                data:
                    "Great! Could you tell me about a challenging project you worked on recently? What were the main technical challenges you faced and how did you overcome them? I'd also love to hear about what you learned from that experience."
            }
        ]
    }
];

const mockUserViewerList: ReducedUserViewModel[] = [
    { userId: '1', emailAddress: 'john.doe@example.com', firstName: 'John', lastName: 'Doe', initials: 'JD', colorIndex: 5 },
    { userId: '2', emailAddress: 'jane.doe@example.com', firstName: 'Jane', lastName: 'Doe', initials: 'JD', colorIndex: 3 }
];

const MockInterviewPageProps: InterviewPageData = {
    interviewTitle: 'Interview Title',
    interviewStatus: 'notListening',
    isAILedInterview: false,
    userRole: 'interviewee'
};

export function InterviewV2ModalPage() {
    const dialogRef = useRef<Dialog>(null);
    const navigate = useNavigate();
    const { ideaId } = useParams();

    return (
        <PopupPropsContext.Provider value={p => (dialogRef.current?.element ? { ...p, appendTo: dialogRef.current?.element } : p)}>
            <Dialog
                ref={dialogRef}
                className="k-icp-dialog-maximized k-icp-dialog-no-padding k-icp-dialog-with-title-shadow"
                title={
                    <InterviewTitleBar
                        ideaId={ideaId}
                        viewerList={mockUserViewerList}
                        interviewTitle={MockInterviewPageProps.interviewTitle}
                        IVAStatus={MockInterviewPageProps.interviewStatus}
                    />
                }
                onClose={() => navigate('./../../')}
            >
                {MockInterviewPageProps.isAILedInterview ? (
                    <AILedChatComponent readonly={MockInterviewPageProps.userRole === 'viewer'} addTalking addListening messages={mockMessages} />
                ) : (
                    <HumanLedChat transcript={MockHumanLedChatMessages} scriptQuestions={MockScriptPendingSections} notesTxt={MockNotes.text} />
                )}
            </Dialog>
        </PopupPropsContext.Provider>
    );
}

function HumanLedChat({
    transcript,
    scriptQuestions,
    notesTxt
}: {
    transcript: ChatMessageSimple[] | string | undefined;
    scriptQuestions: ScriptSection[];
    notesTxt: string;
}) {
    const transcriptPanel = (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'stretch', vertical: 'top' }}
            className="k-px-4 k-pb-4 k-icp-panel-base k-h-full k-overflow-auto"
        >
            <StickyStamp text="Interview transcript" />
            <InterviewTranscriptPanel />
        </StackLayout>
    );

    const scriptPanel = (
        <div className="k-px-4 k-pb-4">
            <StickyStamp text="Script questions" />
            <StackLayout orientation="vertical" className="k-gap-4">
                {scriptQuestions.map((section, index) => (
                    <ScriptQuestionsSection key={index} section={section}>
                        {section.questions.map((question, index) => (
                            <ScriptQuestionCard key={index} status={question.status} text={question.text} scratchAnswered />
                        ))}
                    </ScriptQuestionsSection>
                ))}
            </StackLayout>
        </div>
    );

    const followUpQuestionPanel = (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'stretch', vertical: 'top' }}
            className={'k-px-4 k-pb-4 k-overflow-auto k-h-full k-icp-panel-base k-icp-component-border k-border-b k-border-b-solid'}
        >
            <StickyStamp text="Follow up questions" />
            <FollowUpQuestionPanel />
        </StackLayout>
    );

    const notesPanel = (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-h-full k-overflow-auto">
            <StickyStamp text="Notes" />
            <NotesEditor notesTxt={notesTxt} />
        </StackLayout>
    );

    return <HumanLedChat4PanelsLayout panel1={transcriptPanel} panel2={scriptPanel} panel3={followUpQuestionPanel} panel4={notesPanel} />;
}

function HumanLedChat4PanelsLayout({
    panel1,
    panel2,
    panel3,
    panel4
}: {
    panel1: React.ReactNode;
    panel2: React.ReactNode;
    panel3: React.ReactNode;
    panel4: React.ReactNode;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} className="k-h-full k-icp-component-border k-border-x k-border-x-solid ">
            <div className="k-flex-1 k-overflow-auto">{panel1}</div>
            <div className="k-overflow-auto k-flex-1 k-icp-component-border k-icp-panel-base k-border-r k-border-r-solid k-border-l k-border-l-solid">
                {panel2}
            </div>
            <StackLayout className="k-flex-1" orientation="vertical" align={{ horizontal: 'stretch', vertical: 'stretch' }}>
                <div className="k-flex-1 k-overflow-auto">{panel3}</div>
                <div className="k-flex-1 k-overflow-auto">{panel4}</div>
            </StackLayout>
        </StackLayout>
    );
}

//TODO R4: Forbid pasting images?
function NotesEditor({ notesTxt }: { notesTxt: string }) {
    return (
        <ExtendedRichTextEditor
            defaultContent={`<p>${notesTxt}</p>`}
            placeholderText="Type your notes here..."
            contentStyle={{ display: 'flex', flexDirection: 'column' }}
            className="interview-notes-editor !k-border-0"
            defaultEditMode="div"
        />
    );
}

function InterviewTranscriptPanel() {
    const [transcript, setTranscript] = useState<ChatMessageSimple[] | string | undefined | null>(undefined);

    useEffect(() => {
        //TODO R4: remove mock data
        const timer = setTimeout(() => {
            setTranscript(MockHumanLedChatMessages);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {transcript === undefined ? (
                <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                    <LoadingIndicator size="big" />
                </StackLayout>
            ) : transcript === null ? (
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-flex-1">
                    <div className="k-fs-sm k-icp-subtle-text k-text-center">
                        Real-time transcript of the meeting will appear here as the conversation progresses. Ensure your microphone is clear for accurate
                        transcription.
                    </div>
                </StackLayout>
            ) : typeof transcript === 'string' ? (
                <div className="k-white-space-pre-wrap k-fs-sm">{transcript}</div>
            ) : (
                <ChatPreview messages={transcript} />
            )}
        </>
    );
}

function FollowUpQuestionPanel() {
    const [followUpQuestions, setFollowUpQuestions] = useState<ChatMessageSimple[] | null | undefined>(undefined);

    useEffect(() => {
        //TODO R4: remove mock data
        const timer = setTimeout(() => {
            setFollowUpQuestions(MockFollowUpMessages);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {followUpQuestions === undefined ? (
                <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                    <LoadingIndicator size="big" />
                </StackLayout>
            ) : followUpQuestions === null ? (
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-flex-1">
                    <div className="k-fs-sm k-icp-subtle-text k-text-center">
                        IVA is actively listening to the conversation and will assist with any follow-up questions. They will appear here in real-time during
                        the meeting.
                    </div>
                </StackLayout>
            ) : (
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'bottom' }} className="k-flex-1">
                    <ChatPreview messages={followUpQuestions} />
                </StackLayout>
            )}
        </>
    );
}

//TODO R4 implement IVA is listening picker
function InterviewTitleBar({
    ideaId,
    interviewTitle,
    viewerList,
    IVAStatus
}: {
    ideaId?: string;
    interviewTitle: string;
    viewerList?: ReducedUserViewModel[];
    IVAStatus?: 'listening' | 'reconnecting' | 'notListening';
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-flex-1 k-mr-2">
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-flex-1">
                <div className="k-text-ellipsis">{interviewTitle}</div>
                {IVAStatus && <IVAStatusButton state={IVAStatus} />}
            </StackLayout>
            <StackLayout align={{ horizontal: 'end', vertical: 'middle' }} className="k-flex-1 k-gap-4 k-fs-md k-font-normal k-icp-component-border">
                <InterviewQuestionsCounter askedQuestionNum={0} totalQuestions={57} />
                <div className="k-separator" />
                {ideaId && <InlineTimer ideaId={ideaId} timerId={1} readonly hideActions hideSeconds />}
                <div className="k-separator" />
                {viewerList && <StartupMembershipList users={viewerList} />}
                <Button type="button" fillMode="outline" themeColor="secondary" onClick={() => console.log('TODO R4 SHOW Dialog')}>
                    Finish Interview
                </Button>
            </StackLayout>
        </StackLayout>
    );
}

const IVAStatusButton = ({ state }: { state: 'listening' | 'reconnecting' | 'notListening' }) => {
    const items: DropDownButtonItem[] = [];

    if (state === 'reconnecting' || state === 'listening') {
        items.push({ text: 'Remove IVA', action: () => console.log('TODO R4 Remove IVA') });
    }

    if (state === 'notListening') {
        items.push({ text: 'Rejoin IVA', action: () => console.log('TODO R4 Rejoin IVA') });
    }

    if (state === 'reconnecting' || state === 'notListening') {
        items.push({ text: 'Change Meeting ID', action: () => console.log('TODO R4 Change Meeting ID') });
    }

    return (
        <BoundDropDownButton
            themeColor="base"
            fillMode="outline"
            items={items}
            text={<IVAStatusButtonContent state={state} />}
            icon="arrow-60-down"
            buttonClass="k-flex-row-reverse"
        />
    );
};

const IVAStatusButtonContent = ({ state }: { state: 'listening' | 'reconnecting' | 'notListening' }) => {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
            <span
                className={combineClassNames(
                    'k-rounded-full k-m-1',
                    state === 'listening' ? 'k-bg-success' : state === 'reconnecting' ? 'k-bg-warning' : 'k-bg-error'
                )}
                style={{
                    width: '8px',
                    height: '8px',
                    display: 'inline-block'
                }}
            />
            <span>{state === 'listening' ? 'IVA is listening' : state === 'reconnecting' ? 'IVA is reconnecting' : 'IVA is not listening'}</span>
        </StackLayout>
    );
};

export function InterviewQuestionsCounter({ askedQuestionNum, totalQuestions }: { askedQuestionNum: number; totalQuestions: number }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
            <span className="k-mr-1 k-icp-subtle-text">Counter:</span>
            <strong>
                {askedQuestionNum}/{totalQuestions}
            </strong>
            <span className="k-icp-subtle-text">questions</span>
        </StackLayout>
    );
}
