import { ChatMessageSimple } from '../../components/ai/chatView';
import { ScriptSection } from '../../components/interview2/common';
import { ChatMessageType } from '../../services/chatsService';

export enum InterviewStatus {
    InProgress = 'inProgress',
    NotStarted = 'notStarted',
    Completed = 'completed'
}

export type UserRole = 'viewer' | 'interviewee';

export const MockScriptPendingSections: ScriptSection[] = [
    {
        title: 'Greet and Ask For Permission to Record (5 min)',
        answeredQuestions: 2,
        totalQuestions: 2,
        questions: [
            {
                status: 'answered',
                text:
                    "Thank you very much for taking the time to speak with me today. I'm really excited to chat with you about your experience with teaching your kids real-life programming."
            },
            {
                status: 'answered',
                text:
                    "Before we get started... Do you mind if I record our conversation? This will help make sure I don't miss any important details. The recording will only be used by me and my team, and won't be shared with anyone else. Would that be ok?"
            }
        ]
    },
    {
        title: 'Clarify the Goal of the Interview (5 min)',
        answeredQuestions: 1,
        totalQuestions: 1,
        questions: [
            {
                status: 'answered',
                text:
                    "As I have already shared with you, we are conducting initial research around how and when people need or want to do something. Specifically, I'm interested in learning about the methods and resources you use, including alternative solutions you may have tried. We want to improve our understanding of the customer experience and potentially find ways to make it less of a pain. We are just starting and are not here to pitch anything or sell anything. Our objective is to learn from you and uncover your story as if we are shooting a documentary. Does that make sense?"
            }
        ]
    },
    {
        title: 'Uncover What They Hire to Get the Job Done',
        answeredQuestions: 4,
        totalQuestions: 5,
        questions: [
            { status: 'default', text: 'When was the last time you wanted or needed to teach your kids everything you know about programming?' },
            { status: 'default', text: 'What happened to trigger that want/need?' },
            { status: 'default', text: 'What did you use to teach your kids then?' },
            { status: 'default', text: 'What else do you currently use to teach your kids programming?' },
            { status: 'default', text: 'What else did you use in the past (chronologically)?' }
        ]
    }
];

const generateCompletedSections = (sections: ScriptSection[]): ScriptSection[] => {
    return sections.map(section => ({
        ...section,
        questions: section.questions.map(question => ({
            ...question,
            // Map 'default' status to 'unanswered', keep 'answered' as is, and anything else becomes 'unanswered'
            status: question.status === 'answered' ? 'answered' : 'unanswered'
        }))
    }));
};

export const MockScriptCompletedSections = generateCompletedSections(MockScriptPendingSections);

export const MockHumanLedChatMessages: ChatMessageSimple[] = [
    {
        id: 1,
        type: ChatMessageType.User,
        createdOn: new Date('2024-03-20T10:00:00'),
        timeSeconds: 2,
        content: "Hi! I'm your AI interviewer today. Could you tell me a bit about your background and experience?",
        user: { userId: '3', emailAddress: 'joanna.jane@gmail.com', firstName: 'Joanna', lastName: 'Jane' }
    },
    {
        id: 2,
        type: ChatMessageType.User,
        createdOn: new Date('2024-03-20T10:01:00'),
        timeSeconds: 8,
        user: { userId: '1', emailAddress: 'john.doe@example.com', firstName: 'John', lastName: 'Doe' },
        content: 'Hi! I have 5 years of experience in frontend development.'
    },
    {
        id: 3,
        type: ChatMessageType.User,
        createdOn: new Date('2024-03-20T10:02:00'),
        timeSeconds: 25,
        user: { userId: '3', emailAddress: 'joanna.jane@gmail.com', firstName: 'Joanna', lastName: 'Jane' },
        content:
            'Great! Could you tell me about a challenging project you worked on recently? What were the main technical challenges you faced and how did you overcome them?'
    },
    {
        id: 4,
        type: ChatMessageType.User,
        createdOn: new Date('2024-03-20T10:03:00'),
        timeSeconds: 57,
        user: { userId: '1', emailAddress: 'john.doe@example.com', firstName: 'John', lastName: 'Doe' },
        content:
            'Recently, I led the migration of our legacy Angular application to React. The main challenge was ensuring a smooth transition while maintaining functionality.'
    },
    {
        id: 5,
        type: ChatMessageType.User,
        createdOn: new Date('2024-03-20T10:04:00'),
        timeSeconds: 78,
        user: { userId: '3', emailAddress: 'joanna.jane@gmail.com', firstName: 'Joanna', lastName: 'Jane' },

        content: 'That sounds interesting! Could you elaborate on your approach to the migration? How did you manage the transition period?'
    },
    {
        id: 6,
        type: ChatMessageType.User,
        createdOn: new Date('2024-03-20T10:05:00'),
        timeSeconds: 99,
        user: { userId: '1', emailAddress: 'john.doe@example.com', firstName: 'John', lastName: 'Doe' },
        content:
            'We took an incremental approach, migrating component by component. We used a micro-frontend architecture to run both frameworks simultaneously during the transition.'
    }
];

export const MockFollowUpMessages: ChatMessageSimple[] = [
    {
        id: 7,
        type: ChatMessageType.Agent,
        createdOn: new Date('2024-03-20T10:06:00'),
        timeSeconds: 120,
        content: 'Could you share more details about the specific challenges you faced during the component migration process?'
    },
    {
        id: 8,
        type: ChatMessageType.Agent,
        createdOn: new Date('2024-03-20T10:07:00'),
        timeSeconds: 141,
        content: 'How did you ensure the micro-frontend architecture maintained consistent user experience across both frameworks?'
    },
    {
        id: 9,
        type: ChatMessageType.Agent,
        createdOn: new Date('2024-03-20T10:08:00'),
        timeSeconds: 162,
        content: 'What metrics or KPIs did you use to measure the success of the migration process?'
    }
];

export const MockNotes = {
    id: 123,
    text:
        "The interview provided valuable insights into user needs and pain points.\n\nThe participant shared detailed experiences with the current solution and highlighted several areas for improvement.\n\nThey emphasized the importance of having an intuitive interface and faster response times.\n\nThe discussion revealed unexpected use cases that we hadn't considered in our initial design.\n\nThe participant's background in the industry added credibility to their feedback and suggestions.\n\nTheir enthusiasm for the proposed new features was particularly encouraging."
};

export const MockInterviewText = `Thank you very much for taking the time to speak with me today. I'm really excited to chat with you about your experience in teaching your kids everything you know about programming.

will only be used by me and my team, and won’t be shared with anyone else. Would that be ok?

Go ahead!

Now that we are on the record, let’s jump straight to the point.

As I have already shared with you, we are conducting some initial research around how and when people need or want to teach their kids everything they know about programming. Specifically, I’m interested in learning about what methods and resources you use, including any alternative solutions you may have tried. We want to improve our understanding of the customer experience and potentially find ways to make it less of a pain. We are just starting and are not here to pitch anything to you or sell anything. Our objective is to learn from you and uncover your raw story as if we are shooting a documentary.

Does that make sense?

Yes, of course!

When was the last time you wanted or needed to teach your kids everything you know about programming?
`;
