import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { AIAvatar } from '../../components/ai/aiAvatar';
import { AILedChatComponent } from '../../components/ai/aiLedChat';
import { useInvisibleReCaptcha } from '../../components/common/invisibleReCaptcha';
import { DonutProgress } from '../../components/ui/donutProgress';
import { H1 } from '../../components/ui/typography';
import { useChatActions } from '../../hooks/chatHooks';
import { ResponsiveGroup, useResponsiveLayout, useSingleClickCallback } from '../../hooks/commonHooks';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import interviewIllustrationUrl from '../../images/interview-illustration-2.svg';
import { ReactComponent as IcanpreneurLogo } from '../../images/logo.svg';
import {
    AgentChatMessage,
    Chat,
    ChatMessage,
    ChatMessageBlock,
    ChatMessageBlockType,
    ChatMessageBlockTypeMap,
    ChatMessageType,
    publicChatsService
} from '../../services/chatsService';
import { combineClassNames } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { NotFoundException } from '../../services/httpServiceBase';
import { InterviewStageV2, PublicInterview, publicInterviewsService } from '../../services/interviewsV2Service';

// TODO: update interview progress in state after initialization if first message has update progress block
// TODO: Update interview progress in state and forceExecutionView based on message real-time updates (messages with update progress block)
// TODO: Close chat on force finish interview??
export function AILedInterviewPage() {
    const { publicCode } = useParams();
    if (!publicCode) throw new NotFoundException();

    const [interview, setInterview] = useState<PublicInterview>();
    const [messages, setMessages] = useState<ChatMessage[]>();

    const [forceExecutionView, setForceExecutionView] = useState<boolean>();
    const responsiveGroup = useResponsiveLayout();
    const isMobile = responsiveGroup === ResponsiveGroup.xs;
    const isTablet = isMobile || responsiveGroup === ResponsiveGroup.sm;
    const getReCaptchaToken = useInvisibleReCaptcha();

    const interviewChatTag = interview?.detail?.chat?.tag;
    const setChat = useCallback((chat: Chat) => setInterview(i => (i && i.detail ? { ...i, detail: { ...i.detail, chat: chat } } : i)), []);
    const initializeChat = useCallback(() => {
        if (!interviewChatTag) throw new Error();
        return publicChatsService.initializeChat(interviewChatTag);
    }, [interviewChatTag]);
    const getAllChatMessages = useCallback(async () => {
        if (!interviewChatTag) throw new Error();
        const messages = await publicChatsService.getAllChatMessages(interviewChatTag);
        const lastMessage = messages.at(-1);
        if (!lastMessage || lastMessage.type !== ChatMessageType.Agent) return messages;

        let lastInterviewProgressBlock: ChatMessageBlockTypeMap[ChatMessageBlockType.Progress] | undefined;
        // lastMessage.blocks.findLast does not work with the current TS config
        for (let blockIndex = lastMessage.blocks.length - 1; blockIndex >= 0; blockIndex--) {
            const block = lastMessage.blocks[blockIndex];
            if (block.type === ChatMessageBlockType.Progress) {
                lastInterviewProgressBlock = block;
                break;
            }
        }

        if (!lastInterviewProgressBlock) return messages;

        const interviewProgress = lastInterviewProgressBlock.data;
        // When the chat was just initialized we might have block that updates the interview progress in the first message but since the interview is retrieved earlier
        // and we do not receive real-time updates for it, we are manually updating the progress based on the block in the first message
        setInterview(i =>
            i && i.detail && i.detail.percentCompleted !== interviewProgress ? { ...i, detail: { ...i.detail, percentCompleted: interviewProgress } } : i
        );

        return messages;
    }, [interviewChatTag]);
    const sendMessage = useCallback(
        async function* sendMessage(message: string) {
            if (!interviewChatTag) throw new Error();
            const messageStream = publicChatsService.sendMessage(interviewChatTag, message);
            for await (const message of messageStream) {
                yield message;

                if (message.type === 'block' && message.data.type === ChatMessageBlockType.Progress) {
                    const interviewProgress = message.data.data;
                    setInterview(i => (i && i.detail ? { ...i, detail: { ...i.detail, progressPercentage: interviewProgress } } : i));

                    // TODO: check if this happens on the server when the chat is closed and we do not receive the update so it has to be done manually to keep the client in sync with the server
                    if (interviewProgress === 100) {
                        setInterview(i => i && { ...i, stage: InterviewStageV2.Completed });
                        setForceExecutionView(true);
                    }
                }
            }
        },
        [interviewChatTag]
    );
    const fixChat = useCallback(() => {
        if (!interviewChatTag) throw new Error();
        return publicChatsService.fixChat(interviewChatTag);
    }, [interviewChatTag]);

    const [handleSendMessage, handleMessageComplete, generateFixMessage] = useChatActions(
        interview?.detail?.chat ?? undefined,
        setChat,
        setMessages,
        interviewChatTag ? initializeChat : undefined,
        interviewChatTag ? getAllChatMessages : undefined,
        interviewChatTag ? sendMessage : undefined,
        interviewChatTag ? fixChat : undefined
    );

    useEffect(() => {
        publicInterviewsService.getInterview(publicCode).then(setInterview);
    }, [publicCode]);

    return (
        <div className="page">
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=1.0, minimum-scale=1.0, maximum-scale=1.0" />
            </Helmet>
            <div
                className={combineClassNames(
                    'page-content page-content-section',
                    interview?.stage === InterviewStageV2.NotStarted ? 'ai-background' : undefined
                )}
            >
                {!interview ? (
                    <AIAvatar className="k-centered" size={184} borderSize="k-border-2" animate animationType="draw" iconSize={120} />
                ) : interview.stage === InterviewStageV2.NotStarted ? (
                    <StartInterviewView
                        interview={interview}
                        isMobile={isMobile}
                        onStartInterview={async () => {
                            const reCaptchaToken = await getReCaptchaToken?.();
                            const interview = await publicInterviewsService.startInterview(publicCode, reCaptchaToken);
                            setInterview(interview);
                        }}
                    />
                ) : interview.stage === InterviewStageV2.InProgress || forceExecutionView ? (
                    <ExecuteInterviewView
                        interview={interview}
                        messages={messages}
                        //onFinishInterview={() => publicInterviewsService.completeInterview(publicCode).then(setInterview)}
                        onSendMessage={handleSendMessage}
                        onAIMessageTyped={handleMessageComplete}
                        fixChat={generateFixMessage}
                        readonly={interview.stage !== InterviewStageV2.InProgress || interview.detail?.chat?.closed}
                        isTablet={isTablet}
                    />
                ) : (
                    <FinishedInterviewView interview={interview} isMobile={isMobile} />
                )}
            </div>
        </div>
    );
}

function StartInterviewView({
    interview,
    isMobile,
    onStartInterview
}: {
    interview: PublicInterview;
    isMobile: boolean;
    onStartInterview: () => void | Promise<void>;
}) {
    const [isStartingInterview, startInterviewCallback] = useSingleClickCallback(onStartInterview);

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-gap-8 k-min-h-full page-content-middle">
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2 k-text-center">
                {isMobile ? <H1>{interview.ideaTitle}</H1> : <div className="k-display-4">{interview.ideaTitle}</div>}
                {/* {interview.research && (isMobile ? <H3>{interview.research}</H3> : <H2>{interview.research}</H2>)} */}
                {Boolean(interview.scriptDurationInMinutes) && (
                    <div>
                        <ClockIcon className="!k-display-inline k-align-middle k-icp-icon k-icp-icon-size-4 k-mr-1.5" />
                        <span className="k-align-middle k-icp-line-height-inline-md">
                            Approximate interview time:
                            <span className="k-ml-1.5">{dateTimeService.stringifyMinutesToHours(interview.scriptDurationInMinutes!)}</span>
                        </span>
                    </div>
                )}
            </StackLayout>
            <StackLayout
                orientation="vertical"
                align={{ horizontal: 'center', vertical: 'top' }}
                className="k-text-center k-p-8 k-rounded-xl k-border-2 k-border-solid k-border-white bg-ghost-gradient k-gap-4"
            >
                <AIAvatar size={128} borderSize="k-border-2" iconSize={80} />
                <H1 className="ai-text-color">Hello, I'm IVA!</H1>
                <div className="k-fs-lg">
                    I'm excited to be your AI interviewer!
                    <br />
                    Whenever you're ready, begin the interview.
                </div>
                <Button disabled={isStartingInterview} type="button" themeColor="primary" size="large" className="k-mt-4" onClick={startInterviewCallback}>
                    Start interview
                </Button>
            </StackLayout>

            <StackLayout align={{ horizontal: 'end', vertical: 'middle' }} className="k-gap-2.5">
                <span>Powered by:</span>
                <IcanpreneurLogo className="k-mr-4" />
            </StackLayout>
        </StackLayout>
    );
}

function FinishedInterviewView({ interview, isMobile }: { interview: PublicInterview; isMobile: boolean }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8 k-text-center">
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                {isMobile ? <H1>{interview.ideaTitle}</H1> : <div className="k-display-4">{interview.ideaTitle}</div>}
                {/* {interview.topic && (isMobile ? <H3>{interview.topic}</H3> : <H2>{interview.topic}</H2>)} */}
            </StackLayout>
            <img src={interviewIllustrationUrl} width="321" height="225" alt="Finished interview" className="responsive-image k-mx-auto" />
            <div className="k-fs-lg">
                Thank you for taking the time to answer the questions.
                <br />
                Your input is greatly appreciated!
            </div>
        </StackLayout>
    );
}

function ExecuteInterviewView({
    interview,
    messages,
    onFinishInterview,
    onSendMessage,
    onAIMessageTyped,
    fixChat,
    readonly,
    isTablet
}: {
    interview: PublicInterview;
    messages?: ChatMessage[];
    onFinishInterview?: () => void | Promise<void>;
    onSendMessage?: (message: string, setContext: (context: AgentChatMessage) => void) => AsyncGenerator<ChatMessageBlock>;
    onAIMessageTyped?: (context: AgentChatMessage | undefined) => void;
    fixChat?: (setContext: (context: AgentChatMessage) => void) => AsyncGenerator<ChatMessageBlock>;
    readonly?: boolean;
    isTablet?: boolean;
}) {
    return (
        <Dialog
            className="k-icp-dialog-maximized k-icp-dialog-no-padding k-icp-dialog-with-title-shadow"
            title={<InterviewDialogTitle interview={interview} onFinishInterview={onFinishInterview} isTablet={isTablet} />}
            closeIcon={false}
        >
            <AILedChatComponent
                messages={messages}
                onSendMessage={onSendMessage}
                onMessageComplete={onAIMessageTyped}
                readonly={readonly}
                generateFixMessage={fixChat}
                addTalking
                addListening
                animateIntro
            />
        </Dialog>
    );
}

function InterviewDialogTitle({
    interview,
    onFinishInterview,
    isTablet
}: {
    interview: PublicInterview;
    onFinishInterview?: () => void | Promise<void>;
    isTablet?: boolean;
}) {
    const { show: showFinishInterviewDialog, element: finishInterviewDialog } = useConfirmDialog();

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-w-full k-justify-content-between k-gap-4">
            <div className="k-text-ellipsis">Interview with IVA (Icanpreneur Virtual Assistant)</div>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-icp-component-border">
                <InterviewProgress progressPercentage={interview.detail?.percentCompleted ?? 0} hideLabel={isTablet} />
                <div className="k-separator" />
                <Button
                    type="button"
                    themeColor="secondary"
                    disabled={interview.stage !== InterviewStageV2.InProgress || !onFinishInterview}
                    onClick={() =>
                        showFinishInterviewDialog({
                            title: 'Finish interview',
                            content: 'Do you want to end the active interview? There are still unanswered questions.',
                            confirmButtonText: 'Finish interview',
                            cancelButtonText: 'Back to interview',
                            callback: onFinishInterview!,
                            buttonsLayout: 'center',
                            confirmButtonThemeColor: 'primary'
                        })
                    }
                >
                    {isTablet ? 'Finish' : 'Finish interview'}
                </Button>
            </StackLayout>
            {finishInterviewDialog}
        </StackLayout>
    );
}

function InterviewProgress({ progressPercentage, hideLabel }: { progressPercentage: number; hideLabel?: boolean }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-fs-md k-font-normal">
            {!hideLabel && <span className="k-icp-subtle-text">Completeness:</span>}
            <DonutProgress progress={progressPercentage} padBorderClassName="k-icp-component-border" animate />
            <span className="k-text-secondary" style={{ minWidth: 33 }}>
                {progressPercentage.toFixed()}%
            </span>
        </StackLayout>
    );
}
