import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { combineClassNames } from '../../services/common';

export function DonutProgress({
    size = '8',
    borderWidth = '4',
    padBorderClassName = 'k-icp-component-border-4',
    progressBorderClassName = 'k-border-secondary',
    progress = 0,
    animate,
    className,
    children
}: {
    size?: string;
    borderWidth?: string;
    padBorderClassName?: string;
    progressBorderClassName?: string;
    progress?: number;
    animate?: boolean;
    className?: string;
    children?: ReactNode;
}) {
    const [runInitialAnimation, setRunInitialAnimation] = useState(animate === true && progress > 0);
    useEffect(() => {
        if (!runInitialAnimation) return;
        setRunInitialAnimation(false);
    }, [runInitialAnimation]);

    return (
        <div className={combineClassNames(`k-w-${size} k-h-${size} k-relative`, className)}>
            <div className={`k-rounded-full k-border-${borderWidth} k-border-solid ${padBorderClassName} k-absolute k-inset-0`} />
            <div
                className={combineClassNames(
                    `k-rounded-full k-border-${borderWidth} k-border-solid ${progressBorderClassName} k-absolute k-inset-0 k-icp-donut-progress`,
                    animate ? 'k-icp-donut-progress--animated' : undefined
                )}
                style={{ '--progress-percentage': runInitialAnimation ? 0 : progress, visibility: !progress ? 'hidden' : undefined } as CSSProperties}
            />
            {children && <div className="k-centered">{children}</div>}
        </div>
    );
}
