import { StackLayout } from '@progress/kendo-react-layout';
import { RemoteDataComboBox } from '../../components/common/remoteDataComboBox';
import { ReactComponent as ScriptIcon } from '../../icons/script.svg';
import { InterviewScript2, interviewScripts2Service } from '../../services/interviewScripts2Service';

export function InterviewScriptSelector({
    ideaId,
    value,
    onChange,
    placeholder
}: {
    ideaId: string;
    value?: InterviewScript2;
    onChange?: (e: { value: InterviewScript2 | undefined | null }) => void;
    placeholder?: string;
}) {
    return (
        <RemoteDataComboBox
            dataItemKey="id"
            textField="name"
            placeholder={placeholder ?? 'Add details...'}
            value={value}
            onChange={onChange}
            emptyListText="No script found."
            renderItem={script => <ScriptSimpleView script={script} />}
            fetchItems={filter => interviewScripts2Service.getScripts(ideaId)}
        />
    );
}

function ScriptSimpleView({ script }: { script: InterviewScript2 }) {
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-fs-sm">
            <ScriptIcon className="k-icp-icon k-icp-icon-size-8" />
            {script.name}
        </StackLayout>
    );
}
