import { StackLayout } from '@progress/kendo-react-layout';
import { combineClassNames, generateInitials, getPersonFullName, getPreferredColorIndex } from '../../services/common';
import { ReducedPerson } from '../../services/contactsService';
import { ReducedUser } from '../../services/usersService';
import { AIAvatar } from '../ai/aiAvatar';
import { DivButton } from '../common/DivButton';
import { IVA_USER, IVAUser, UNKNOWN_HOST_USER, UnknownUser } from '../interview2/common';
import UserAvatar from '../user/userAvatar';

type ContactViewProps =
    | {
          isAI: true;
          isUnknown?: never;
          unknownName?: never;
          isSelected?: boolean;
          onClick?: () => void;
          isCurrentUser?: boolean;
          contact?: never;
          isComingSoon?: never;
      }
    | {
          isAI: false;
          isSelected?: boolean;
          isUnknown?: boolean;
          unknownName?: string;
          onClick?: () => void;
          isCurrentUser?: boolean;
          isComingSoon?: boolean;
          contact?: ReducedPerson | ReducedUser;
      };

export type SimpleSelectorContact = ReducedPerson | ReducedUser | IVAUser | UnknownUser;

export function ContactSimpleSelector({
    className,
    contacts,
    currentUserId,
    emptyText,
    selectedContactId,
    otherHostsComingSoon,
    onSelectedContact
}: {
    className?: string;
    currentUserId?: string;
    emptyText?: string;
    contacts: SimpleSelectorContact[];
    selectedContactId?: string | number;
    otherHostsComingSoon?: boolean;
    onSelectedContact?: (contact: SimpleSelectorContact) => void;
}) {
    return (
        <StackLayout
            align={{ horizontal: 'start', vertical: 'top' }}
            className={combineClassNames('k-rounded-md k-icp-panel-base k-p-2 k-gap-1 k-overflow-auto hide-scrollbar-on-touch-device', className)}
        >
            {contacts.length === 0 ? (
                <div style={{ height: 94 }} className="k-flex-1 k-d-flex k-align-items-center k-justify-content-center k-text-center k-icp-subtle-text">
                    {emptyText ?? 'No contacts found'}
                </div>
            ) : (
                contacts.map(contact => {
                    const contactId = 'userId' in contact ? contact.userId : contact.id;
                    const isCurrentUser = currentUserId === contactId;

                    if (contactId === IVA_USER.userId) {
                        return (
                            <ContactView key={contactId} isAI={true} isSelected={selectedContactId === 'iva'} onClick={() => onSelectedContact?.(contact)} />
                        );
                    }

                    if (contactId === UNKNOWN_HOST_USER.userId) {
                        return (
                            <ContactView
                                key={contactId}
                                isAI={false}
                                isUnknown
                                unknownName={(contact as UnknownUser).name}
                                isSelected={selectedContactId === 'unknown'}
                                onClick={() => onSelectedContact?.(contact)}
                            />
                        );
                    }

                    return (
                        <ContactView
                            key={contactId}
                            isAI={false}
                            isCurrentUser={isCurrentUser}
                            isSelected={contactId === selectedContactId}
                            onClick={() => onSelectedContact?.(contact)}
                            contact={contact as ReducedPerson | ReducedUser}
                            isComingSoon={otherHostsComingSoon}
                        />
                    );
                })
            )}
        </StackLayout>
    );
}

function ContactView({ isAI, isSelected, onClick, isCurrentUser, contact, unknownName, isComingSoon }: ContactViewProps) {
    const text = contact
        ? 'name' in contact
            ? contact.name
            : getPersonFullName(contact.firstName, contact.lastName, isCurrentUser)
        : isAI
        ? 'IVA'
        : unknownName
        ? unknownName
        : 'Unknown';

    //Using k-pointer-events-none instead of k-disabled because the "Coming soon" badge must not have opacity
    return (
        <DivButton
            title={text}
            rounded="medium"
            fillMode="solid"
            className={combineClassNames(
                'k-icp-hover-bg-base-4 !k-p-2 k-icp-no-focus-shadow !k-min-w-fit',
                isComingSoon ? 'k-pointer-events-none' : '',
                isSelected ? '!k-border-secondary' : '!k-border-transparent'
            )}
            onClick={onClick}
        >
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-1 k-text-sm k-font-normal">
                {isAI ? (
                    <AIAvatar />
                ) : (
                    <UserAvatar
                        empty={!contact}
                        picture={contact?.picture}
                        className={combineClassNames(isComingSoon ? 'k-disabled' : '')}
                        initials={contact ? generateInitials(2, contact.firstName, contact.lastName) : undefined}
                        colorIndex={contact ? getPreferredColorIndex('id' in contact ? contact.id : contact.userId) : 0}
                    />
                )}

                {isAI ? (
                    <div className="k-text-center k-fs-sm" style={{ width: 80 }}>
                        {text}
                    </div>
                ) : (
                    <div style={{ width: 80 }} className={combineClassNames('k-text-center k-text-ellipsis k-fs-sm', isComingSoon ? 'k-disabled' : '')}>
                        {text}
                    </div>
                )}

                {isComingSoon && (
                    <div className="k-px-1 k-py-0.5 k-rounded k-border k-icp-border-secondary-24 k-border-solid k-icp-text-xs k-text-secondary">
                        Coming soon
                    </div>
                )}
            </StackLayout>
        </DivButton>
    );
}
