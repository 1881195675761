import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useMemo, useRef, useState } from 'react';

import { generateInitials, getPersonFullName, getPreferredColorIndex } from '../../services/common';
import { ideasService } from '../../services/ideasService';
import { ReducedUser, UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { AIAvatar } from '../ai/aiAvatar';
import { ValidationScope, ValidationScopeHandle, ValidationUnit } from '../common/validation';
import { ContactSimpleSelector, SimpleSelectorContact } from '../contacts/contactSimpleSelector';
import { FormFieldProps, requiredValidator } from '../ui/inputs';
import LoadingIndicator from '../ui/loadingIndicator';
import UserAvatar from '../user/userAvatar';
import { IVA_USER, IVAUser, OptionLayout, UNKNOWN_HOST_USER, UnknownUser } from './common';

export const hostPickerValidator = requiredValidator('Host');

export function HostPickerDialog({
    title,
    ideaId,
    currentHost,
    onSave,
    onCancel
}: {
    title: string;
    ideaId: string;
    currentHost?: ReducedUser | IVAUser;
    onSave?: (host: ReducedUser | IVAUser) => void;
    onCancel?: () => void;
}) {
    const [selectedHost, setSelectedHost] = useState<ReducedUser | IVAUser | undefined>(currentHost);
    const validationScopeRef = useRef<ValidationScopeHandle | null>(null);
    const currentUserId = useAppSelector(state => state.user?.userId);

    return (
        <ValidationScope ref={validationScopeRef}>
            <Dialog title={title} width={640} onClose={onCancel} className="dialog-window-max-h-half">
                <div className="k-mb-3 k-font-weight-bold">Select host</div>

                <ValidationUnit key={'host'} name={'host'} value={selectedHost} validator={hostPickerValidator}>
                    {(errorMessage, onParameterValidationChange) => (
                        <>
                            <HostOptionsPicker
                                ideaId={ideaId}
                                value={selectedHost}
                                onSelected={value => {
                                    onParameterValidationChange?.(value);
                                    setSelectedHost(value);
                                }}
                                firstHostId={currentHost?.userId}
                                currentUserId={currentUserId}
                            />

                            {errorMessage && <div className="k-mt-2 k-text-error">{errorMessage}</div>}
                        </>
                    )}
                </ValidationUnit>
                <DialogActionsBar layout="center">
                    <Button
                        themeColor="primary"
                        onClick={() => {
                            validationScopeRef.current?.validate();
                            if (validationScopeRef.current?.isValid) onSave?.(selectedHost!);
                        }}
                    >
                        Save
                    </Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActionsBar>
            </Dialog>
        </ValidationScope>
    );
}

export function HostsPicker({
    ideaId,
    value,
    onChange,
    currentUserId,
    addIva,
    emptyText,
    supportUnknown,
    otherHostsComingSoon
}: FormFieldProps<ReducedUser | IVAUser | UnknownUser> & {
    ideaId: string;
    currentUserId?: string;
    addIva?: boolean;
    supportUnknown?: boolean;
    emptyText?: string;
    otherHostsComingSoon?: boolean;
}) {
    const selectedId = value?.userId;
    const [hosts, setHosts] = useState<ReducedUser[] | undefined>(undefined);

    const reorderedHosts = useMemo(() => {
        if (!hosts) return undefined;
        const sortedHosts: SimpleSelectorContact[] = hosts.sort((a, b) => (a.userId === currentUserId ? -1 : b.userId === currentUserId ? 1 : 0));
        let hostsResult = sortedHosts;

        if (addIva) {
            hostsResult = [IVA_USER, ...sortedHosts];
        }

        if (supportUnknown) {
            hostsResult = [UNKNOWN_HOST_USER, ...hostsResult];
        }

        return hostsResult;
    }, [addIva, currentUserId, hosts, supportUnknown]);

    useEffect(() => {
        ideasService.getMembers(ideaId).then(result => {
            const hosts = result.filter(m => m.role === UserRole.Administrator || m.role === UserRole.Editor).map(m => m.user);

            setHosts(hosts);
        });
    }, [ideaId]);

    return (
        <>
            {reorderedHosts ? (
                <ContactSimpleSelector
                    currentUserId={currentUserId}
                    className="k-mt-4"
                    contacts={reorderedHosts}
                    selectedContactId={selectedId}
                    onSelectedContact={selectedUser => onChange?.({ value: selectedUser as ReducedUser | IVAUser | UnknownUser })}
                    emptyText={emptyText}
                    otherHostsComingSoon={otherHostsComingSoon}
                />
            ) : (
                <Skeleton style={{ height: 96 }} />
            )}
        </>
    );
}

function HostOptionsPicker({
    ideaId,
    value,
    firstHostId,
    currentUserId,
    onSelected
}: {
    ideaId: string;
    value?: ReducedUser | IVAUser;
    currentUserId?: string;
    firstHostId?: string;
    onSelected: (value: ReducedUser | IVAUser) => void;
}) {
    const [hosts, setHosts] = useState<ReducedUser[] | undefined>(undefined);

    useEffect(() => {
        ideasService.getMembers(ideaId).then(result => {
            const hosts = result.filter(m => m.role === UserRole.Administrator || m.role === UserRole.Editor).map(m => m.user);
            setHosts(hosts);
        });
    }, [ideaId]);

    const reorderdedHosts = useMemo(() => {
        if (!hosts) return undefined;
        const sortedHosts: ReducedUser[] = hosts.sort((a, b) => (a.userId === currentUserId ? -1 : b.userId === currentUserId ? 1 : 0));
        const hostsWithIva = [IVA_USER, ...sortedHosts];

        let result = hostsWithIva;
        if (firstHostId) {
            const firstHost = hostsWithIva.find(h => h.userId === firstHostId);
            if (firstHost) {
                result = [firstHost, ...hostsWithIva.filter(h => h.userId !== firstHostId)];
            }
        }

        return result;
    }, [currentUserId, firstHostId, hosts]);

    if (!reorderdedHosts) {
        return (
            <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} style={{ minHeight: '172px' }}>
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'stretch', vertical: 'top' }}
            className="k-gap-3 k-overflow-y-auto"
            style={{ minHeight: '172px' }}
        >
            {reorderdedHosts.map((contact, index) => (
                <HostOptionView
                    key={index}
                    isSelected={contact.userId === value?.userId}
                    isCurrentUser={contact.userId === currentUserId}
                    onSelected={() => onSelected(contact)}
                    user={contact}
                />
            ))}
        </StackLayout>
    );
}

function HostOptionView({
    isSelected,
    onSelected,
    isCurrentUser,
    user
}: {
    isSelected: boolean;
    onSelected: (value: ReducedUser | IVAUser) => void;
    isCurrentUser?: boolean;
    user: ReducedUser | IVAUser;
}) {
    const isAI = isIVAUser(user);
    const userName = isAI ? 'IVA' : getPersonFullName(user.firstName, user.lastName, isCurrentUser);
    const subtitle = isAI ? 'Icanpreneur Virtual Assistant' : user.emailAddress;

    return (
        <OptionLayout
            isSelected={isSelected}
            onClick={() => onSelected(user)}
            content={
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-flex-1">
                    {isAI ? (
                        <AIAvatar />
                    ) : (
                        <UserAvatar
                            empty={!user}
                            picture={user.picture}
                            initials={user ? generateInitials(2, user.firstName, user.lastName) : undefined}
                            colorIndex={user ? getPreferredColorIndex(user.userId) : 0}
                        />
                    )}

                    <div>
                        <div>{userName}</div>
                        <div className="k-fs-sm k-icp-subtle-text">{subtitle}</div>
                    </div>
                </StackLayout>
            }
        />
    );
}

function isIVAUser(user: ReducedUser | IVAUser): user is IVAUser {
    return user.userId === 'iva';
}
