import { ErrorWithOperationDisplayName, FileResponse } from './common';
import { dateTimeService } from './dateTimeService';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';
import { ReducedUser } from './usersService';

type ContactEditData = {
    countryCode?: string;
    city?: string;
    address?: string;
    picture?: string;
    linkedIn?: string;
    twitter?: string;
    facebook?: string;
};

export type Contact = ContactEditData & {
    id: number;
    createdOn: Date;
    updatedOn?: Date;
};

type FullContact = Contact & {
    createdBy?: ReducedUser;
    updatedBy?: ReducedUser;
};

export type CompanyEditData = ContactEditData & {
    name: string;
    website?: string;
};

export type Company = Contact & CompanyEditData & { readonly peopleCount?: number };
export type FullCompany = FullContact & Company & { people: Person[]; peopleCount: number };
export type Companies = { companies: Company[]; totalCount: number };

export enum PersonSex {
    Male = 'Male',
    Female = 'Female',
    Other = 'Other'
}

export enum MaritalStatus {
    NeverMarried = 'Never married',
    Single = 'Single',
    Married = 'Married',
    Divorced = 'Divorced',
    Separated = 'Separated',
    Widowed = 'Widowed'
}

export enum EducationLevel {
    None = 'None',
    Primary = 'Primary',
    Secondary = 'Secondary',
    Bachelor = 'Bachelor',
    Master = 'Master',
    Doctorate = 'Doctorate'
}

export type PersonEditData = ContactEditData & {
    firstName: string;
    lastName: string;
    emailAddress?: string;
    phoneNumber?: string;
    sex?: PersonSex;
    birthDate?: Date;
    maritalStatus?: MaritalStatus;
    referredByContactId?: number;

    jobTitle?: string;
    companyId?: number;
    workPhoneNumber?: string;
    otherAddress?: string;
    secondaryEmailAddress?: string;

    educationLevel?: EducationLevel;
    educationField?: string;

    customerSegmentIds?: number[];
    tagIds?: number[];
};

export type ReducedPerson = {
    id: number;
    firstName: string;
    lastName: string;
    readonly name: string;
    emailAddress?: string;
    picture?: string | null;
    jobTitle?: string | null;
};

export type Person = Contact & Omit<PersonEditData, 'tagIds'> & { readonly name: string; readonly companyName?: string };
export type FullPerson = FullContact & Person & { company?: Company; referredByContact?: Person; tags?: ContactTag[] };
export type People = { people: Person[]; totalCount: number };
export type ContactTag = { id: number; name: string };
export type ContactTagStats = ContactTag & { contactCount: number };
export type ContactTags = { tags: ContactTag[]; totalCount: number };
export type ContactTagsStats = { tags: ContactTagStats[]; totalCount: number };
export type PersonStats = {
    contactId: number;
    noteCount: number;
    meetingCount: number;
    reachOutCount: number;
    researchReachOutCount: number;
    researchCount: number;
    interviewCount: number;
};

export type PeopleFilter = {
    search?: string;
    jobTitle?: string;
    companyId?: number;
    city?: string;
    countryCode?: string;
    customerSegmentId?: number;
    createdFrom?: Date;
    createdTo?: Date;
    requireLinkedIn?: boolean;
    requireTwitter?: boolean;
    requireFacebook?: boolean;
    tagIds?: number[];
    researchId?: number;
};
export enum ContactsSortBy {
    Recent = 'Recent',
    Alphabetical = 'Alphabetical'
}

export enum ContactsListViewMode {
    Card,
    List
}

enum ContactSortOrder {
    Name = 'Name',
    UpdatedOn = 'UpdatedOn'
}

class ContactsService extends HttpServiceBase {
    constructor() {
        super('/api/contacts');
    }

    @ErrorWithOperationDisplayName('Get contacts')
    async getPeople(ideaId: string, sortBy?: ContactsSortBy, filter?: PeopleFilter, skip?: number, take?: number): Promise<People> {
        const queryParams: URLSearchParams = new URLSearchParams();

        this.addQueryParamIfPresent(queryParams, 'orderBy', this.getSortOrder(sortBy));
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());
        this.addQueryParamIfPresent(queryParams, 'search', filter?.search);
        this.addQueryParamIfPresent(queryParams, 'jobTitle', filter?.jobTitle);
        this.addQueryParamIfPresent(queryParams, 'companyId', filter?.companyId?.toString());
        this.addQueryParamIfPresent(queryParams, 'city', filter?.city);
        this.addQueryParamIfPresent(queryParams, 'countryCode', filter?.countryCode);
        this.addQueryParamIfPresent(queryParams, 'customerSegmentId', filter?.customerSegmentId?.toString());
        this.addQueryParamIfPresent(queryParams, 'createdFrom', filter?.createdFrom?.toISOString());
        this.addQueryParamIfPresent(queryParams, 'createdTo', filter?.createdTo?.toISOString());
        this.addQueryParamIfPresent(queryParams, 'hasLinkedIn', filter?.requireLinkedIn?.toString());
        this.addQueryParamIfPresent(queryParams, 'hasTwitter', filter?.requireTwitter?.toString());
        this.addQueryParamIfPresent(queryParams, 'hasFacebook', filter?.requireFacebook?.toString());
        this.addQueryParamIfPresent(queryParams, 'researchId', filter?.researchId?.toString());
        if (filter?.tagIds) {
            filter.tagIds.forEach(tagId => queryParams.append('tagIds', tagId.toString()));
        }

        const peopleResult = await this.performRequest<People>({
            path: `/${ideaId}/people`,
            queryParams: queryParams
        });

        dateTimeService.ensureDateType(i => i, 'birthDate', ...peopleResult.people);
        dateTimeService.ensureDateType(i => i, 'createdOn', ...peopleResult.people);
        dateTimeService.ensureDateType(i => i, 'updatedOn', ...peopleResult.people);

        return peopleResult;
    }

    @ErrorWithOperationDisplayName('Get contact')
    async getPersonById(ideaId: string, id: number): Promise<FullPerson> {
        const person = await this.performRequest<FullPerson>({
            path: `/${ideaId}/people/${id}`
        });

        dateTimeService.ensureDateType(i => i, 'birthDate', person);
        dateTimeService.ensureDateType(i => i, 'createdOn', person);
        dateTimeService.ensureDateType(i => i, 'updatedOn', person);

        return person;
    }

    @ErrorWithOperationDisplayName('Create contact')
    async createPerson(ideaId: string, data: PersonEditData): Promise<FullPerson> {
        const person = await this.performRequest<FullPerson>({
            path: `/${ideaId}/people`,
            body: data,
            method: RequestMethod.POST
        });

        return person;
    }

    @ErrorWithOperationDisplayName('Update contact')
    async updatePerson(ideaId: string, id: number, data: PersonEditData): Promise<FullPerson> {
        const person = await this.performRequest<FullPerson>({
            path: `/${ideaId}/people/${id}`,
            body: data,
            method: RequestMethod.PUT
        });

        return person;
    }

    @ErrorWithOperationDisplayName('Delete contact')
    async deletePerson(ideaId: string, id: number): Promise<void> {
        await this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/people/${id}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Upload contact picture')
    uploadPersonImage(file: File): Promise<FileResponse> {
        return this.uploadImage(file);
    }

    @ErrorWithOperationDisplayName('Get contacts')
    async getCompanies(ideaId: string, sortBy?: ContactsSortBy, filter?: string, skip?: number, take?: number): Promise<Companies> {
        const queryParams: Record<string, string> = {};

        this.addQueryParamIfPresent(queryParams, 'orderBy', this.getSortOrder(sortBy));
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());
        this.addQueryParamIfPresent(queryParams, 'search', filter);

        const companiesResult = await this.performRequest<Companies>({
            path: `/${ideaId}/companies`,
            queryParams: queryParams
        });

        dateTimeService.ensureDateType(i => i, 'createdOn', ...companiesResult.companies);
        dateTimeService.ensureDateType(i => i, 'updatedOn', ...companiesResult.companies);

        return companiesResult;
    }

    @ErrorWithOperationDisplayName('Get contact')
    async getCompanyById(ideaId: string, id: number): Promise<FullCompany> {
        const company = await this.performRequest<FullCompany>({
            path: `/${ideaId}/companies/${id}`
        });

        dateTimeService.ensureDateType(i => i, 'createdOn', company);
        dateTimeService.ensureDateType(i => i, 'updatedOn', company);

        return company;
    }

    @ErrorWithOperationDisplayName('Create contact')
    async createCompany(ideaId: string, data: CompanyEditData): Promise<FullCompany> {
        const company = await this.performRequest<FullCompany>({
            path: `/${ideaId}/companies`,
            body: data,
            method: RequestMethod.POST
        });

        dateTimeService.ensureDateType(i => i, 'createdOn', company);
        dateTimeService.ensureDateType(i => i, 'updatedOn', company);

        return company;
    }

    @ErrorWithOperationDisplayName('Update contact')
    async updateCompany(ideaId: string, id: number, data: CompanyEditData): Promise<FullCompany> {
        const company = await this.performRequest<FullCompany>({
            path: `/${ideaId}/companies/${id}`,
            body: data,
            method: RequestMethod.PUT
        });

        return company;
    }

    @ErrorWithOperationDisplayName('Delete contact')
    async deleteCompany(ideaId: string, id: number): Promise<void> {
        await this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/companies/${id}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Upload contact picture')
    uploadCompanyImage(file: File): Promise<FileResponse> {
        return this.uploadImage(file);
    }

    @ErrorWithOperationDisplayName('Ensure tag')
    ensureTag(ideaId: string, tagName: string): Promise<ContactTag> {
        return this.performRequest<ContactTag>({
            path: `/${ideaId}/tags/ensure`,
            method: RequestMethod.POST,
            body: {
                name: tagName
            }
        });
    }

    @ErrorWithOperationDisplayName('Get tags')
    getTags(ideaId: string, search?: string, take?: number): Promise<ContactTag[]> {
        const queryParams: Record<string, string> = {};
        this.addQueryParamIfPresent(queryParams, 'search', search);
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());

        return this.performRequest<ContactTags>({
            path: `/${ideaId}/tags`,
            queryParams: queryParams
        }).then(r => r.tags);
    }

    @ErrorWithOperationDisplayName('Get tags with stats')
    getTagsStats(ideaId: string): Promise<ContactTagStats[]> {
        return this.performRequest<ContactTagsStats>({
            path: `/${ideaId}/tags`,
            queryParams: {
                includeCounts: 'true'
            }
        }).then(r => r.tags);
    }

    @ErrorWithOperationDisplayName('Create tag')
    createTag(ideaId: string, tagName: string): Promise<ContactTag> {
        return this.performRequest({
            path: `/${ideaId}/tags`,
            method: RequestMethod.POST,
            body: {
                name: tagName
            }
        });
    }

    @ErrorWithOperationDisplayName('Get tag')
    getTag(ideaId: string, tagId: number): Promise<ContactTag> {
        return this.performRequest({
            path: `/${ideaId}/tags/${tagId}`
        });
    }

    @ErrorWithOperationDisplayName('Update tag')
    updateTag(ideaId: string, tagId: number, tagName: string): Promise<ContactTag> {
        return this.performRequest({
            path: `/${ideaId}/tags/${tagId}`,
            method: RequestMethod.PUT,
            body: {
                name: tagName
            }
        });
    }

    @ErrorWithOperationDisplayName('Delete tag')
    deleteTag(ideaId: string, tagId: number): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/tags/${tagId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Restore tag')
    restoreTag(ideaId: string, tagId: number): Promise<ContactTag> {
        return this.performRequest({
            path: `/${ideaId}/tags/${tagId}/restore`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Restore tag')
    getPersonStats(ideaId: string, personId: number): Promise<PersonStats> {
        return this.performRequest({ path: `/${ideaId}/people/${personId}/stats` });
    }

    public parsePersonNamesFromString(value: string): { firstName: string; lastName?: string } | undefined {
        if (!value) return undefined;

        const textParts = value.split(' ');
        if (textParts.length > 1)
            return {
                firstName: textParts[0],
                lastName: textParts.length === 2 ? textParts[1] : textParts.slice(1).join(' ')
            };

        return {
            firstName: value
        };
    }

    private uploadImage(file: File): Promise<FileResponse> {
        const dataToPost = new FormData();
        dataToPost.append('file', file);

        return this.performRequest({ path: `/images`, method: RequestMethod.POST, body: dataToPost });
    }

    private getSortOrder(sortBy: ContactsSortBy | undefined) {
        if (sortBy) {
            return sortBy === ContactsSortBy.Recent ? ContactSortOrder.UpdatedOn : ContactSortOrder.Name;
        }

        return undefined;
    }
}

export const contactsService = new ContactsService();
