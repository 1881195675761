import { StackLayout } from '@progress/kendo-react-layout';
import { ChatMessageType } from '../../services/chatsService';
import { combineClassNames, generateInitials, getPreferredColorIndex } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { ReducedUser } from '../../services/usersService';
import { InterviewMessageBox } from '../interview/entries/interviewMessageLog';
import UserAvatar from '../user/userAvatar';
import { AIAvatar } from './aiAvatar';

export type ChatMessageSimple = {
    id?: number;
    createdOn?: Date;
    updatedOn?: Date;
    type: ChatMessageType;
    timeSeconds: number;
    content: string;
    user?: ReducedUser;
};

const exampleMessages: ChatMessageSimple[] = [
    {
        id: 1,
        type: ChatMessageType.Agent,
        createdOn: new Date('2024-03-20T10:00:00'),
        timeSeconds: 10,
        content: "Hi! I'm your AI interviewer today. Could you tell me a bit about your background and experience?"
    },
    {
        id: 2,
        type: ChatMessageType.User,
        createdOn: new Date('2024-03-20T10:01:00'),
        timeSeconds: 10,
        user: { userId: '1', emailAddress: 'john.doe@example.com', firstName: 'John', lastName: 'Doe' },
        content: 'Hi! I have 5 years of experience in frontend development.'
    },
    {
        id: 3,
        type: ChatMessageType.Agent,
        createdOn: new Date('2024-03-20T10:02:00'),
        timeSeconds: 10,
        content:
            'Great! Could you tell me about a challenging project you worked on recently? What were the main technical challenges you faced and how did you overcome them?'
    },
    {
        id: 4,
        type: ChatMessageType.User,
        createdOn: new Date('2024-03-20T10:03:00'),
        timeSeconds: 10,
        user: { userId: '1', emailAddress: 'john.doe@example.com', firstName: 'John', lastName: 'Doe' },
        content:
            'Recently, I led the migration of our legacy Angular application to React. The main challenge was ensuring a smooth transition while maintaining functionality.'
    },
    {
        id: 5,
        type: ChatMessageType.Agent,
        createdOn: new Date('2024-03-20T10:04:00'),
        timeSeconds: 10,
        content: 'That sounds interesting! Could you elaborate on your approach to the migration? How did you manage the transition period?'
    },
    {
        id: 6,
        type: ChatMessageType.User,
        createdOn: new Date('2024-03-20T10:05:00'),
        timeSeconds: 10,
        user: { userId: '1', emailAddress: 'john.doe@example.com', firstName: 'John', lastName: 'Doe' },
        content:
            'We took an incremental approach, migrating component by component. We used a micro-frontend architecture to run both frameworks simultaneously during the transition.'
    }
];

interface AILedChatComponentProps {
    messages?: ChatMessageSimple[];
    className?: string;
}

export const ChatPreview = ({ messages, className }: AILedChatComponentProps) => {
    //TODO R2: remove mock messages
    messages = messages ?? exampleMessages;
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className={combineClassNames('k-gap-4', className)}>
            {messages.map(message => {
                return (
                    <InterviewMessageBox
                        hideShadow
                        rightAdditionalComponent={
                            <span className="k-align-self-end k-ml-1 k-mb-2 k-icp-subtle-text k-fs-sm">
                                {dateTimeService.stringifySecondsToMinutesAndSeconds(message.timeSeconds)}
                            </span>
                        }
                        messageBoxClassName={message.type === ChatMessageType.Agent ? undefined : 'k-icp-panel-base !k-icp-component-border'}
                        avatar={
                            message.type === ChatMessageType.Agent ? (
                                <AIAvatar className="k-shrink-0" />
                            ) : (
                                <UserAvatar
                                    className="k-shrink-0"
                                    initials={generateInitials(2, message.user!.firstName, message.user!.lastName)}
                                    picture={message.user!.picture}
                                    colorIndex={getPreferredColorIndex(message.user!.userId)}
                                />
                            )
                        }
                    >
                        {message.content}
                    </InterviewMessageBox>
                );
            })}
        </StackLayout>
    );
};
