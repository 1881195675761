import { Dialog } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { PopupPropsContext } from '@progress/kendo-react-popup';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { ReactComponent as CheckCircleIcon } from '../../icons/check-circle-closed.svg';
import { ReactComponent as CircleIcon } from '../../icons/circle.svg';
import { ReactComponent as InterviewAddIcon } from '../../icons/interview-add.svg';
import { ReactComponent as DefaultItemCardIcon } from '../../icons/pause-circle.svg';
import { ReactComponent as XCircleIcon } from '../../icons/x-circle.svg';
import buyerIllustrationUrl from '../../images/buyer-illustration.svg';
import customerProblemIllustrationUrl from '../../images/customer-problem-illustration.svg';
import diceIllustrationUrl from '../../images/dice-illustration.svg';
import { combineClassNames, getPersonFullName } from '../../services/common';
import { Interview2Type } from '../../services/interviewsV2Service';
import { ChatMessageSimple, ChatPreview } from '../ai/chatView';
import { ClickableStackLayout } from '../ui/clickableStackLayout';
import { DonutProgress } from '../ui/donutProgress';
import { FormFieldProps, requiredValidator } from '../ui/inputs';

export const interviewTypeToLabelMap: Record<Interview2Type, string> = {
    [Interview2Type.ProblemDiscoveryAndValidation]: 'Problem Discovery and Validation',
    [Interview2Type.WinLoss]: 'Win/Loss',
    [Interview2Type.BuyerPersona]: 'Buyer Persona',
    [Interview2Type.Other]: 'Other'
};

export const interviewTypeToIllustrationUrlMap: Record<Interview2Type, string> = {
    [Interview2Type.ProblemDiscoveryAndValidation]: customerProblemIllustrationUrl,
    [Interview2Type.WinLoss]: diceIllustrationUrl,
    [Interview2Type.BuyerPersona]: buyerIllustrationUrl,
    [Interview2Type.Other]: customerProblemIllustrationUrl
};

export const allowedTextFileExtensions = ['.pdf', '.docx', '.doc', '.rtf', '.txt'];
//TODO R4: support video extension files
// export const allowedInterviewTranscriptExtensions = [...allowedTextFileExtensions, '.mov', '.mp4', '.avi'];
export const maxInterviewFileSizeInBytes = 10 * 1024 * 1024; // 10 MB

export const intervieweeValidator = requiredValidator('Interviewee');
export const transcriptValidator = requiredValidator('Transcript');
export const scriptValidator = requiredValidator('Script');
export const hostValidator = requiredValidator('Host');

export const InterviewsFullScreenModal = ({ title, children, icon, onClose }: { title: string; children: React.ReactNode; icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>; onClose?: () => void }) => {
    const dialogRef = useRef<Dialog>(null);
    return (
        <PopupPropsContext.Provider value={p => (dialogRef.current?.element ? { ...p, appendTo: dialogRef.current?.element } : p)}>
            <Dialog
                ref={dialogRef}
                className="k-icp-dialog-maximized k-icp-dialog-no-padding k-icp-dialog-with-title-shadow"
                title={<InterviewTitle text={title} icon={icon} />}
                onClose={onClose}
            >
                {children}
            </Dialog>
        </PopupPropsContext.Provider>
    );
};

export const InterviewTitle = ({ text, icon }: { text: string; icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> }) => {
    const Icon = icon ?? InterviewAddIcon;
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
            <Icon className="k-icp-icon k-icp-icon-size-6" />
            <span className="k-fs-lg k-font-medium">{text}</span>
        </StackLayout>
    );
};



export function RoundedIconBackground({ icon, className }: { icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>; className?: string }) {
    const Icon = icon;
    return (
        <div
            style={{ width: '64px', height: '64px' }}
            className={combineClassNames('k-d-flex k-align-items-center k-justify-content-center k-icp-bg-secondary-8 k-rounded-full', className)}
        >
            <Icon style={{ width: 40, height: 40 }} className="k-icp-icon" />
        </div>
    );
}

export function InterviewStatusIndicator({ text, type, className }: { text: string; type: 'warning' | 'success' | "alert" | 'base'; className?: string }) {
    const bgClassName = type === 'warning' ? 'k-icp-bg-warning-8' : type === 'success' ? 'k-icp-bg-success-8' : type === 'alert' ? 'k-icp-bg-error-4' : 'k-icp-bg-dark-8';
    return <div className={`k-fs-sm k-font-normal k-px-2 -py-0.75 k-rounded-md ${bgClassName}${className ? ` ${className}` : ''}`}>{text}</div>;
}

export const ScoreView = ({ percentage }: { percentage?: number }) => {
    return (
        <StackLayout
            align={{ horizontal: 'start', vertical: 'middle' }}
            className={combineClassNames('k-gap-2', typeof percentage !== 'number' ? 'k-disabled' : undefined)}
            orientation="horizontal"
        >
            <DonutProgress progress={percentage ?? 0} />
            <div className={combineClassNames(typeof percentage === 'number' ? 'k-text-secondary' : undefined)}>
                {typeof percentage === 'number' ? `${percentage}%` : 'N/A'}
            </div>
        </StackLayout>
    );
};

export const TextFileButton = ({
    title,
    icon,
    onClick
}: {
    title: string;
    icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string;
        }
    >;
    onClick: () => void;
}) => {
    const Icon = icon;
    return (
        <ClickableStackLayout
            align={{ horizontal: 'start', vertical: 'middle' }}
            className="k-gap-2 k-p-1 k-border k-border-solid k-icp-component-border k-cursor-pointer k-rounded k-fs-sm k-icp-hover-bg-base-4"
            onClick={onClick}
        >
            <Icon className="k-icp-icon k-w-8 k-h-8 k-shrink-0" />
            <span>{title}</span>
        </ClickableStackLayout>
    );
};

export function InterviewTypePicker({ id, value, onChange, disabled, valid }: FormFieldProps<Interview2Type>) {
    const interviewTypes = [Interview2Type.ProblemDiscoveryAndValidation, Interview2Type.BuyerPersona, Interview2Type.WinLoss];

    return (
        <StackLayout id={id} align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            {interviewTypes.map(v => {
                const isSelected = v === value;
                const canSelectValue = !disabled && Boolean(onChange);

                return (
                    <div key={v} className="k-text-center">
                        <div
                            className={combineClassNames(
                                'k-p-4 k-icp-panel k-mb-1',
                                disabled ? 'k-disabled' : undefined,
                                canSelectValue ? 'k-cursor-pointer k-icp-interactive-card-small' : undefined,
                                isSelected ? '!k-border-secondary' : undefined
                            )}
                            onClick={canSelectValue && !isSelected ? () => onChange!({ value: v }) : undefined}
                        >
                            <img src={interviewTypeToIllustrationUrlMap[v]} alt={interviewTypeToLabelMap[v]} width="48" height="48" />
                        </div>
                        <div className="k-fs-sm">{interviewTypeToLabelMap[v]} Interviews</div>
                    </div>
                );
            })}
        </StackLayout>
    );
}

export type IVAUser = typeof IVA_USER;
export type UnknownUser = typeof UNKNOWN_HOST_USER;	

export const IVA_USER = {
    userId: 'iva' as const,
    name: 'IVA' as const
} as const;

export const UNKNOWN_HOST_USER = {
    userId: 'unknown' as const,
    name: 'Unknown host' as const
} as const;

export type ScriptQuestion = {
    status: 'default' | 'answered' | 'unanswered';
    text: string;
};

export type ScriptSection = {
    title: string;
    answeredQuestions: number;
    totalQuestions: number;
    questions: ScriptQuestion[];
};

//TODO R2: Implement IVA-led finished chat interview
export function IVALedChatPreview({ messages }: { messages: ChatMessageSimple[] }) {
    return (
        <div style={{ width: '720px' }} className="page-content-middle">
            <StickyStamp text="Interview transcript" />
            <ChatPreview messages={messages} />
        </div>
    );
}

export function StickyStamp({ text }: { text: string }) {
    return <div className={`k-icp-message-stamp k-pos-sticky k-top-2 k-mb-5 k-w-fit k-mx-auto k-z-10`}>{text}</div>;
}

export function ScriptQuestionsSection({ section, children }: { section: ScriptSection; children: React.ReactNode }) {
    const { title, answeredQuestions, totalQuestions } = section;
    return (
        <StackLayout orientation="vertical" className="k-gap-2">
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2 k-justify-content-between">
                <strong className="k-text-secondary">{title}</strong>
                <span className="k-fs-sm -mt-0.75">
                    {answeredQuestions}/{totalQuestions}
                </span>
            </StackLayout>

            {children}
        </StackLayout>
    );
}


type InterviewScriptQuestionHandle = {
    get element(): HTMLDivElement | null;
}

export type ScriptQuestionCardProps = {
    status: 'default' | 'answered' | 'unanswered';
    text: string;
    scratchAnswered?: boolean;
};

export const ScriptQuestionCard = forwardRef<InterviewScriptQuestionHandle, ScriptQuestionCardProps>(function ScriptQuestionCard(
    { status, text, scratchAnswered },
    ref
) {
    const elementRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
        ref,
        () => ({
            get element() {
                return elementRef.current;
            }
        }),
        []
    );

    const backgroundClass = status === 'unanswered' || (status === 'answered' && scratchAnswered) ? 'k-icp-bg-light-16' : undefined;
    const iconClass = status === 'answered' ? 'k-text-success' : status === 'unanswered' ? 'k-text-warning' : 'k-text-secondary';
    const scratched = scratchAnswered && status === 'answered';
    const Icon = status === 'answered' ? CheckCircleIcon : status === 'unanswered' ? XCircleIcon : DefaultItemCardIcon;
    return (
        <div ref={elementRef} className={combineClassNames('k-panel k-p-2', backgroundClass)}>
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                <Icon className={`k-icp-icon k-icp-icon-size-4 ${iconClass}`} />
                <div className={combineClassNames('k-flex-1', scratched ? 'k-text-line-through' : undefined)}>{text}</div>
            </StackLayout>
        </div>
    );
});

export function UserDetailsText({ name, firstName, lastName, jobTitle }: { name?: string; firstName?: string; lastName?: string; jobTitle?: string | null }) {
    const fullName = name || getPersonFullName(firstName, lastName);
    return (
        <div>
            <strong className="k-fs-lg">{fullName}</strong>
            {jobTitle && <div className="k-fs-sm k-icp-subtle-text">{jobTitle}</div>}
        </div>
    );
}

export interface OptionPickerItemProps {
    isSelected: boolean;
    onClick?: () => void;
    content: React.ReactNode;
}
export function OptionLayout({
    isSelected,
    onClick,
    content
}: OptionPickerItemProps) {

    return (
        <ClickableStackLayout
            align={{ horizontal: 'start', vertical: 'top' }}
            className={combineClassNames(
                'k-gap-2 k-cursor-pointer k-border k-border-solid k-rounded-md k-p-3',
                isSelected ? 'k-border-secondary' : 'k-icp-component-border'
            )}
            onClick={() => onClick?.()}
        >
                {content}
            {isSelected ? <CheckCircleIcon className="k-icp-icon k-text-secondary" /> : <CircleIcon className="k-icp-icon k-icp-subtle-text" />}
        </ClickableStackLayout>
    );
}
