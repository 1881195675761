import { Editor, EditorMountEvent, EditorProps, ProseMirror } from '@progress/kendo-react-editor';
import { useCallback } from 'react';
const { Plugin, PluginKey, Decoration, DecorationSet } = ProseMirror;

function placeholder(emptyMessage: string) {
    return new Plugin({
        key: new PluginKey('placeholder'),
        props: {
            decorations: state => {
                const { doc } = state;
                const empty = doc.textContent === '' && doc.childCount <= 1 && doc.content.size <= 2;

                if (!empty) {
                    return DecorationSet.empty;
                }

                const decorations: Array<any> = [];
                const decAttrs = { class: 'placeholder', 'data-placeholder': emptyMessage };

                doc.descendants((node, pos) => {
                    decorations.push(Decoration.node(pos, pos + node.nodeSize, decAttrs));
                });

                return DecorationSet.create(doc, decorations);
            }
        }
    });
}

//TODO: provide a way to override/extend these styles
const styles = `p.placeholder:first-child:before {
        content: attr(data-placeholder);
        float: left;
        cursor: text;
        height: 0;
        color: #b3b3b3;
    }`;

export function ExtendedRichTextEditor({ placeholderText, onMount: onMountProp, ...props }: { placeholderText: string } & EditorProps) {
    const onMount = useCallback(
        (event: EditorMountEvent) => {
            const state = event.viewProps.state;
            const plugins = [...state.plugins, placeholder(placeholderText)];

            const editorDocument = event.dom.ownerDocument;
            const styleElement = editorDocument && editorDocument.querySelector('style');
            if (styleElement) {
                styleElement.appendChild(editorDocument.createTextNode(styles));
            }
            onMountProp?.(event);

            return new ProseMirror.EditorView(
                { mount: event.dom },
                {
                    ...event.viewProps,
                    state: ProseMirror.EditorState.create({ doc: state.doc, plugins })
                }
            );
        },
        [placeholderText, onMountProp]
    );
    return <Editor onMount={onMount} {...props} />;
}
