import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, To, useNavigate, useParams } from 'react-router-dom';
import { getActivityCardForType } from '../../components/activities/activityCards';
import { ShowActivityDialogProps, useDeleteActivityDialog, useManageActivityDialog } from '../../components/activities/activityDialogs';
import { CanvasItemSimpleView } from '../../components/canvas/canvasItemSimpleView';
import { useRequireCanvasBoxItemsInZone } from '../../components/canvas/canvasItemsZone';
import { BackLinkHeader } from '../../components/common/BackLinkHeader';
import { BoundDropDownButton } from '../../components/common/boundDropDownButton';
import { CountryAndCityView } from '../../components/common/countries';
import { ContactInfoPanel, ContactInfoPanelRow, ContactSidePanel, ContactSocialInfo, ContactTagsList } from '../../components/contacts/view';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H2 } from '../../components/ui/typography';
import UserAvatar from '../../components/user/userAvatar';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { ReactComponent as EditIcon } from '../../icons/edit-2.svg';
import { ReactComponent as AddNoteIcon } from '../../icons/file-plus.svg';
import { ActivityOf, ActivityType, GenericActivity, activitiesService } from '../../services/activitiesService';
import { BoxItem, BoxType } from '../../services/canvasService';
import { combineClassNames, generateInitials, getPreferredColorIndex } from '../../services/common';
import { FullPerson, Person, contactsService } from '../../services/contactsService';
import { dateTimeService } from '../../services/dateTimeService';
import { ReachOutType } from '../../services/events';
import {
    RealTimeUpdateCompanyEventData,
    RealTimeUpdateContactTagEventData,
    RealTimeUpdatePersonEventData,
    realTimeUpdatesEventHub
} from '../../services/realTimeUpdatesService';
import { UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';
import { ContactDeletionRelatedEntriesNotice } from './contactDeletionRelatedEntriesNotice';

export function ViewPersonPage() {
    const [isLoadingActivities, setIsLoadingActivities] = useState(false);
    const [activitiesTypeFilter, setActivitiesTypeFilter] = useState<ActivityType>();
    const [activities, setActivities] = useState<GenericActivity[]>();
    const { ideaId, personId: personIdString } = useParams();
    const personId = parseInt(personIdString ?? '');
    const person = usePerson(ideaId, personId, '..');

    const customerSegments = useRequireCanvasBoxItemsInZone(BoxType.CustomerSegments);

    const currentUserRole = useAppSelector(s => s.idea.role);
    const canEdit = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const navigate = useNavigate();

    const loadActivities = useCallback(
        (suppressLoading = false) => {
            if (!ideaId || !personId) return;

            if (!suppressLoading) setIsLoadingActivities(true);
            activitiesService
                .getPersonActivities(ideaId, personId, activitiesTypeFilter)
                .then(setActivities)
                .finally(suppressLoading ? undefined : () => setIsLoadingActivities(false));
        },
        [activitiesTypeFilter, ideaId, personId]
    );

    const [manageActivityDialogElement, showActivityDialog] = useManageActivityDialog(ideaId, loadActivities, person);

    useEffect(() => {
        loadActivities();
    }, [loadActivities]);

    const { show: showConfirmDialog, element: confirmDialog, updateDialog: updateConfirmDialog } = useConfirmDialog();
    function deletePerson() {
        if (!person) return;

        showConfirmDialog({
            title: 'Delete contact',
            content: (
                <>
                    <div>
                        Are you sure you want to delete <strong>“{person.name}”</strong>?
                    </div>
                    <ContactDeletionRelatedEntriesNotice
                        ideaId={ideaId!}
                        personId={personId}
                        onReady={() => updateConfirmDialog(config => (config.confirmButtonDisabled ? { ...config, confirmButtonDisabled: false } : config))}
                    />
                </>
            ),
            confirmCheckboxText: 'I acknowledge that this cannot be undone',
            confirmButtonText: 'Delete contact',
            confirmButtonDisabled: true,
            callback: async () => {
                await contactsService.deletePerson(ideaId!, person.id);
                navigate('..');
            }
        });
    }

    const [deleteActivityId, setDeleteActivityId] = useState<number>();
    const activityToDelete = useMemo(() => (deleteActivityId === undefined ? undefined : activities?.find(a => a.data.id === deleteActivityId)), [
        activities,
        deleteActivityId
    ]);
    const [deleteActivity, confirmDeleteActivityElement] = useDeleteActivityDialog(
        ideaId,
        () => {
            setDeleteActivityId(undefined);
            loadActivities();
        },
        activityToDelete ? { personId, activity: activityToDelete.data } : undefined,
        (_, activityToDelete) => setDeleteActivityId(activityToDelete.id),
        () => setDeleteActivityId(undefined)
    );

    useEffect(() => {
        if (!ideaId || !personId) return;

        function loadActivitiesIfNeeded(e: { contactId: number }) {
            if (e.contactId !== personId) return;

            loadActivities(true);
        }

        const subscribeForNoteUpdates = !activitiesTypeFilter || activitiesTypeFilter === ActivityType.Note;
        if (subscribeForNoteUpdates) {
            realTimeUpdatesEventHub.addEventListener('contact', 'noteAdd', loadActivitiesIfNeeded);
            realTimeUpdatesEventHub.addEventListener('contact', 'noteUpdate', loadActivitiesIfNeeded);
            realTimeUpdatesEventHub.addEventListener('contact', 'noteDelete', loadActivitiesIfNeeded);
            realTimeUpdatesEventHub.addEventListener('contact', 'noteRestore', loadActivitiesIfNeeded);
        }

        const subscribeForReachOutUpdates = !activitiesTypeFilter || activitiesTypeFilter === ActivityType.ReachOut;
        if (subscribeForReachOutUpdates) {
            realTimeUpdatesEventHub.addEventListener('contact', 'reachOutAdd', loadActivitiesIfNeeded);
            realTimeUpdatesEventHub.addEventListener('contact', 'reachOutUpdate', loadActivitiesIfNeeded);
            realTimeUpdatesEventHub.addEventListener('contact', 'reachOutDelete', loadActivitiesIfNeeded);
            realTimeUpdatesEventHub.addEventListener('contact', 'reachOutRestore', loadActivitiesIfNeeded);
        }

        const subscribeForMeetingUpdates = !activitiesTypeFilter || activitiesTypeFilter === ActivityType.ReachOut;
        if (subscribeForMeetingUpdates) {
            realTimeUpdatesEventHub.addEventListener('scheduling', 'meetingAdd', loadActivitiesIfNeeded);
            realTimeUpdatesEventHub.addEventListener('scheduling', 'meetingUpdate', loadActivitiesIfNeeded);
            realTimeUpdatesEventHub.addEventListener('scheduling', 'meetingDelete', loadActivitiesIfNeeded);
            realTimeUpdatesEventHub.addEventListener('scheduling', 'meetingRestore', loadActivitiesIfNeeded);
        }

        const subscribeForResearchReachOutUpdates = !activitiesTypeFilter || activitiesTypeFilter === ActivityType.ResearchReachOut;
        if (subscribeForResearchReachOutUpdates) {
            realTimeUpdatesEventHub.addEventListener('research', 'reachOutAdd', loadActivitiesIfNeeded);
            realTimeUpdatesEventHub.addEventListener('research', 'reachOutUpdate', loadActivitiesIfNeeded);
            realTimeUpdatesEventHub.addEventListener('research', 'reachOutDelete', loadActivitiesIfNeeded);
            realTimeUpdatesEventHub.addEventListener('research', 'reachOutRestore', loadActivitiesIfNeeded);
        }

        return () => {
            if (subscribeForNoteUpdates) {
                realTimeUpdatesEventHub.removeEventListener('contact', 'noteAdd', loadActivitiesIfNeeded);
                realTimeUpdatesEventHub.removeEventListener('contact', 'noteUpdate', loadActivitiesIfNeeded);
                realTimeUpdatesEventHub.removeEventListener('contact', 'noteDelete', loadActivitiesIfNeeded);
                realTimeUpdatesEventHub.removeEventListener('contact', 'noteRestore', loadActivitiesIfNeeded);
            }

            if (subscribeForReachOutUpdates) {
                realTimeUpdatesEventHub.removeEventListener('contact', 'reachOutAdd', loadActivitiesIfNeeded);
                realTimeUpdatesEventHub.removeEventListener('contact', 'reachOutUpdate', loadActivitiesIfNeeded);
                realTimeUpdatesEventHub.removeEventListener('contact', 'reachOutDelete', loadActivitiesIfNeeded);
                realTimeUpdatesEventHub.removeEventListener('contact', 'reachOutRestore', loadActivitiesIfNeeded);
            }

            if (subscribeForMeetingUpdates) {
                realTimeUpdatesEventHub.removeEventListener('scheduling', 'meetingAdd', loadActivitiesIfNeeded);
                realTimeUpdatesEventHub.removeEventListener('scheduling', 'meetingUpdate', loadActivitiesIfNeeded);
                realTimeUpdatesEventHub.removeEventListener('scheduling', 'meetingDelete', loadActivitiesIfNeeded);
                realTimeUpdatesEventHub.removeEventListener('scheduling', 'meetingRestore', loadActivitiesIfNeeded);
            }

            if (subscribeForResearchReachOutUpdates) {
                realTimeUpdatesEventHub.removeEventListener('research', 'reachOutAdd', loadActivitiesIfNeeded);
                realTimeUpdatesEventHub.removeEventListener('research', 'reachOutUpdate', loadActivitiesIfNeeded);
                realTimeUpdatesEventHub.removeEventListener('research', 'reachOutDelete', loadActivitiesIfNeeded);
                realTimeUpdatesEventHub.removeEventListener('research', 'reachOutRestore', loadActivitiesIfNeeded);
            }
        };
    }, [ideaId, loadActivities, personId, activitiesTypeFilter]);

    if (!person) return <LoadingIndicator size="big" className="!k-pos-absolute k-centered" />;

    return (
        <>
            <BackLinkHeader to=".." text="All contacts" />
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-10">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1 k-gap-10">
                    <PersonHeader
                        person={person}
                        canEdit={canEdit}
                        onCreateActivity={canEdit ? showActivityDialog : undefined}
                        onDelete={canEdit ? deletePerson : undefined}
                    />
                    <StackLayout align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-10">
                        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-10">
                            <ContactInfoPanel title="Personal details">
                                {!person.emailAddress &&
                                    !person.phoneNumber &&
                                    !person.countryCode &&
                                    !person.city &&
                                    !person.address &&
                                    !person.sex &&
                                    !person.birthDate &&
                                    !person.maritalStatus &&
                                    !person.referredByContact && <span className="k-icp-subtle-text">No personal details available</span>}

                                {person.emailAddress && (
                                    <ContactInfoPanelRow title="Email">
                                        <a href={`mailto:${person.emailAddress}`} className="k-button-link-secondary">
                                            {person.emailAddress}
                                        </a>
                                    </ContactInfoPanelRow>
                                )}

                                {person.phoneNumber && <ContactInfoPanelRow title="Phone">{person.phoneNumber}</ContactInfoPanelRow>}
                                {person.countryCode || person.city ? (
                                    <ContactInfoPanelRow title="Country, City">
                                        <CountryAndCityView city={person.city} countryCode={person.countryCode} />
                                    </ContactInfoPanelRow>
                                ) : (
                                    undefined
                                )}
                                {person.address && <ContactInfoPanelRow title="Mailing address">{person.address}</ContactInfoPanelRow>}
                                {person.sex && <ContactInfoPanelRow title="Sex">{person.sex}</ContactInfoPanelRow>}
                                {person.birthDate && (
                                    <ContactInfoPanelRow title="Date of birth">
                                        {person.birthDate ? dateTimeService.stringifyToDay(person.birthDate) : undefined}
                                    </ContactInfoPanelRow>
                                )}
                                {person.maritalStatus && <ContactInfoPanelRow title="Marital status">{person.maritalStatus}</ContactInfoPanelRow>}
                                {person.referredByContact && (
                                    <ContactInfoPanelRow title="Referred by">
                                        <span>{person.referredByContact.name}</span>
                                    </ContactInfoPanelRow>
                                )}
                            </ContactInfoPanel>

                            <ContactInfoPanel title="Social">
                                {person.linkedIn || person.twitter || person.facebook ? (
                                    <ContactSocialInfo contact={person} />
                                ) : (
                                    <span className="k-icp-subtle-text">No social details available</span>
                                )}
                            </ContactInfoPanel>
                        </StackLayout>
                        <div className="k-separator k-icp-component-border" />
                        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-10">
                            <ContactInfoPanel title="Professional details">
                                {!person.jobTitle && !person.company && !person.workPhoneNumber && !person.otherAddress && !person.secondaryEmailAddress && (
                                    <span className="k-icp-subtle-text">No professional details available</span>
                                )}

                                {person.jobTitle && <ContactInfoPanelRow title="Job title">{person.jobTitle}</ContactInfoPanelRow>}
                                {person.company && (
                                    <ContactInfoPanelRow title="Company">
                                        <Link to={`../companies/${person.company.id}`} className="k-button-link-secondary">
                                            {person.company.name}
                                        </Link>
                                    </ContactInfoPanelRow>
                                )}
                                {person.workPhoneNumber && <ContactInfoPanelRow title="Work phone">{person.workPhoneNumber}</ContactInfoPanelRow>}
                                {person.otherAddress && <ContactInfoPanelRow title="Other address">{person.otherAddress}</ContactInfoPanelRow>}
                                {person.secondaryEmailAddress && (
                                    <ContactInfoPanelRow title="Secondary email">
                                        <a href={`mailto:${person.secondaryEmailAddress}`} className="k-button-link-secondary">
                                            {person.secondaryEmailAddress}
                                        </a>
                                    </ContactInfoPanelRow>
                                )}
                            </ContactInfoPanel>
                            <ContactInfoPanel title="Education">
                                {!person.educationLevel && !person.educationField && <span className="k-icp-subtle-text">No education available</span>}
                                {person.educationLevel && <ContactInfoPanelRow title="Education level">{person.educationLevel}</ContactInfoPanelRow>}
                                {person.educationField && <ContactInfoPanelRow title="Field of study">{person.educationField}</ContactInfoPanelRow>}
                            </ContactInfoPanel>
                            <ContactInfoPanel title="Administrative">
                                <ContactInfoPanelRow title="Created">
                                    <span>
                                        {dateTimeService.stringifyToDay(person.createdOn)}
                                        {person.createdBy && ` by ${person.createdBy.firstName} ${person.createdBy.lastName}`}
                                    </span>
                                </ContactInfoPanelRow>
                                {person.updatedOn && person.updatedBy && (
                                    <ContactInfoPanelRow title="Modified">
                                        <span>
                                            {dateTimeService.stringifyToDay(person.updatedOn)} by {person.updatedBy.firstName} {person.updatedBy.lastName}
                                        </span>
                                    </ContactInfoPanelRow>
                                )}
                            </ContactInfoPanel>
                        </StackLayout>
                    </StackLayout>
                </StackLayout>

                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="-w2 k-gap-4">
                    <ContactSidePanel title="Customer segments">
                        <PersonCustomerSegmentsList personCustomerSegmentsIds={person.customerSegmentIds} customerSegments={customerSegments} />
                    </ContactSidePanel>
                    <ContactSidePanel title="Tags">
                        <ContactTagsList tags={person.tags} />
                    </ContactSidePanel>
                    <ContactSidePanel
                        title="Activities"
                        renderHeader={
                            activities?.length || typeof activitiesTypeFilter !== 'undefined'
                                ? titleElement => (
                                      <PersonActivitiesHeader filter={activitiesTypeFilter} onFilterChange={setActivitiesTypeFilter}>
                                          {titleElement}
                                      </PersonActivitiesHeader>
                                  )
                                : undefined
                        }
                    >
                        <ActivitiesList
                            activities={activities}
                            emptyText={
                                activitiesTypeFilter
                                    ? `No scheduled or past activities of type ${activityTypeFilterValues.find(f => f.value === activitiesTypeFilter)?.text}`
                                    : 'No scheduled or past activities'
                            }
                            isLoading={isLoadingActivities}
                            onActivityClick={
                                canEdit
                                    ? a =>
                                          showActivityDialog(
                                              a.data.type,
                                              a.data.type === ActivityType.ResearchReachOut
                                                  ? { itemKey: { researchId: a.data.researchReachOut.research.id, reachOutId: a.details.id } }
                                                  : { itemKey: a.details.id }
                                          )
                                    : undefined
                            }
                            onDelete={canEdit ? activity => deleteActivity(personId, activity.data) : undefined}
                        />
                    </ContactSidePanel>
                </StackLayout>
            </StackLayout>

            {manageActivityDialogElement}
            {confirmDeleteActivityElement}
            {confirmDialog}
        </>
    );
}

export function usePerson(ideaId?: string, personId?: number, onDeleteNavigateTo?: To) {
    const [person, setPerson] = useState<FullPerson>();

    const loadCurrentPerson = useCallback(() => {
        if (!ideaId || !personId) return;

        contactsService.getPersonById(ideaId, personId).then(setPerson);
    }, [ideaId, personId]);

    useEffect(() => {
        loadCurrentPerson();
    }, [loadCurrentPerson]);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!ideaId || !personId) return;

        const handlePersonDelete = (e: RealTimeUpdatePersonEventData) => {
            if (e.ideaId !== ideaId) return;

            if (e.personId === personId) {
                dispatch(addNotification({ content: 'The person was deleted' }));
                if (onDeleteNavigateTo) navigate(onDeleteNavigateTo);
                else setPerson(undefined);
            } else if (person && person.referredByContactId === e.personId) {
                loadCurrentPerson();
            }
        };

        const handlePersonUpdate = (e: RealTimeUpdatePersonEventData) => {
            if (e.ideaId !== ideaId) return;

            if (e.personId === personId || (person && person.referredByContactId === e.personId)) loadCurrentPerson();
        };

        const handleCompanyChange = (e: RealTimeUpdateCompanyEventData) => {
            if (e.ideaId !== ideaId || !person || person.companyId !== e.companyId) return;

            loadCurrentPerson();
        };

        const handleTagChange = (e: RealTimeUpdateContactTagEventData) => {
            if (e.ideaId !== ideaId || !person || !person.tags || !person.tags.some(t => t.id === e.tagId)) return;

            loadCurrentPerson();
        };

        const handleTagRestore = (e: RealTimeUpdateContactTagEventData) => {
            if (e.ideaId !== ideaId || !person) return;

            loadCurrentPerson();
        };

        realTimeUpdatesEventHub.addEventListener('contact', 'personDelete', handlePersonDelete);
        realTimeUpdatesEventHub.addEventListener('contact', 'personUpdate', handlePersonUpdate);
        realTimeUpdatesEventHub.addEventListener('contact', 'companyUpdate', handleCompanyChange);
        realTimeUpdatesEventHub.addEventListener('contact', 'companyDelete', handleCompanyChange);
        realTimeUpdatesEventHub.addEventListener('contact', 'tagUpdate', handleTagChange);
        realTimeUpdatesEventHub.addEventListener('contact', 'tagDelete', handleTagChange);
        realTimeUpdatesEventHub.addEventListener('contact', 'tagRestore', handleTagRestore);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('contact', 'personDelete', handlePersonDelete);
            realTimeUpdatesEventHub.removeEventListener('contact', 'personUpdate', handlePersonUpdate);
            realTimeUpdatesEventHub.removeEventListener('contact', 'companyUpdate', handleCompanyChange);
            realTimeUpdatesEventHub.removeEventListener('contact', 'companyDelete', handleCompanyChange);
            realTimeUpdatesEventHub.removeEventListener('contact', 'tagUpdate', handleTagChange);
            realTimeUpdatesEventHub.removeEventListener('contact', 'tagDelete', handleTagChange);
            realTimeUpdatesEventHub.removeEventListener('contact', 'tagRestore', handleTagRestore);
        };
    }, [ideaId, personId, person, loadCurrentPerson, dispatch, navigate, onDeleteNavigateTo]);

    return person;
}

function PersonHeader({
    person,
    canEdit,
    onCreateActivity,
    onDelete
}: {
    person: Person;
    canEdit?: boolean;
    onCreateActivity?: <TActivity extends ActivityType>(activityType: TActivity, props?: ShowActivityDialogProps<TActivity> | undefined) => void;
    onDelete?: () => void;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-8">
            <UserAvatar
                className="k-avatar-xl"
                picture={person.picture}
                initials={generateInitials(2, person.firstName, person.lastName)}
                colorIndex={getPreferredColorIndex(person.id)}
            />
            <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-3">
                <H2>{person.name}</H2>

                <PersonActions canEdit={canEdit} onCreateActivity={onCreateActivity} onDelete={onDelete} />
            </StackLayout>
        </StackLayout>
    );
}

function PersonActions({
    canEdit,
    onCreateActivity,
    onDelete
}: {
    canEdit?: boolean;
    onCreateActivity?: <TActivity extends ActivityType>(activityType: TActivity, props?: ShowActivityDialogProps<TActivity> | undefined) => void;
    onDelete?: () => void;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
            <Link
                to="edit"
                className={combineClassNames(
                    'k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md',
                    canEdit ? undefined : 'k-disabled'
                )}
            >
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                    <EditIcon className="k-icp-icon-size-4" />
                    Edit contact
                </StackLayout>
            </Link>

            <Button disabled={!onCreateActivity} onClick={onCreateActivity ? () => onCreateActivity(ActivityType.Note) : undefined}>
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                    <AddNoteIcon className="k-icp-icon-size-4" />
                    Add note
                </StackLayout>
            </Button>

            <BoundDropDownButton
                icon="arrow-60-down"
                buttonClass="k-flex-row-reverse"
                text="More actions"
                items={[
                    {
                        text: 'Add meeting',
                        action() {
                            onCreateActivity?.(ActivityType.Meeting);
                        },
                        disabled: !onCreateActivity
                    },
                    {
                        text: 'Schedule interview',
                        action() {
                            onCreateActivity?.(ActivityType.Meeting, { initialResearchId: null });
                        },
                        disabled: !onCreateActivity
                    },
                    {
                        text: 'Reach out by email',
                        action() {
                            onCreateActivity?.(ActivityType.ReachOut, { initialData: { type: ReachOutType.Email } });
                        },
                        separated: true,
                        disabled: !onCreateActivity
                    },
                    {
                        text: 'Reach out by phone',
                        action() {
                            onCreateActivity?.(ActivityType.ReachOut, { initialData: { type: ReachOutType.Phone } });
                        },
                        disabled: !onCreateActivity
                    },
                    {
                        text: 'Reach out by LinkedIn',
                        action() {
                            onCreateActivity?.(ActivityType.ReachOut, { initialData: { type: ReachOutType.LinkedIn } });
                        },
                        disabled: !onCreateActivity
                    },

                    {
                        text: 'Delete contact',
                        action: onDelete,
                        separated: true,
                        danger: true,
                        disabled: !onDelete
                    }
                ]}
            />
        </StackLayout>
    );
}

function PersonCustomerSegmentsList({
    personCustomerSegmentsIds,
    customerSegments
}: {
    personCustomerSegmentsIds: number[] | undefined;
    customerSegments: BoxItem[] | undefined;
}) {
    if (!personCustomerSegmentsIds?.length) return <span className="k-icp-subtle-text">Not related to Customer segments</span>;
    if (!customerSegments) return <LoadingIndicator size="big" />;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
            {customerSegments
                .filter(s => personCustomerSegmentsIds!.includes(s.id))
                .map(s => (
                    <CanvasItemSimpleView key={s.id} item={s} />
                ))}
        </StackLayout>
    );
}

const activityTypeFilterValues = [
    { text: 'Show all', value: undefined },
    { text: 'Note', value: ActivityType.Note },
    { text: 'Reach out', value: ActivityType.ReachOut },
    { text: 'Meeting', value: ActivityType.Meeting },
    { text: 'Invite', value: ActivityType.ResearchReachOut }
];
function PersonActivitiesHeader({
    children,
    filter,
    onFilterChange
}: {
    children?: ReactElement;
    filter?: ActivityType;
    onFilterChange: (filter: ActivityType | undefined) => void;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-justify-content-between">
            {children}

            <DropDownList
                data={activityTypeFilterValues}
                textField="text"
                dataItemKey="value"
                defaultValue={activityTypeFilterValues[0]}
                onChange={e => onFilterChange(e.value.value)}
                style={{ width: 118 }}
            />
        </StackLayout>
    );
}

function ActivitiesList({
    activities,
    emptyText,
    isLoading,
    onActivityClick,
    onDelete
}: {
    activities?: GenericActivity[];
    emptyText: string;
    isLoading?: boolean;
    onActivityClick?: (activity: GenericActivity) => void;
    onDelete?: (activity: GenericActivity) => void;
}) {
    if (isLoading) return <LoadingIndicator size="big" />;
    if (!activities?.length) return <span className="k-icp-subtle-text">{emptyText}</span>;

    const upcomingActivities: GenericActivity[] = [];
    const pastActivities: GenericActivity[] = [];
    const currentTime = new Date();

    activities.forEach(activity => (activity.date > currentTime ? upcomingActivities.push(activity) : pastActivities.push(activity)));

    function renderActivity(activity: GenericActivity) {
        const ActivityCard = getActivityCardForType(activity.data.type);
        if (!ActivityCard) return undefined;
        return (
            <ActivityCard
                key={activity.data.id}
                activity={activity.data as ActivityOf<ActivityType>}
                onEdit={onActivityClick ? () => onActivityClick(activity) : undefined}
                onDelete={onDelete ? () => onDelete(activity) : undefined}
            />
        );
    }

    function renderActivityGroup(title: string, activitiesInGroup: GenericActivity[]) {
        if (!activitiesInGroup.length) return undefined;
        return (
            <div>
                <div className="k-fs-sm k-icp-subtle-text k-mb-2 k-text-uppercase">{title}</div>
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                    {activitiesInGroup.map(renderActivity)}
                </StackLayout>
            </div>
        );
    }

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
            {renderActivityGroup('Upcoming', upcomingActivities)}
            {renderActivityGroup('Past', pastActivities)}
        </StackLayout>
    );
}
