import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react';
import { AgentChatMessage, Chat, ChatMessage, ChatStreamMessage } from '../services/chatsService';

export function useChatActions(
    chat?: Chat,
    setChat?: (chat: Chat) => void,
    setMessages?: Dispatch<SetStateAction<ChatMessage[] | undefined>>,
    initializeChat?: () => Promise<Chat>,
    getAllChatMessages?: () => Promise<ChatMessage[]>,
    sendMessage?: (message: string) => AsyncIterable<ChatStreamMessage>,
    fixChat?: () => AsyncIterable<ChatStreamMessage>
) {
    const shouldInitializeChat = chat?.initialized === false;
    const isInitializingChatRef = useRef<boolean>();
    useEffect(() => {
        if (!initializeChat || !shouldInitializeChat || isInitializingChatRef.current) return;

        isInitializingChatRef.current = true;

        initializeChat()
            .then(setChat)
            .finally(() => (isInitializingChatRef.current = false));
    }, [initializeChat, setChat, shouldInitializeChat]);

    const isChatInitialized = chat?.initialized === true;
    useEffect(() => {
        if (!isChatInitialized || !getAllChatMessages) return;
        getAllChatMessages().then(setMessages);
    }, [getAllChatMessages, isChatInitialized, setMessages]);

    const processMessageStream = useCallback(
        async function* processMessageStream(stream: AsyncIterable<ChatStreamMessage> | undefined, onAgentMessage: (agentMessage: AgentChatMessage) => void) {
            if (!stream) return;
            for await (const chatStreamMessage of stream) {
                if (chatStreamMessage.type === 'block') yield chatStreamMessage.data;
                else if (chatStreamMessage.type === 'chat') setChat?.(chatStreamMessage.data);
                else if (chatStreamMessage.type === 'userMessage' || chatStreamMessage.type === 'contactMessage')
                    setMessages?.(messages => (messages ? [...messages, chatStreamMessage.data] : [chatStreamMessage.data]));
                else if (chatStreamMessage.type === 'agentMessage') onAgentMessage(chatStreamMessage.data);
            }
        },
        [setChat, setMessages]
    );

    const handleSendMessage = useCallback(
        (message: string, setContext: (context: AgentChatMessage) => void) => processMessageStream(sendMessage?.(message), setContext),
        [processMessageStream, sendMessage]
    );

    const handleMessageComplete = useCallback(
        (context: AgentChatMessage | undefined) => {
            if (!context) return;
            setMessages?.(messages => (messages ? [...messages, context] : [context]));
        },
        [setMessages]
    );

    const generateFixMessage = useCallback((setContext: (context: AgentChatMessage) => void) => processMessageStream(fixChat?.(), setContext), [
        fixChat,
        processMessageStream
    ]);

    return [sendMessage ? handleSendMessage : undefined, setMessages ? handleMessageComplete : undefined, fixChat ? generateFixMessage : undefined] as const;
}
