import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { getPersonFullName } from '../../services/common';
import { Person } from '../../services/contactsService';
import { InterviewReferredContact } from '../../services/interviewsV2Service';
import { DropDownButtonItem } from '../common/boundDropDownButton';
import { PersonCard, PersonDetailsCard } from '../contacts/contactCard';
import { ContactPickerDialog } from '../contacts/contactPicker';
import { CreatePersonDialog } from '../contacts/edit';

type ReferralCardProps = {
    interviewId: number;
    ideaId: string;
    refereeDetails: InterviewReferredContact;
    readonly?: boolean;
    addedReferee?: Person;
    onAddReferee?: (refereeId: number, contact: Person) => Promise<void>;
};

export function ReferralCard({ ideaId, refereeDetails, readonly, addedReferee, onAddReferee }: ReferralCardProps) {
    const [createReferalDialogOpen, setCreateReferalDialogOpen] = useState(false);
    const [pickExistingContactDialogOpen, setPickExistingContactDialogOpen] = useState(false);

    const [disabledWhileAddingReferred, handleDisabledWhileAddingReferred] = useSingleClickButton();
    const actions: DropDownButtonItem[] = [
        {
            text: 'Create new contact',
            action: () => setCreateReferalDialogOpen(true)
        },
        {
            text: 'Pick existing contact',
            action: () => setPickExistingContactDialogOpen(true)
        }
    ];

    const handleCreateNewContact = handleDisabledWhileAddingReferred(async (newPerson: Person) => {
        setCreateReferalDialogOpen(false);
        await onAddReferee?.(refereeDetails.id, newPerson);
    });

    const handlePickExistingContact = handleDisabledWhileAddingReferred(async (person: Person) => {
        setPickExistingContactDialogOpen(false);
        await onAddReferee?.(refereeDetails.id, person);
    });

    const name =
        !refereeDetails.firstName && !refereeDetails.lastName
            ? 'Unknown referee'
            : getPersonFullName(refereeDetails.firstName ?? undefined, refereeDetails.lastName ?? undefined);

    return (
        <>
            {addedReferee ? (
                <Link key={addedReferee.id} className="k-link" to={`/startups/${ideaId}/contacts/people/${addedReferee.id}`}>
                    <PersonCard person={addedReferee} />
                </Link>
            ) : (
                <PersonDetailsCard
                    name={name}
                    emailAddress={refereeDetails.emailAddress ?? undefined}
                    phoneNumber={refereeDetails.phoneNumber ?? undefined}
                    linkedIn={refereeDetails.linkedIn ?? undefined}
                    jobTitle={refereeDetails.jobTitle ?? undefined}
                    company={refereeDetails.company ?? undefined}
                    disabled={disabledWhileAddingReferred || readonly}
                    dashed
                    actions={actions}
                />
            )}
            {createReferalDialogOpen && (
                <CreatePersonDialog
                    ideaId={ideaId}
                    showPartialForm
                    onCancel={() => setCreateReferalDialogOpen(false)}
                    initialData={{
                        firstName: refereeDetails.firstName ?? undefined,
                        lastName: refereeDetails.lastName ?? undefined,
                        emailAddress: refereeDetails.emailAddress ?? undefined,
                        phoneNumber: refereeDetails.phoneNumber ?? undefined,
                        linkedIn: refereeDetails.linkedIn ?? undefined
                    }}
                    onCreated={handleCreateNewContact}
                />
            )}
            {pickExistingContactDialogOpen && (
                <ContactPickerDialog
                    ideaId={ideaId}
                    title="Pick existing contact"
                    placeholder="Search by contact name"
                    onSave={handlePickExistingContact}
                    onCancel={() => setPickExistingContactDialogOpen(false)}
                />
            )}
        </>
    );
}
