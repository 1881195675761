import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode } from 'react';
import { BackLinkHeader } from '../../components/common/BackLinkHeader';
import { InterviewSectionsConversationList } from '../../components/interview/entries/interviewEntries';
import { InterviewEmptyColumnView } from '../../components/interview/interviewModalLayout';
import { H2 } from '../../components/ui/typography';
import { useGlobalCanvas } from '../../hooks/canvasHooks';
import { useInterviewSections } from '../../hooks/interviewHooks';
import { useProblemValidationResearch } from '../../hooks/researchHooks';
import { useResearchParams } from '../../hooks/routerHooks';
import pickInterviewImageUrl from '../../images/pick-interview-illustration.svg';
import { ReducedPerson } from '../../services/contactsService';
import { TabHeader, TabbedLayout } from '../layouts/tabbedLayout';

const tabs: TabHeader[] = [
    {
        title: 'Summary',
        to: ''
    },
    {
        title: 'Insights',
        to: 'insights'
    },
    {
        title: 'Hypotheses',
        to: 'hypotheses'
    }
];

export function ResearchDetailsPage() {
    const { ideaId, researchId } = useResearchParams();
    const research = useProblemValidationResearch(ideaId, researchId, '..');
    useGlobalCanvas(ideaId, true);

    return (
        <div className="k-vstack k-h-full">
            <BackLinkHeader to=".." text="Back to Research" />

            <H2 className="!k-mb-3">{research ? research.title : <Skeleton shape="text" style={{ width: 300 }} />}</H2>
            <TabbedLayout
                headers={tabs}
                size="small"
                theme="secondary"
                navWrapperClassName="k-border-b k-border-b-solid k-icp-component-border k-pb-thin"
                data={research}
            />
        </div>
    );
}

export function ResearchInterviewLayout({
    children,
    ideaId,
    interviewId,
    interviewee,
    selectedEntryId
}: {
    children?: ReactNode;
    ideaId: string;
    interviewId?: number;
    interviewee?: ReducedPerson;
    selectedEntryId?: number;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} className="k-flex-1 k-icp-component-border k-min-h-0 k-mt--6 k-mb--6 k-mr--6">
            <div className="k-overflow-auto k-flex-1 k-pr-6 k-py-6">{children}</div>

            <div className="k-flex-basis-1/4 k-overflow-auto k-scroll-smooth k-py-6 k-px-4 k-icp-panel-base">
                <SelectedInterviewView ideaId={ideaId} interviewId={interviewId} selectedEntryId={selectedEntryId} interviewee={interviewee} />
            </div>
        </StackLayout>
    );
}

function SelectedInterviewView({
    ideaId,
    interviewId,
    interviewee,
    selectedEntryId
}: {
    ideaId: string;
    interviewId?: number;
    interviewee?: ReducedPerson;
    selectedEntryId?: number;
}) {
    const { interviewSections } = useInterviewSections(ideaId, interviewId);

    if (interviewId === undefined)
        return (
            <InterviewEmptyColumnView image={{ url: pickInterviewImageUrl, width: 64, height: 64, description: 'Pick interview' }}>
                Click on a quote to highlight it in the interview it was extracted from.
            </InterviewEmptyColumnView>
        );

    return <InterviewSectionsConversationList interviewSections={interviewSections} interviewee={interviewee} selectedEntryId={selectedEntryId} />;
}
