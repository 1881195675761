import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BackLinkHeader } from '../../components/common/BackLinkHeader';
import { BoundDropDownButton } from '../../components/common/boundDropDownButton';
import { CountryAndCityView } from '../../components/common/countries';
import { PersonCard } from '../../components/contacts/contactCard';
import { PeopleActiveFilters } from '../../components/contacts/contactListOptions';
import { ContactInfoPanel, ContactInfoPanelRow, ContactSidePanel, ContactSocialInfo } from '../../components/contacts/view';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H2 } from '../../components/ui/typography';
import UserAvatar from '../../components/user/userAvatar';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { ReactComponent as EditIcon } from '../../icons/edit-2.svg';
import { ReactComponent as ExternalLinkIcon } from '../../icons/external-link.svg';
import { combineClassNames, generateInitials, getPreferredColorIndex } from '../../services/common';
import { Company, FullCompany, Person, contactsService } from '../../services/contactsService';
import { dateTimeService } from '../../services/dateTimeService';
import {
    RealTimeUpdateCompanyEventData,
    RealTimeUpdatePersonEventData,
    RealTimeUpdatePersonUpdateEventData,
    realTimeUpdatesEventHub
} from '../../services/realTimeUpdatesService';
import { UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';

export function ViewCompanyPage() {
    const [company, setCompany] = useState<FullCompany>();
    const { ideaId, companyId: companyIdString } = useParams();
    const companyId = parseInt(companyIdString ?? '');

    const loadCurrentCompany = useCallback(() => {
        if (!companyId) return;

        contactsService.getCompanyById(ideaId!, companyId).then(setCompany);
    }, [companyId, ideaId]);

    useEffect(loadCurrentCompany, [loadCurrentCompany]);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const handleCompanyDelete = (e: RealTimeUpdateCompanyEventData) => {
            if (e.ideaId !== ideaId || e.companyId !== companyId) return;

            dispatch(addNotification({ content: 'The company was deleted' }));
            navigate('../companies');
        };

        const handleCompanyUpdate = (e: RealTimeUpdateCompanyEventData) => {
            if (e.ideaId !== ideaId || e.companyId !== companyId) return;

            loadCurrentCompany();
        };

        const handlePersonEvent = async (e: RealTimeUpdatePersonEventData) => {
            if (e.ideaId !== ideaId || e.companyId !== companyId) return;

            loadCurrentCompany();
        };

        const handlePersonUpdateEvent = async (e: RealTimeUpdatePersonUpdateEventData) => {
            if (e.ideaId !== ideaId || (e.companyId !== companyId && e.previousCompanyId !== companyId)) return;

            loadCurrentCompany();
        };

        realTimeUpdatesEventHub.addEventListener('contact', 'companyDelete', handleCompanyDelete);
        realTimeUpdatesEventHub.addEventListener('contact', 'companyUpdate', handleCompanyUpdate);

        realTimeUpdatesEventHub.addEventListener('contact', 'personAdd', handlePersonEvent);
        realTimeUpdatesEventHub.addEventListener('contact', 'personDelete', handlePersonEvent);
        realTimeUpdatesEventHub.addEventListener('contact', 'personRestore', handlePersonEvent);
        realTimeUpdatesEventHub.addEventListener('contact', 'personUpdate', handlePersonUpdateEvent);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('contact', 'companyDelete', handleCompanyDelete);
            realTimeUpdatesEventHub.removeEventListener('contact', 'companyUpdate', handleCompanyUpdate);

            realTimeUpdatesEventHub.removeEventListener('contact', 'personAdd', handlePersonEvent);
            realTimeUpdatesEventHub.removeEventListener('contact', 'personDelete', handlePersonEvent);
            realTimeUpdatesEventHub.removeEventListener('contact', 'personRestore', handlePersonEvent);
            realTimeUpdatesEventHub.removeEventListener('contact', 'personUpdate', handlePersonUpdateEvent);
        };
    }, [companyId, dispatch, ideaId, loadCurrentCompany, navigate]);

    const { show: showConfirmDialog, element: confirmDialog } = useConfirmDialog();
    function deleteCompany() {
        if (!company) return;

        showConfirmDialog({
            title: 'Delete company',
            content: (
                <>
                    Are you sure you want to delete the <strong>“{company.name}”</strong> company?
                </>
            ),
            confirmCheckboxText: 'I acknowledge that this cannot be undone',
            confirmButtonText: 'Delete company',
            callback: async () => {
                await contactsService.deleteCompany(ideaId!, company.id);
                navigate('../companies');
            }
        });
    }

    if (!company) return <LoadingIndicator size="big" className="!k-pos-absolute k-centered" />;

    return (
        <>
            <BackLinkHeader to="../companies" text="All companies" />
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-10">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1 k-gap-10">
                    <CompanyHeader company={company} onDelete={deleteCompany} />
                    <StackLayout align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-10">
                        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-10">
                            <ContactInfoPanel title="Basic information">
                                <ContactInfoPanelRow title="Website">
                                    {company.website ? (
                                        <a
                                            href={company.website}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="k-button-link-secondary k-d-inline-flex k-align-items-center k-gap-3"
                                        >
                                            {company.website} <ExternalLinkIcon className="k-icp-icon k-icp-icon-size-4 k-color-base" />
                                        </a>
                                    ) : (
                                        undefined
                                    )}
                                </ContactInfoPanelRow>
                                <ContactInfoPanelRow title="Country, City">
                                    {company.countryCode || company.city ? (
                                        <CountryAndCityView city={company.city} countryCode={company.countryCode} />
                                    ) : (
                                        undefined
                                    )}
                                </ContactInfoPanelRow>
                                <ContactInfoPanelRow title="Mailing address">{company.address}</ContactInfoPanelRow>
                            </ContactInfoPanel>
                            {(company.linkedIn || company.twitter || company.facebook) && (
                                <ContactInfoPanel title="Social">
                                    <ContactSocialInfo contact={company} />
                                </ContactInfoPanel>
                            )}
                        </StackLayout>
                        <div className="k-separator k-icp-component-border" />
                        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-10">
                            <ContactInfoPanel title="Administrative">
                                <ContactInfoPanelRow title="Created">
                                    <span>
                                        {dateTimeService.stringifyToDay(company.createdOn)}
                                        {company.createdBy && ` by ${company.createdBy.firstName} ${company.createdBy.lastName}`}
                                    </span>
                                </ContactInfoPanelRow>
                                {company.updatedOn && company.updatedBy && (
                                    <ContactInfoPanelRow title="Modified">
                                        <span>
                                            {dateTimeService.stringifyToDay(company.updatedOn)} by {company.updatedBy.firstName} {company.updatedBy.lastName}
                                        </span>
                                    </ContactInfoPanelRow>
                                )}
                            </ContactInfoPanel>
                        </StackLayout>
                    </StackLayout>
                </StackLayout>

                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="-w2 k-gap-4">
                    <ContactSidePanel title="Contacts in this company">
                        <PeopleInCompanyList companyId={companyId} people={company.people} totalCount={company.peopleCount} />
                    </ContactSidePanel>
                </StackLayout>
            </StackLayout>

            {confirmDialog}
        </>
    );
}

function CompanyHeader({ company, onDelete }: { company: Company; onDelete: () => void }) {
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canExecuteActions = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const companyNameParts = company.name.split(' ');
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-8">
            <UserAvatar
                className="k-avatar-xl"
                picture={company.picture}
                initials={generateInitials(2, companyNameParts[0], companyNameParts[1])}
                colorIndex={getPreferredColorIndex(company.id)}
            />
            <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-3">
                <H2>{company.name}</H2>

                <CompanyActions disabled={!canExecuteActions} onDelete={onDelete} />
            </StackLayout>
        </StackLayout>
    );
}

function CompanyActions({ disabled, onDelete }: { disabled?: boolean; onDelete: () => void }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
            <Link
                to="edit"
                className={combineClassNames(
                    'k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md',
                    disabled ? 'k-disabled' : undefined
                )}
            >
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                    <EditIcon className="k-icp-icon-size-4" />
                    Edit company
                </StackLayout>
            </Link>

            <BoundDropDownButton
                icon="arrow-60-down"
                buttonClass="k-flex-row-reverse"
                text="More actions"
                items={[
                    {
                        text: 'Delete company',
                        action() {
                            onDelete();
                        },
                        separated: true,
                        danger: true,
                        disabled: disabled
                    }
                ]}
            />
        </StackLayout>
    );
}

function PeopleInCompanyList({ companyId, people, totalCount }: { companyId: number; people?: Person[]; totalCount?: number }) {
    if (!people) return <LoadingIndicator size="big" />;

    if (!people.length) return <span className="k-icp-subtle-text">No contacts in this company</span>;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
            {people.map(p => (
                <Link key={p.id} className="k-link" to={`../people/${p.id}`}>
                    <PersonCard person={p} />
                </Link>
            ))}
            {typeof totalCount !== 'undefined' && totalCount > people.length && (
                <Link
                    to=".."
                    className="k-button k-button-sm k-button-rectangle k-button-flat k-button-flat-secondary k-rounded-md k-align-self-start"
                    state={{ companyId: companyId } as PeopleActiveFilters}
                >
                    View all {totalCount} Contacts from this Company
                </Link>
            )}
        </StackLayout>
    );
}
