import { StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Link, resolvePath, useNavigate } from 'react-router-dom';
import { BackLinkHeader } from '../../components/common/BackLinkHeader';
import { BoundDropDownButton } from '../../components/common/boundDropDownButton';
import { DaySlotsOverview, TimetableSlotView, WeeklySlotsOverview } from '../../components/events/slotsOverview';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { SvgIconButtonContent } from '../../components/ui/svgIconButtonContent';
import { H2, H3 } from '../../components/ui/typography';
import UserAvatar from '../../components/user/userAvatar';
import { useCopyToClipboard } from '../../hooks/commonHooks';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { useScheduleParams } from '../../hooks/routerHooks';
import { ReactComponent as EditIcon } from '../../icons/edit-2.svg';
import { ReactComponent as MapPinIcon } from '../../icons/map-pin-1.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as VideoIcon } from '../../icons/video.svg';
import { combineClassNames, resolveAbsoluteUrl } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { RealTimeUpdateResearchEventData, RealTimeUpdateScheduleEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { researchService } from '../../services/researchService';
import { FullSchedule, LocationOption, LocationOptionType, schedulesService } from '../../services/schedulesService';
import { ReducedUser, UserRole, generateInitials, getPreferredColorIndex } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';

export function ViewSchedulePage() {
    const { ideaId, scheduleId } = useScheduleParams();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canEdit = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const currentUserId = useAppSelector(s => s.user?.userId);
    const [schedule, setSchedule] = useState<FullSchedule>();
    const navigate = useNavigate();

    const loadSchedule = useCallback(() => schedulesService.getSchedule(ideaId, scheduleId).then(setSchedule), [ideaId, scheduleId]);

    useEffect(() => {
        loadSchedule();
    }, [loadSchedule]);

    const { show: showConfirmDialog, element: confirmDialog } = useConfirmDialog();
    function onDeleteSchedule() {
        if (!ideaId || !schedule) return;

        showConfirmDialog({
            title: 'Delete schedule',
            content: (
                <>
                    Are you sure you want to delete <strong>“{schedule.title}”</strong> schedule?
                </>
            ),
            confirmCheckboxText: 'I acknowledge that this cannot be undone',
            confirmButtonText: 'Delete schedule',
            callback: async () => {
                await schedulesService.deleteSchedule(ideaId, schedule.id);
                navigate('../schedules');
            }
        });
    }

    const scheduleRef = useRef(schedule);
    scheduleRef.current = schedule;
    const dispatch = useAppDispatch();
    useEffect(() => {
        function handleScheduleUpdate(e: RealTimeUpdateScheduleEventData) {
            if (e.ideaId !== ideaId || e.scheduleId !== scheduleId) return;

            loadSchedule();
        }

        const handleScheduleDelete = (e: RealTimeUpdateScheduleEventData) => {
            if (e.ideaId !== ideaId || e.scheduleId !== scheduleId) return;

            dispatch(addNotification({ content: 'The schedule was deleted' }));
            navigate('../schedules');
        };

        async function isResearchRelatedToSchedule(researchId: number): Promise<boolean> {
            if (!scheduleRef.current) return false;

            if (scheduleRef.current.relatedResearch.some(r => r.id === researchId)) return true;

            const research = await researchService.getProblemValidationResearch(ideaId, researchId);

            return research.scheduleIds !== undefined && research.scheduleIds.includes(scheduleId);
        }

        async function handleResearchSchedules(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId) return;
            if (!(await isResearchRelatedToSchedule(e.researchId))) return;

            loadSchedule();
        }

        async function handleResearchUpdated(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId) return;
            const loadedSchedule = scheduleRef.current;
            if (!loadedSchedule || !loadedSchedule.relatedResearch.some(r => r.id === e.researchId)) return;

            const updatedResearch = await researchService.getProblemValidationResearch(ideaId, e.researchId);

            setSchedule(s => {
                if (!s || !s.relatedResearch.some(r => r.id === e.researchId)) return s;
                return { ...s, relatedResearch: s.relatedResearch.map(r => (r.id === e.researchId ? updatedResearch : r)) };
            });
        }

        function handleResearchDeleted(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId) return;
            setSchedule(s => {
                if (!s || !s.relatedResearch.some(r => r.id === e.researchId)) return s;
                return { ...s, relatedResearch: s.relatedResearch.filter(r => r.id !== e.researchId) };
            });
        }

        async function handleResearchRestored(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId) return;

            const restoredResearch = await researchService.getProblemValidationResearch(ideaId, e.researchId);
            if (!restoredResearch.scheduleIds || !restoredResearch.scheduleIds.some(sId => sId === scheduleId)) return;

            setSchedule(s => {
                if (!s) return s;
                return { ...s, relatedResearch: [...s.relatedResearch, restoredResearch] };
            });
        }

        realTimeUpdatesEventHub.addEventListener('scheduling', 'scheduleUpdate', handleScheduleUpdate);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'scheduleDelete', handleScheduleDelete);
        realTimeUpdatesEventHub.addEventListener('research', 'schedulesUpdated', handleResearchSchedules);
        realTimeUpdatesEventHub.addEventListener('research', 'update', handleResearchUpdated);
        realTimeUpdatesEventHub.addEventListener('research', 'delete', handleResearchDeleted);
        realTimeUpdatesEventHub.addEventListener('research', 'restore', handleResearchRestored);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'scheduleUpdate', handleScheduleUpdate);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'scheduleDelete', handleScheduleDelete);
            realTimeUpdatesEventHub.removeEventListener('research', 'schedulesUpdated', handleResearchSchedules);
            realTimeUpdatesEventHub.removeEventListener('research', 'update', handleResearchUpdated);
            realTimeUpdatesEventHub.removeEventListener('research', 'delete', handleResearchDeleted);
            realTimeUpdatesEventHub.removeEventListener('research', 'restore', handleResearchRestored);
        };
    }, [dispatch, ideaId, loadSchedule, navigate, scheduleId]);

    if (!schedule) return <LoadingIndicator size="big" className="!k-pos-absolute k-centered" />;

    return (
        <>
            <BackLinkHeader to="../schedules" text="All schedules" />
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-10">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1 k-gap-10">
                    <ScheduleHeader title={schedule.title} code={schedule.code} canEdit={canEdit} onDelete={onDeleteSchedule} />
                    <ScheduleContentSection title="Details">
                        <ScheduleDetails schedule={schedule} />
                    </ScheduleContentSection>
                    <ScheduleContentSection
                        title="Weekly slots"
                        headerContent={
                            <TimetableSlotView>
                                {`${dateTimeService.stringifyToDay(schedule.startTime)} - ${dateTimeService.stringifyToDay(schedule.endTime)}`}
                            </TimetableSlotView>
                        }
                    >
                        <div className="k-icp-panel k-icp-panel-outlined k-p-2">
                            <WeeklySlotsOverview entries={schedule.timetable.entries} />
                        </div>
                    </ScheduleContentSection>
                    <ScheduleContentSection title="Special day slots">
                        {schedule.timetable.specialEntries.length ? (
                            <DaySlotsOverview days={schedule.timetable.specialEntries} />
                        ) : (
                            'No days with special slots defined'
                        )}
                    </ScheduleContentSection>
                </StackLayout>

                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4 -w2">
                    <ScheduleSidePanel title="Description">
                        {schedule.description || <span className="k-icp-subtle-text">No description set</span>}
                    </ScheduleSidePanel>
                    <ScheduleSidePanel title="Host & Location">
                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-mt-4 k-gap-4">
                            <ScheduleUserView user={schedule.user} isCurrent={schedule.user.userId === currentUserId} />
                            <LocationsList locations={schedule.locationOptions} />
                        </StackLayout>
                    </ScheduleSidePanel>
                </StackLayout>
            </StackLayout>
            {confirmDialog}
        </>
    );
}

function ScheduleHeader({ title, code, canEdit, onDelete }: { title: string; code: string; canEdit?: boolean; onDelete?: () => void }) {
    const bookingPagePath = `/bookings/${code}`;
    const copyToClipboard = useCopyToClipboard();

    return (
        <div>
            <H2 className="!k-mb-3">{title}</H2>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
                <Link
                    to="edit"
                    className={combineClassNames(
                        'k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md',
                        canEdit ? undefined : 'k-disabled'
                    )}
                >
                    <SvgIconButtonContent icon={EditIcon}>Edit schedule</SvgIconButtonContent>
                </Link>

                <BoundDropDownButton
                    icon="arrow-60-down"
                    buttonClass="k-flex-row-reverse"
                    text="More actions"
                    items={[
                        {
                            text: 'Open booking page',
                            action() {
                                const absoluteBookingPagePath = resolvePath(bookingPagePath).pathname;
                                window.open(absoluteBookingPagePath, '_blank');
                            },
                            disabled: !canEdit
                        },
                        {
                            text: 'Copy link to booking page',
                            action() {
                                const absoluteBookingPagePath = resolvePath(bookingPagePath).pathname;
                                const absoluteBookingPageUrl = resolveAbsoluteUrl(absoluteBookingPagePath);
                                copyToClipboard(absoluteBookingPageUrl, 'Booking page link');
                            },
                            disabled: !canEdit
                        },
                        {
                            text: 'Delete schedule',
                            action: onDelete,
                            separated: true,
                            danger: true,
                            disabled: !canEdit || !onDelete
                        }
                    ]}
                />
            </StackLayout>
        </div>
    );
}

function ScheduleSidePanel({ title, children }: { title: string; children?: ReactNode }) {
    return (
        <div className="k-icp-panel k-icp-panel-base !k-border-0 k-p-4">
            <H3 className="!k-mb-2">{title}</H3>
            {children}
        </div>
    );
}

function ScheduleUserView({ user, isCurrent, className }: { user: ReducedUser; isCurrent?: boolean; className?: string }) {
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className={combineClassNames('k-gap-2', className)}>
            <UserAvatar picture={user.picture} initials={generateInitials(user, 2)} colorIndex={getPreferredColorIndex(user)} />

            <span>
                {user.firstName} {user.lastName}
                {isCurrent && ' (You)'}
            </span>
        </StackLayout>
    );
}

const locationIconsMap: Record<LocationOptionType, ComponentType<React.SVGProps<SVGSVGElement>>> = {
    [LocationOptionType.InPerson]: MapPinIcon,
    [LocationOptionType.PhoneCall]: VideoIcon,
    [LocationOptionType.Online]: PhoneIcon,
    [LocationOptionType.Other]: MapPinIcon
};
function LocationsList({ locations }: { locations: LocationOption[] }) {
    function getLocationDescription(location: LocationOption) {
        if (location.type === LocationOptionType.InPerson) return `In person ${location.details}`;
        if (location.type === LocationOptionType.Other) return 'Other locations';

        return location.details || location.type;
    }

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            {locations.map(location => (
                <ScheduleLocationView
                    key={location.type}
                    icon={locationIconsMap[location.type]}
                    additionalContent={
                        location.type === LocationOptionType.InPerson ? (
                            <a
                                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location.details)}`}
                                target="_blank"
                                rel="noreferrer"
                                className="k-button-link-secondary k-fs-sm k-px-1"
                            >
                                View
                            </a>
                        ) : (
                            undefined
                        )
                    }
                >
                    {getLocationDescription(location)}
                </ScheduleLocationView>
            ))}
        </StackLayout>
    );
}

function ScheduleLocationView({
    icon: Icon,
    children,
    additionalContent
}: {
    icon: ComponentType<React.SVGProps<SVGSVGElement>>;
    children: string;
    additionalContent?: ReactElement;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-px-2 k-py-1 k-border k-border-solid k-icp-component-border">
            <Icon className="k-icp-icon k-icp-icon-size-4" />
            <span className="k-flex-1">{children}</span>
            {additionalContent}
        </StackLayout>
    );
}

function ScheduleContentSection({ title, headerContent, children }: { title: string; headerContent?: ReactNode; children: ReactNode }) {
    return (
        <div>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-mb-2 k-gap-3">
                <H3>{title}</H3>
                {headerContent}
            </StackLayout>
            {children}
        </div>
    );
}

function ScheduleDetails({ schedule }: { schedule: FullSchedule }) {
    const research = schedule.relatedResearch.length ? schedule.relatedResearch[0] : undefined;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
            <ScheduleDetail title="Related research">
                {research && (
                    <Link to={`../../research/${research.id}`} className="k-button-link-secondary">
                        {research.title}
                    </Link>
                )}
            </ScheduleDetail>
            <ScheduleDetail title="Event duration">{schedule.durationMinutes ? `${schedule.durationMinutes} min` : undefined}</ScheduleDetail>
            <ScheduleDetail title="Event buffer">
                {schedule.bufferBeforeMinutes || schedule.bufferAfterMinutes ? (
                    <ScheduleBufferDetailContent bufferBeforeMinutes={schedule.bufferBeforeMinutes} bufferAfterMinutes={schedule.bufferAfterMinutes} />
                ) : (
                    undefined
                )}
            </ScheduleDetail>
            <ScheduleDetail title="Booking notice">Same day booking is {!schedule.allowSameDayBooking && 'not '}allowed</ScheduleDetail>
            <ScheduleDetail title="Time zone">{dateTimeService.getGenericTimeZoneTitle(schedule.timeZone)}</ScheduleDetail>
        </StackLayout>
    );
}

function ScheduleDetail({ title, children }: { title: string; children?: ReactNode }) {
    if (!children) return null;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-3">
            <div className="k-icp-subtle-text">{title}:</div>
            <div>{children}</div>
        </StackLayout>
    );
}

function ScheduleBufferDetailContent({ bufferBeforeMinutes, bufferAfterMinutes }: { bufferBeforeMinutes?: number; bufferAfterMinutes?: number }) {
    if (!bufferBeforeMinutes && !bufferAfterMinutes) return null;

    function formatBuffer(value: number, type: 'before' | 'after') {
        return `${value} min ${type} event`;
    }

    if (!bufferAfterMinutes) return <>{formatBuffer(bufferBeforeMinutes!, 'before')}</>;
    if (!bufferBeforeMinutes) return <>{formatBuffer(bufferAfterMinutes!, 'after')}</>;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
            {formatBuffer(bufferBeforeMinutes, 'before')}
            <div className="dot-separator dot-separator-current-color" />
            {formatBuffer(bufferAfterMinutes, 'after')}
        </StackLayout>
    );
}
