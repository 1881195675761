import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn, GridRowClickEvent } from '@progress/kendo-react-grid';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AIAvatar } from '../../components/ai/aiAvatar';
import { BoundDropDownButton, DropDownButtonItem } from '../../components/common/boundDropDownButton';
import { CellTemplateProps, createCellTemplateFromComponent } from '../../components/common/grid';
import { interviewTypeToLabelMap } from '../../components/interview2/common';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { Pager } from '../../components/ui/pager';
import { H1 } from '../../components/ui/typography';
import UserAvatar from '../../components/user/userAvatar';
import { useInterviews } from '../../hooks/interviews2Hooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as NoInterviewsIllustration } from '../../images/interviews-illustration.svg';
import { generateInitials, getPersonFullName, getPreferredColorIndex } from '../../services/common';
import { ReducedPerson } from '../../services/contactsService';
import { dateTimeService } from '../../services/dateTimeService';
import { InterviewAnalysisStatus, InterviewStageV2, MinimalInterviewV2 } from '../../services/interviewsV2Service';
import { Interviews2ListPreferences, listPreferencesService } from '../../services/listPreferencesService';
import { ReducedUser, UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { TabbedLayout, TabHeader } from '../layouts/tabbedLayout';

type ListPreferencesContextProps = {
    listPreferences: Interviews2ListPreferences;
    updateListPreferences: (prefs: Partial<Interviews2ListPreferences>) => void;
};

const InterviewsListPreferencesContext = createContext<ListPreferencesContextProps>({
    listPreferences: listPreferencesService.getInterviewsListPreferences(),
    updateListPreferences: () => {}
});

function useInterviewsListPreferencesContext() {
    return useContext(InterviewsListPreferencesContext);
}

function InterviewsListProvider({ children }: { children: ReactNode }) {
    const [listPreferences, setListPreferences] = useState<Interviews2ListPreferences>(() => listPreferencesService.getInterviewsListPreferences());

    const updateListPreferences = useCallback(
        function(updatedListPreferences: Partial<Interviews2ListPreferences>) {
            const newListPreferences = { ...listPreferences, ...updatedListPreferences };
            listPreferencesService.saveInterviewsListPreferences(newListPreferences);
            setListPreferences(newListPreferences);
        },
        [listPreferences]
    );

    return <InterviewsListPreferencesContext.Provider value={{ listPreferences, updateListPreferences }}>{children}</InterviewsListPreferencesContext.Provider>;
}

export function InterviewsPage() {
    const navigate = useNavigate();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canExecuteActions = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;

    const tabs: TabHeader[] = [
        {
            title: 'Not started',
            to: ''
        },
        {
            title: 'In progress',
            to: 'in-progress'
        },
        {
            title: 'Completed',
            to: 'completed'
        }
    ];

    const dropdownItems: DropDownButtonItem[] = [
        { text: 'Upload an interview', action: () => navigate('upload') },
        { text: 'Upload multiple interviews', action: () => navigate('upload-multiple') }
    ];

    return (
        <InterviewsListProvider>
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="-minh100">
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-8 heading-row">
                    <H1>Interviews</H1>
                    {canExecuteActions && (
                        <>
                            <div className="vertical-separator" />
                            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                                <Button themeColor="primary" size="large" onClick={() => navigate('create')}>
                                    <StackLayout className="k-gap-1">
                                        <PlusIcon className="k-icp-icon" />
                                        New interview
                                    </StackLayout>
                                </Button>
                                <BoundDropDownButton
                                    size="large"
                                    popupSettings={{
                                        popupAlign: { horizontal: 'left', vertical: 'top' },
                                        anchorAlign: { horizontal: 'left', vertical: 'bottom' }
                                    }}
                                    text={
                                        <StackLayout className="k-gap-1">
                                            <PlusIcon className="k-icp-icon" />
                                            Upload interview
                                        </StackLayout>
                                    }
                                    items={dropdownItems}
                                />
                            </StackLayout>
                        </>
                    )}
                </StackLayout>
                <TabbedLayout headers={tabs} />
            </StackLayout>
        </InterviewsListProvider>
    );
}

export function NotStartedInterviewsTabPage() {
    const navigate = useNavigate();
    const { ideaId } = useIdeaParams();
    const { listPreferences, updateListPreferences } = useInterviewsListPreferencesContext();
    const [skip, setSkip] = useState(0);
    const { interviews: notStartedInterviews } = useInterviews(ideaId, InterviewStageV2.NotStarted, skip, listPreferences.pageSize);

    const handleRowClick = (e: GridRowClickEvent) => {
        navigate(`./${(e.dataItem as MinimalInterviewV2).id}`);
    };

    if (!notStartedInterviews) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    return (
        <InterviewsLayout
            emptyMessage="Start a new interview or upload a transcript from a past one to get insights."
            empty={notStartedInterviews.totalCount <= 0}
        >
            <InterviewsGrid
                interviews={notStartedInterviews.interviews}
                showHost
                handleRowClick={handleRowClick}
                actions={InterviewActionsCellTemplate}
                pagerComponent={
                    <Pager
                        total={notStartedInterviews.totalCount}
                        skip={skip}
                        take={listPreferences.pageSize}
                        onPageChange={(skip, take) => {
                            setSkip(skip);
                            updateListPreferences({ pageSize: take });
                        }}
                    />
                }
            />
        </InterviewsLayout>
    );
}

export function InProgressInterviewsPage() {
    const navigate = useNavigate();
    const { ideaId } = useIdeaParams();
    const { listPreferences, updateListPreferences } = useInterviewsListPreferencesContext();
    const [skip, setSkip] = useState(0);
    const { interviews: inProgressInterviews } = useInterviews(ideaId, InterviewStageV2.InProgress, skip, listPreferences.pageSize);

    const handleRowClick = (e: GridRowClickEvent) => {
        navigate(`./../${(e.dataItem as MinimalInterviewV2).id}`);
    };

    if (!inProgressInterviews) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    return (
        <InterviewsLayout emptyMessage="Your ongoing interviews will appear here as they happen." empty={inProgressInterviews.totalCount <= 0}>
            <InterviewsGrid
                interviews={inProgressInterviews.interviews}
                showHost
                showDate
                handleRowClick={handleRowClick}
                actions={InterviewActionsCellTemplate}
                pagerComponent={
                    <Pager
                        total={inProgressInterviews.totalCount}
                        skip={skip}
                        take={listPreferences.pageSize}
                        onPageChange={(skip, take) => {
                            setSkip(skip);
                            updateListPreferences({ pageSize: take });
                        }}
                    />
                }
            />
        </InterviewsLayout>
    );
}

export function CompletedInterviewsPage() {
    const navigate = useNavigate();
    const { ideaId } = useIdeaParams();
    const { listPreferences, updateListPreferences } = useInterviewsListPreferencesContext();
    const [skip, setSkip] = useState(0);
    const { interviews: completedInterviews } = useInterviews(ideaId, InterviewStageV2.Completed, skip, listPreferences.pageSize);

    const handleRowClick = (e: GridRowClickEvent) => {
        navigate(`./../${(e.dataItem as MinimalInterviewV2).id}`);
    };

    if (!completedInterviews) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    return (
        <InterviewsLayout
            emptyMessage="Once an interview is finished, you’ll find it here, ready for review with all key insights extracted."
            empty={completedInterviews.totalCount <= 0}
        >
            <InterviewsGrid
                interviews={completedInterviews.interviews}
                handleRowClick={handleRowClick}
                showDate
                showScore
                actions={InterviewActionsCellTemplate}
                pagerComponent={
                    <Pager
                        total={completedInterviews.totalCount}
                        skip={skip}
                        take={listPreferences.pageSize}
                        onPageChange={(skip, take) => {
                            setSkip(skip);
                            updateListPreferences({ pageSize: take });
                        }}
                    />
                }
            />
        </InterviewsLayout>
    );
}

function InterviewsLayout({ empty, children, emptyMessage }: { empty: boolean; children: ReactNode; emptyMessage: string }) {
    return (
        <StackLayout className="k-flex-1 page-content-middle page-content--xxl">
            {!empty ? (
                <>{children}</>
            ) : (
                <div className="k-display-flex k-justify-content-center k-items-center">
                    <EmptyInterviewsComponent emptyMessage={emptyMessage} />
                </div>
            )}
        </StackLayout>
    );
}

function EmptyInterviewsComponent({ emptyMessage }: { emptyMessage: string }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-6">
            <NoInterviewsIllustration />
            <div style={{ maxWidth: '480px' }} className="k-text-center">
                {emptyMessage}
            </div>
        </StackLayout>
    );
}

function InterviewsGrid({
    interviews,
    showHost,
    showScore,
    showDate,
    pagerComponent,
    actions,
    handleRowClick
}: {
    interviews: MinimalInterviewV2[];
    showHost?: boolean;
    showScore?: boolean;
    showDate?: boolean;
    actions?: (props: GridCellProps) => JSX.Element;
    pagerComponent?: ReactNode;
    handleRowClick?: (e: GridRowClickEvent) => void;
}) {
    return (
        <div>
            <Grid data={interviews} onRowClick={handleRowClick} className="k-grid-no-scrollbar k-icp-grid-navigatable k-icp-grid-light-header">
                <GridColumn title="Interviewee" cell={IntervieweeInfoGridCellTemplate} />
                {showHost && <GridColumn title="Interview Host" cell={HostInfoGridCellTemplate} />}
                {showScore && <GridColumn cell={InterviewScoreGridCellTemplate} title="Interview Score" width={172} />}
                <GridColumn title="Interview type" cell={InterviewTypeGridCellTemplate} className="k-fs-sm" width={172} />
                {showDate && <GridColumn cell={InterviewDateGridCellTemplate} title="Interview Date" className="k-fs-sm" width={110} />}
                <GridColumn cell={actions} width={80} />
            </Grid>
            {pagerComponent}
        </div>
    );
}
const InterviewActionsCellTemplate = createCellTemplateFromComponent(function({ dataItem: interview, cellProps }: CellTemplateProps<MinimalInterviewV2>) {
    const link = interview.stage === InterviewStageV2.NotStarted ? `./${interview.id}` : `./../${interview.id}`;
    return (
        <div className="k-display-flex k-justify-content-end">
            <Link to={link} className="!k-color-secondary k-button-link-secondary k-fs-sm k-font-semibold k-cursor-pointer">
                View
            </Link>
        </div>
    );
});

const InterviewTypeGridCellTemplate = createCellTemplateFromComponent(function({ dataItem }: CellTemplateProps<MinimalInterviewV2>) {
    return <div className="k-no-click">{interviewTypeToLabelMap[dataItem.type]}</div>;
});

const InterviewDateGridCellTemplate = createCellTemplateFromComponent(function({ dataItem }: CellTemplateProps<MinimalInterviewV2>) {
    const analysis = dataItem.analysis;
    const isPending = analysis?.status === InterviewAnalysisStatus.Pending && !dataItem.date;
    const date = dataItem.date ? dateTimeService.stringifyToDay(dataItem.date, false, true) : 'Unknown';
    return <div className="k-no-click">{isPending ? <Skeleton shape="text" /> : date}</div>;
});

const InterviewScoreGridCellTemplate = createCellTemplateFromComponent(function({ dataItem }: CellTemplateProps<MinimalInterviewV2>) {
    const analysis = dataItem.analysis;
    const isPending = analysis?.status === InterviewAnalysisStatus.Pending;
    const qualityScore = analysis?.qualityScore ?? undefined;
    const scriptCoverage = analysis?.scriptCoverage ?? undefined;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-0.5 k-no-click">
            <InterviewScoreRowComponent score={qualityScore} isPending={isPending && !qualityScore} label="Quality" />
            <InterviewScoreRowComponent score={scriptCoverage} isPending={isPending && !scriptCoverage} label="Script coverage" />
        </StackLayout>
    );
});

const InterviewScoreRowComponent = ({ score, isPending, label }: { score?: number; isPending: boolean; label: string }) => {
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
            {isPending ? (
                <Skeleton shape="text" className="k-w-9" />
            ) : (
                <span className="k-w-9">
                    {score !== undefined ? <span className="k-font-semibold ">{score}%</span> : <span className="k-font-normal k-icp-subtle-text">-</span>}
                </span>
            )}

            <span className="k-fs-sm">{label}</span>
        </StackLayout>
    );
};

const IntervieweeInfoGridCellTemplate = createCellTemplateFromComponent(function({ dataItem }: CellTemplateProps<MinimalInterviewV2>) {
    const interviewee = dataItem.contact;
    const fileName = interviewee ? undefined : dataItem.title;
    const isPendingAnalysis = dataItem.analysis?.status === InterviewAnalysisStatus.Pending;
    return (
        <IntervieweeInfoComponent
            person={interviewee}
            guessedName={dataItem.intervieweeName}
            fileName={fileName}
            isPendingAnalysis={isPendingAnalysis && !dataItem.intervieweeName && !interviewee?.name}
        />
    );
});

const HostInfoGridCellTemplate = createCellTemplateFromComponent(function({ dataItem }: CellTemplateProps<MinimalInterviewV2>) {
    const user = dataItem.host;
    const currentUser = useAppSelector(s => s.user);
    return <HostInfoComponent user={user} isCurrentUser={user?.userId === currentUser?.userId} isAI={dataItem.aiLed} />;
});

function IntervieweeInfoComponent({
    person,
    guessedName,
    fileName,
    isPendingAnalysis
}: {
    person: ReducedPerson | null;
    guessedName: string | null;
    fileName?: string;
    isPendingAnalysis?: boolean;
}) {
    return (
        <>
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-no-click">
                {person ? (
                    <UserAvatar
                        empty={!person}
                        picture={person?.picture}
                        initials={generateInitials(2, person?.firstName, person?.lastName)}
                        colorIndex={person && getPreferredColorIndex(person.id)}
                    />
                ) : (
                    <UserAvatar empty />
                )}

                <div className="k-flex-1">
                    {!isPendingAnalysis ? (
                        <div className="k-fs-lg k-font-semibold">{person?.name || guessedName || 'Unknown interviewee'}</div>
                    ) : (
                        <Skeleton className="k-fs-lg k-w-full" shape="text" />
                    )}
                    {person?.jobTitle && <div className="k-fs-sm k-icp-subtle-text">{person.jobTitle}</div>}
                </div>
            </StackLayout>
            {fileName && <div className="k-mt-0.5 k-fs-sm k-ml-14 k-no-click">{fileName}</div>}
        </>
    );
}

function HostInfoComponent({ user, isCurrentUser, isAI }: { user: ReducedUser | null; isCurrentUser: boolean; isAI: boolean }) {
    const name = isAI ? 'IVA' : user === null ? 'Unknown host' : getPersonFullName(user.firstName, user.lastName, isCurrentUser);
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-no-click">
            {isAI ? (
                <AIAvatar />
            ) : user === null ? (
                <UserAvatar empty />
            ) : (
                <UserAvatar
                    picture={user.picture}
                    initials={generateInitials(2, user.firstName, user.lastName)}
                    colorIndex={getPreferredColorIndex(user.userId)}
                />
            )}
            <span className="k-fs-md k-icp-hoverable">{name}</span>
        </StackLayout>
    );
}
