import { ButtonProps } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import { CSSProperties, forwardRef } from 'react';
import { ReactComponent as AIAvatarSvg } from '../../images/ai-avatar.svg';
import { combineClassNames } from '../../services/common';
import { DivButton } from '../common/DivButton';

type AIAvatarProps = {
    size?: string | number;
    iconSize?: string | number;
    borderSize?: string | number;
    animate?: boolean;
    animationType?: 'pulse' | 'draw';
    clickable?: boolean;
    hideBorder?: boolean;
    onAnimationIteration?: () => void;
    onClick?: () => void;
    className?: string;
};

export const AIAvatar = forwardRef<HTMLDivElement, AIAvatarProps>(function AIAvatar(
    { size, iconSize, borderSize, animate, animationType = 'draw', hideBorder, onAnimationIteration, clickable, onClick, className },
    ref
) {
    const wrapperSizeAttributes = getWrapperSizeAttributes(size);
    const avatarSizeAttributes = getAvatarSizeAttributes(iconSize);
    const borderSizeAttributes = getBorderSizeAttributes(borderSize);

    const Element = clickable ? DivButton : 'div';

    const buttonProps = clickable
        ? {
              fillMode: 'flat' as ButtonProps['fillMode'],
              rounded: 'full' as ButtonProps['rounded'],
              removeChildrenWrapper: true
          }
        : {};

    return (
        <Element
            ref={ref}
            {...buttonProps}
            className={combineClassNames(
                'k-d-flex k-align-items-center k-justify-content-center k-pos-relative',
                wrapperSizeAttributes.className,
                clickable ? '!k-min-w-0 !k-p-0 !k-border-none' : undefined,
                className
            )}
            style={wrapperSizeAttributes.style}
            onClick={onClick}
        >
            {!hideBorder && (
                <div
                    className={classNames(
                        'k-border-solid k-border-transparent ai-border k-rounded-full',
                        borderSizeAttributes.className,
                        animate && animationType === 'pulse' ? 'ai-border--pulse' : undefined,
                        animate && animationType === 'draw' ? 'ai-border--draw-infinite' : undefined
                    )}
                    style={borderSizeAttributes.style}
                    onAnimationIteration={animate ? onAnimationIteration : undefined}
                />
            )}
            <AIAvatarSvg {...avatarSizeAttributes} />
        </Element>
    );
});

function getWrapperSizeAttributes(size?: string | number): { className?: string; style?: CSSProperties } {
    if (!size || size === 'medium') return { className: 'k-avatar-md' };
    if (size === 'small') return { className: 'k-avatar-sm' };
    if (size === 'large') return { className: 'k-avatar-lg' };

    if (typeof size === 'string') return { className: size };

    return { style: { width: size, height: size } };
}

function getAvatarSizeAttributes(iconSize?: string | number): { className?: string; style?: CSSProperties } {
    if (!iconSize) return { className: 'k-icp-icon-size-6' };
    if (iconSize === '3' || iconSize === '4' || iconSize === '6') return { className: `k-icp-icon-size-${iconSize}` };

    if (typeof iconSize === 'string') return { className: iconSize };

    return { style: { width: iconSize, height: iconSize } };
}

function getBorderSizeAttributes(borderSize?: string | number): { className?: string; style?: CSSProperties } {
    if (!borderSize) return { className: 'k-border' };
    if (typeof borderSize === 'string') return { className: borderSize };

    return { style: { borderWidth: borderSize } };
}
