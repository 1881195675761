export function groupBy<TItem, TKey extends string | number>(collection: TItem[], keySelector: (item: TItem) => TKey): Partial<Record<TKey, TItem[]>> {
    const result: Partial<Record<TKey, TItem[]>> = {};
    for (const item of collection) {
        const groupKey = keySelector(item);
        const itemsInGroup = result[groupKey] ?? (result[groupKey] = []);
        itemsInGroup.push(item);
    }

    return result;
}

export function toRecord<TItem, TKey extends string | number, TValue>(
    collection: TItem[],
    keySelector: (item: TItem) => TKey,
    valueSelector: (item: TItem) => TValue
): Partial<Record<TKey, TValue>>;
export function toRecord<TItem, TKey extends string | number>(collection: TItem[], keySelector: (item: TItem) => TKey): Partial<Record<TKey, TItem>>;
export function toRecord<TItem, TKey extends string | number, TValue>(
    collection: TItem[],
    keySelector: (item: TItem) => TKey,
    valueSelector?: (item: TItem) => TValue
): Partial<Record<TKey, TItem | TValue>> {
    const record = collection.reduce<Partial<Record<TKey, TItem | TValue>>>((record, item) => {
        const itemKey = keySelector(item);
        record[itemKey] = valueSelector ? valueSelector(item) : item;

        return record;
    }, {});

    return record;
}

export function countWhere<TItem>(collection: TItem[], predicate: (item: TItem) => unknown) {
    const count = collection.reduce((count, item) => (predicate(item) ? count + 1 : count), 0);

    return count;
}

export function sum<TItem>(collection: TItem[], selector: (item: TItem) => number) {
    const count = collection.reduce((count, item) => count + selector(item), 0);

    return count;
}

export function distinct<TItem>(collection: TItem[], keySelector?: (item: TItem) => unknown): TItem[] {
    return collection.filter(uniqueItemsFilter(keySelector));
}

export function findLastIndex<TItem>(collection: TItem[], predicate: (item: TItem) => unknown): number {
    for (let i = collection.length - 1; i >= 0; i--) {
        if (predicate(collection[i])) {
            return i;
        }
    }
    return -1;
}

function uniqueItemsFilter<TItem>(keySelector?: (item: TItem) => unknown): (item: TItem, index: number, array: TItem[]) => boolean {
    if (keySelector)
        return function(item, index, array) {
            const itemKey = keySelector(item);
            return array.findIndex(i => keySelector(i) === itemKey) === index;
        };

    return uniqueItemsByRefFilter;
}

function uniqueItemsByRefFilter<TItem>(item: TItem, index: number, array: TItem[]) {
    return array.indexOf(item) === index;
}

// Group items by key by preserving the ordinal of key appearance
export class StableGroupsList<TItem, TKey extends string | number | symbol> {
    private readonly groups: { key: TKey; items: TItem[] }[] = [];
    private readonly groupsIndices: Record<string | number | symbol, number | undefined> = {};

    constructor(private readonly groupKeyGenerator: (item: TItem) => TKey) {}

    add(...items: TItem[]) {
        for (const item of items) {
            const itemKey = this.groupKeyGenerator(item);
            const groupIndex: number | undefined = this.groupsIndices[itemKey];
            if (groupIndex === undefined) {
                this.groupsIndices[itemKey] = this.groups.length;
                this.groups.push({ key: itemKey, items: [item] });
            } else this.groups[groupIndex].items.push(item);
        }
    }

    asArray() {
        return this.groups;
    }
}
