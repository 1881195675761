import { StackLayout } from '@progress/kendo-react-layout';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg';
import { combineClassNames } from '../../services/common';
import { ClickableStackLayout } from '../ui/clickableStackLayout';
import { RoundedIconBackground, ScoreView } from './common';

export interface Section {
    id: number;
    text: string;
    scorePercentage?: number;
}

interface SectionProps {
    sectionName: string;
    sections: Section[];
    selectedSectionId: number;
    onSelectedSection: (id: number) => void;
    className?: string;
}

export function SectionsView({ sectionName, sections, selectedSectionId, onSelectedSection, className }: SectionProps) {
    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'start', vertical: 'top' }}
            className={combineClassNames('k-gap-2', className)}
            style={{ maxWidth: 320 }}
        >
            <div className="k-icp-text-smaller k-text-uppercase">{sectionName}</div>
            {sections.map(section => (
                <SectionButton
                    key={section.id}
                    scorePercentage={section.scorePercentage}
                    id={section.id}
                    text={section.text}
                    selected={selectedSectionId === section.id}
                    onClick={onSelectedSection}
                />
            ))}
        </StackLayout>
    );
}

interface SectionButtonProps {
    id: number;
    text: string;
    selected: boolean;
    onClick: (id: number) => void;
    scorePercentage?: number;
}

function SectionButton({ id, text, selected, onClick, scorePercentage }: SectionButtonProps) {
    return (
        <ClickableStackLayout
            orientation="horizontal"
            onClick={() => {
                onClick?.(id);
            }}
            align={{ horizontal: 'start', vertical: 'middle' }}
            className={`k-justify-content-between k-w-full k-border k-border-solid k-rounded-md k-p-4 k-cursor-pointer  k-gap-2 ${
                selected ? 'k-text-secondary k-border-secondary k-icp-hover-bg-secondary-8' : 'k-icp-component-border k-icp-hover-bg-base-4'
            }`}
        >
            <div className={selected ? 'k-font-semibold' : ''}>
                <span className="k-mr-2">{text}</span>
                {typeof scorePercentage === 'number' && (
                    <PercentageChip className="k-font-normal" percentage={scorePercentage} theme={selected ? 'secondary' : 'base'} />
                )}
            </div>
            <ArrowRightIcon className="k-shrink-0 k-icp-icon" />
        </ClickableStackLayout>
    );
}

export function SummaryHeader({ title, summary, icon }: { title: string; summary: string; icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> }) {
    return (
        <>
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
                <RoundedIconBackground className="k-shrink-0" icon={icon} />
                <div className="k-fs-lg k-font-semibold">{title}</div>
            </StackLayout>
            <div>{summary}</div>
        </>
    );
}

export function hasQuotes(text: string) {
    const trimmed = text.trim();
    return (
        (trimmed.startsWith('"') || trimmed.startsWith('“') || trimmed.startsWith("'")) &&
        (trimmed.endsWith('"') || trimmed.endsWith('”') || trimmed.endsWith("'"))
    );
}

export function QuotePanel({
    text,
    icon,
    addQuotes,
    panelClassName
}: {
    text: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    addQuotes?: boolean;
    panelClassName?: string;
}) {
    const Icon = icon;
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
            <Icon className="k-shrink-0 k-icp-icon k-icp-icon-size-4 k-mt-1.5" />
            <div className={`k-px-2 k-py-1.5 k-rounded-md ${panelClassName}`}>{addQuotes ? `“${text}”` : text}</div>
        </StackLayout>
    );
}

export function PercentageChip({ percentage, theme, className }: { percentage: number; theme?: 'base' | 'secondary'; className?: string }) {
    const borderColorClass = theme === 'secondary' ? 'k-border-secondary' : theme === 'base' ? 'k-border-base' : 'k-border-transparent';
    const textColorClass = theme === 'secondary' ? 'k-text-secondary' : 'k-text-base';
    return (
        <span
            style={{ borderRadius: '20px' }}
            className={combineClassNames(
                'k-display-inline-block k-fs-sm k-px-1.5 k-py-0.5 k-border k-border-solid',
                borderColorClass,
                textColorClass,
                className
            )}
        >
            +{percentage}%
        </span>
    );
}

export function QualityNote({ text, score, className, maxWidth }: { text: string; score: number; className?: string; maxWidth?: number }) {
    return (
        <div className={combineClassNames('k-px-6 k-py-4 k-rounded-lg k-icp-bg-secondary-8', className)} style={{ maxWidth: maxWidth }}>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-justify-content-between k-gap-2 k-mb-4">
                <div className="k-fs-lg k-font-semibold">Quality score</div>
                <ScoreView percentage={score} />
            </StackLayout>
            <div>{text}</div>
        </div>
    );
}
