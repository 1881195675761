import { Button } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { Input } from '@progress/kendo-react-inputs';
import { GridLayout, GridLayoutItem, StackLayout } from '@progress/kendo-react-layout';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AIAvatar } from '../../components/ai/aiAvatar';
import { BackLinkHeader } from '../../components/common/BackLinkHeader';
import { BoundDropDownButton, DropDownButtonItem } from '../../components/common/boundDropDownButton';
import { hasQuotes, PercentageChip, QualityNote, QuotePanel, Section, SectionsView, SummaryHeader } from '../../components/interview2/analysisComponents';
import {
    InterviewStatusIndicator,
    IVA_USER,
    IVAUser,
    RoundedIconBackground,
    ScoreView,
    TextFileButton,
    UserDetailsText
} from '../../components/interview2/common';
import { HostPickerDialog } from '../../components/interview2/hostPicker';
import { InterviewAnalysisContext, useInterviewAnalysis, useInterviewAnalysisContext } from '../../components/interview2/interviewAnalysisContext';
import { ReferralCard } from '../../components/interview2/referralsPanel';
import { ScriptPickerDialog } from '../../components/interviewScript/changeScriptModal';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { SvgIconButtonContent } from '../../components/ui/svgIconButtonContent';
import { H2, H3 } from '../../components/ui/typography';
import UserAvatar from '../../components/user/userAvatar';
import { useCopyToClipboard, useSingleClickButton } from '../../hooks/commonHooks';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { ReactComponent as ArrowRightCircleIcon } from '../../icons/arrow-right-circle.svg';
import { ReactComponent as AwardIcon } from '../../icons/award.svg';
import { ReactComponent as FileTranscriptIcon } from '../../icons/file-transcript.svg';
import { ReactComponent as FileUploadedIcon } from '../../icons/file-uploaded.svg';
import { ReactComponent as InsightIcon } from '../../icons/insight.svg';
import { ReactComponent as LinkIcon } from '../../icons/link.svg';
import { ReactComponent as ListCheckIcon } from '../../icons/list-check.svg';
import { ReactComponent as PlayCircleIcon } from '../../icons/play-circle.svg';
import { ReactComponent as QuoteIcon } from '../../icons/quote-3.svg';
import { ReactComponent as ScriptIcon } from '../../icons/script.svg';
import { ReactComponent as SuggestionIcon } from '../../icons/thumbs-up.svg';
import { ReactComponent as ReferralIcon } from '../../icons/user-referred.svg';
import { ReactComponent as EmptyInsightsIllustration } from '../../images/insight-in-circle-illustration.svg';
import { ReactComponent as EmptyQualityIllustration } from '../../images/quality-in-circle-illustration.svg';
import { combineClassNames, generateInitials, getPersonFullName, getPreferredColorIndex, resolveAbsoluteUrl } from '../../services/common';
import { ReducedPerson } from '../../services/contactsService';
import { dateTimeService } from '../../services/dateTimeService';
import { NotFoundException } from '../../services/httpServiceBase';
import {
    ImportedInterviewDetailV2,
    InterviewAnalysisStatus,
    InterviewExecutionTypeV2,
    InterviewInsight,
    InterviewInsightCategory,
    InterviewNextStep,
    InterviewQualityAssessmentArea,
    InterviewQualityAssessmentRecommendation,
    InterviewStageV2,
    interviewsV2Service,
    InterviewV2
} from '../../services/interviewsV2Service';
import { RealTimeUpdateInterviewEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { ReducedUser, UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addErrorNotification } from '../../state/notifications/platformNotificationsSlice';
import { TabbedLayout } from '../layouts/tabbedLayout';
import { InterviewScriptViewModal } from '../scripts/interviewScriptViewDialog';
import FileViewModal from './FileViewModal';

const tabHeaders = [
    { title: 'Overview', to: '' },
    { title: 'Insights', to: 'insights' },
    { title: 'Quality', to: 'quality' }
];

type MeetingInstructions = {
    title: string;
    instructions: string[];
};

const MEETING_INSTRUCTIONS: Record<InterviewExecutionTypeV2, MeetingInstructions> = {
    [InterviewExecutionTypeV2.IvaChat]: {
        title: 'AI-led interview',
        instructions: [
            'The interviewee will engage in an online chat with IVA.',
            'IVA will gather the required information by following your prepared interview script and organize all findings for your review.',
            'Start your interview by sharing the following link:'
        ]
    },
    [InterviewExecutionTypeV2.OnlineMeeting]: {
        title: 'Online meeting',
        instructions: [
            'Online interviews can be conducted with Google Meet.',

            'IVA will join your meeting as a silent attendant, taking notes and giving you hints what to ask next, so you never miss crucial information around your most important questions.',
            'Input your meeting information, so IVA is ready to join as soon as you start the interview:'
        ]
    },
    [InterviewExecutionTypeV2.InPersonMeeting]: {
        title: 'In-person meeting',
        instructions: [
            'In-person interviews require both you and your interviewee to be in the same location, with your laptop or phone readily accessible.',

            'IVA will actively listen through your device, take detailed notes, and monitor the points outlined in your interview script. It will also provide helpful hints to guide your conversation seamlessly.',
            'Ensure your device is positioned to capture both you and your interviewee clearly, with the microphone enabled for optimal audio quality.'
        ]
    },
    [InterviewExecutionTypeV2.Import]: {
        title: '',
        instructions: []
    }
};

export default function InterviewDetailsPage() {
    const { ideaId } = useIdeaParams();
    const { interviewId: interviewIdParam } = useParams();

    if (!interviewIdParam) {
        throw new NotFoundException();
    }

    const [details, setDetails] = useState<InterviewV2 | null>(null);
    const [showChangeHostDialog, setShowChangeHostDialog] = useState(false);
    const [showChangeScriptDialog, setShowChangeScriptDialog] = useState(false);
    const parsedInterviewId = useMemo(() => parseInt(interviewIdParam), [interviewIdParam]);
    const currentUserRole = useAppSelector(s => s.idea.role);
    const currentUserId = useAppSelector(s => s.user?.userId);
    const canExecuteActions = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const navigate = useNavigate();
    const { show: showConfirmDialog, element: confirmDialog } = useConfirmDialog();

    useEffect(() => {
        // Subscribe to interview real-time updates
        const handleInterviewUpdate = async (event: RealTimeUpdateInterviewEventData) => {
            if (event.interviewId !== parsedInterviewId || event.ideaId !== ideaId) return;

            const interview = await interviewsV2Service.getInterview(ideaId, parsedInterviewId);
            setDetails(interview);
        };

        realTimeUpdatesEventHub.addEventListener('interview3', 'update', handleInterviewUpdate);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview3', 'update', handleInterviewUpdate);
        };
    }, [ideaId, parsedInterviewId]);

    useEffect(() => {
        const fetchDetails = async () => {
            if (!parsedInterviewId) return;

            const interviewDetails = await interviewsV2Service.getInterview(ideaId, parsedInterviewId);
            setDetails(interviewDetails);
        };

        fetchDetails();
    }, [ideaId, parsedInterviewId]);

    const handleChangeHost = async (host: ReducedUser | IVAUser) => {
        if (!parsedInterviewId) return;

        const isAiLed = host.userId === IVA_USER.userId;
        const updatedHost = isAiLed ? undefined : host;

        const updatedInterview = await interviewsV2Service.updateInterview(ideaId, parsedInterviewId, isAiLed, updatedHost?.userId ?? null);
        setDetails(updatedInterview);
        setShowChangeHostDialog(false);
    };

    const handleChangeScript = async (scriptId: number) => {
        if (!parsedInterviewId) return;

        const updatedInterview = await interviewsV2Service.updateInterview(ideaId, parsedInterviewId, undefined, undefined, scriptId);
        setDetails(updatedInterview);
        setShowChangeScriptDialog(false);
    };

    const handleDeleteInterview = async () => {
        if (!parsedInterviewId) return;

        showConfirmDialog({
            title: 'Delete interview',
            content: (
                <div>Are you sure you want to delete this interview? Deleting a complete interview makes all derivative insights unavailable for use.</div>
            ),
            confirmButtonText: 'Delete interview',
            callback: async () => {
                await interviewsV2Service.deleteInterview(ideaId, parsedInterviewId);
                navigate('..');
            }
        });
    };

    if (!details) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    const interviewMoreActions: DropDownButtonItem[] | undefined =
        details.stage === InterviewStageV2.NotStarted
            ? [
                  {
                      text: 'Change host',
                      action: e => {
                          setShowChangeHostDialog(true);
                      },
                      //TODO: R4 Remove when real-time interview execution is implemented
                      disabled: true
                  },
                  { text: 'Change script', action: e => setShowChangeScriptDialog(true) },
                  { text: 'Delete interview', danger: true, separated: true, action: handleDeleteInterview }
              ]
            : details.stage === InterviewStageV2.Completed || details.imported
            ? [{ text: 'Delete interview', danger: true, action: handleDeleteInterview }]
            : undefined;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-min-h-full">
            {details.stage === InterviewStageV2.Completed || details.imported ? (
                <BackLinkHeader to="./../completed" text="Back to Completed interviews" />
            ) : details.stage === InterviewStageV2.NotStarted || details.stage === InterviewStageV2.InProgress ? (
                <BackLinkHeader to="./.." text="Back to Not started interviews" />
            ) : null}
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1">
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                    <div className="k-flex-1">
                        <H2>
                            <span className="k-mr-2 k-align-middle">{details.title}</span>
                            <InterviewStatusIndicator
                                className="k-d-inline-block k-align-middle"
                                text={
                                    details.stage === InterviewStageV2.NotStarted
                                        ? 'Not started'
                                        : details.stage === InterviewStageV2.InProgress
                                        ? 'In progress'
                                        : details.imported
                                        ? 'Uploaded'
                                        : 'Completed'
                                }
                                type={
                                    details.stage === InterviewStageV2.NotStarted
                                        ? 'warning'
                                        : details.stage === InterviewStageV2.InProgress
                                        ? 'alert'
                                        : details.imported
                                        ? 'base'
                                        : 'success'
                                }
                            />
                        </H2>
                    </div>
                    {/* TODO R2: Add correct link for handleGoToAIInterviewInProgress when page is implemented */}
                    <InterviewDetailsActions
                        interviewStage={details.stage}
                        isImported={details.imported}
                        isAILed={details.aiLed}
                        isViewer={!canExecuteActions}
                        handleGoToAIInterviewInProgress={() => console.log('Go to AI Interview in progress')}
                        handleGoToRealTimeInterviewInProgress={() => console.log('Go to real interview in progress')}
                        isCurrentUserHost={currentUserId === details.host?.userId}
                        actionsItems={interviewMoreActions}
                    />
                </StackLayout>

                {details.stage === InterviewStageV2.NotStarted || details.stage === InterviewStageV2.InProgress ? (
                    <NotCompletedInterviewPage ideaId={ideaId} details={details} />
                ) : (
                    <CompletedInterviewPage ideaId={ideaId} interviewId={details.id} />
                )}
            </StackLayout>
            {showChangeHostDialog && (
                <HostPickerDialog
                    ideaId={ideaId}
                    title="Change host"
                    currentHost={!details.aiLed ? details.host! : IVA_USER}
                    onSave={host => handleChangeHost(host)}
                    onCancel={() => setShowChangeHostDialog(false)}
                />
            )}

            {showChangeScriptDialog && details.scriptId && (
                <ScriptPickerDialog
                    ideaId={ideaId}
                    interviewType={details.type}
                    title="Change interview script"
                    currentScriptId={details.scriptId}
                    onSave={scriptId => handleChangeScript(scriptId)}
                    onCancel={() => setShowChangeScriptDialog(false)}
                />
            )}
            {confirmDialog}
        </StackLayout>
    );
}

function InterviewDetailsActions({
    interviewStage,
    isImported,
    isAILed,
    isViewer,
    isCurrentUserHost,
    handleGoToAIInterviewInProgress,
    handleGoToRealTimeInterviewInProgress,
    actionsItems
}: {
    interviewStage: InterviewStageV2;
    isImported: boolean;
    isAILed?: boolean;
    isViewer: boolean;
    isCurrentUserHost?: boolean;
    handleGoToAIInterviewInProgress?: () => void;
    handleGoToRealTimeInterviewInProgress?: () => void;
    actionsItems?: DropDownButtonItem[];
}) {
    if ((interviewStage === InterviewStageV2.Completed || isImported) && !isViewer) {
        return (
            <StackLayout orientation="horizontal" align={{ vertical: 'top', horizontal: 'start' }} className="k-gap-3">
                <BoundDropDownButton
                    popupSettings={{
                        anchorAlign: { horizontal: 'right', vertical: 'bottom' },
                        popupAlign: { horizontal: 'right', vertical: 'top' }
                    }}
                    items={actionsItems}
                    text="Actions"
                    icon="arrow-60-down"
                    buttonClass="k-flex-row-reverse"
                />
            </StackLayout>
        );
    }

    if (interviewStage === InterviewStageV2.InProgress) {
        if (!isCurrentUserHost) {
            return (
                <div>
                    <Button onClick={isAILed ? handleGoToAIInterviewInProgress : handleGoToRealTimeInterviewInProgress}>
                        <SvgIconButtonContent icon={ArrowRightCircleIcon}>View interview in progress</SvgIconButtonContent>
                    </Button>
                </div>
            );
        }

        return (
            <div>
                <Button themeColor="primary" onClick={isAILed ? handleGoToAIInterviewInProgress : handleGoToRealTimeInterviewInProgress}>
                    <SvgIconButtonContent icon={PlayCircleIcon}>Continue interview</SvgIconButtonContent>
                </Button>
            </div>
        );
    }

    if (interviewStage === InterviewStageV2.NotStarted && !isViewer) {
        // const startInterviewActions: DropDownButtonItem[] = [{ text: 'Start online interview' }, { text: 'Start in-person interview' }];
        return (
            <BoundDropDownButton
                popupSettings={{
                    anchorAlign: { horizontal: 'right', vertical: 'bottom' },
                    popupAlign: { horizontal: 'right', vertical: 'top' }
                }}
                items={actionsItems}
                text="Actions"
                icon="arrow-60-down"
                buttonClass="k-flex-row-reverse"
            />
        );

        //TODO R4: Add Take Over & Start interview action when real-time interview execution is implemented
        // return (
        //     <StackLayout orientation="horizontal" align={{ vertical: 'top', horizontal: 'start' }} className="k-gap-3">
        //         <BoundDropDownButton
        //             items={startInterviewActions}
        //             themeColor={isAILed ? 'base' : 'primary'}
        //             icon="arrow-60-down"
        //             buttonClass="k-flex-row-reverse"
        //             popupSettings={{
        //                 anchorAlign: { horizontal: 'left', vertical: 'bottom' },
        //                 popupAlign: { horizontal: 'left', vertical: 'top' }
        //             }}
        //             text={<SvgIconButtonContent icon={PlayIcon}>{isAILed ? 'Take Over & Start interview' : 'Start interview'}</SvgIconButtonContent>}
        //         />
        //         <BoundDropDownButton
        //             popupSettings={{
        //                 anchorAlign: { horizontal: 'right', vertical: 'bottom' },
        //                 popupAlign: { horizontal: 'right', vertical: 'top' }
        //             }}
        //             items={actionsItems}
        //             text="More actions"
        //             icon="arrow-60-down"
        //             buttonClass="k-flex-row-reverse"
        //         />
        //     </StackLayout>
        // );
    }

    return null;
}

export function NotCompletedInterviewPage({ ideaId, details }: { ideaId: string; details: InterviewV2 }) {
    const currentUserId = useAppSelector(state => state.user?.userId);
    const [showPreviewScriptDialog, setShowPreviewScriptDialog] = useState(false);

    if (!details || !currentUserId) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    return (
        <div className="page-content-middle page-content--xxl k-mt-6">
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8">
                <IntervieweeDetailsSection interviewee={details.contact} intervieweeName={details.intervieweeName} />
                <HostAndDetailsSection
                    interviewDetails={details}
                    currentUserId={currentUserId}
                    fileButtons={
                        details.scriptId && details.scriptName ? (
                            <TextFileButton
                                title={details.scriptName}
                                icon={ScriptIcon}
                                onClick={() => {
                                    setShowPreviewScriptDialog(true);
                                }}
                            />
                        ) : null
                    }
                />

                <MeetingInstructionsPanel
                    meetingId={details.meetingCode}
                    fixedType={details.stage === InterviewStageV2.InProgress || details.stage === InterviewStageV2.Completed ? details.detail?.type : undefined}
                    publicCode={details.publicCode}
                    isAiLed={details.aiLed}
                />
            </StackLayout>

            {showPreviewScriptDialog && details.scriptId && details.scriptName && (
                <InterviewScriptViewModal
                    ideaId={ideaId}
                    scriptId={details.scriptId}
                    scriptName={details.scriptName}
                    onClose={() => setShowPreviewScriptDialog(false)}
                />
            )}
        </div>
    );
}

export function CompletedInterviewPage({ ideaId, interviewId }: { ideaId: string; interviewId: number }) {
    const dispatch = useAppDispatch();
    const { analysis, associateContactWithReferee } = useInterviewAnalysis(ideaId, interviewId);

    useEffect(() => {
        if (analysis?.status === InterviewAnalysisStatus.Error) {
            dispatch(addErrorNotification('Failed to analyze the interview.'));
        }
    }, [analysis?.status, dispatch]);

    if (!analysis) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    return (
        <InterviewAnalysisContext.Provider value={{ analysis, associateContactWithReferee }}>
            <TabbedLayout
                size="small"
                headers={tabHeaders}
                theme="secondary"
                navWrapperClassName="k-border-b k-border-b-solid k-icp-component-border k-pb-thin k-mt-2"
            />
        </InterviewAnalysisContext.Provider>
    );
}

function MeetingInstructionsPanel({
    meetingId,
    publicCode,
    fixedType,
    isAiLed
}: {
    meetingId?: string | null;
    publicCode?: string | null;
    fixedType?: InterviewExecutionTypeV2;
    isAiLed?: boolean;
}) {
    const [meetingInstructionsType, setMeetingInstructionsType] = useState<InterviewExecutionTypeV2>(fixedType ?? InterviewExecutionTypeV2.OnlineMeeting);
    const { title, instructions } = MEETING_INSTRUCTIONS[isAiLed ? InterviewExecutionTypeV2.IvaChat : meetingInstructionsType];

    const handleSwitchMeetingInstructions = () => {
        setMeetingInstructionsType(type =>
            type === InterviewExecutionTypeV2.OnlineMeeting ? InterviewExecutionTypeV2.InPersonMeeting : InterviewExecutionTypeV2.OnlineMeeting
        );
    };

    if (meetingInstructionsType === InterviewExecutionTypeV2.Import) {
        return null;
    }

    return (
        <MeetingDetailsPanel
            title={title}
            headerRightElement={
                isAiLed || fixedType ? (
                    undefined
                ) : (
                    <Button onClick={handleSwitchMeetingInstructions} themeColor="secondary" fillMode="flat" size="small">
                        {meetingInstructionsType === InterviewExecutionTypeV2.OnlineMeeting
                            ? 'View instructions for in-person meeting'
                            : 'View instructions for online meeting'}
                    </Button>
                )
            }
        >
            <MeetingInstructionsView meetingInstructions={instructions} />
            {isAiLed && publicCode ? (
                <MeetingLinkComponent className="k-mt-4 k-ml-11" publicCode={publicCode} />
            ) : !isAiLed ? (
                <MeetingIDComponent className="k-mt-4 k-ml-11" meetingId={meetingId} />
            ) : null}
        </MeetingDetailsPanel>
    );
}

function getIVAInterviewLink(publicCode: string) {
    return `/interviews/${publicCode}/ai`;
}

function generateAbsoluteIVAInterviewLink(publicCode: string) {
    return resolveAbsoluteUrl(getIVAInterviewLink(publicCode));
}

//TODO R4 check this link
function generateGoogleMeetLink(meetingId: string) {
    return `https://meet.google.com/${meetingId}`;
}

function MeetingLinkComponent({ publicCode, className }: { publicCode: string; className?: string }) {
    const copyToClipboard = useCopyToClipboard();
    const link = generateAbsoluteIVAInterviewLink(publicCode);

    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className={combineClassNames('k-gap-2', className)}>
            <div className="k-text-secondary">{link}</div>
            <Button className="!k-min-w-0 !k-p-2" onClick={() => copyToClipboard(link, 'Interview link')}>
                <LinkIcon className="k-icp-icon" />
            </Button>
        </StackLayout>
    );
}

function MeetingIDComponent({ meetingId, className }: { meetingId?: string | null; className?: string }) {
    const [meetingLinkValue, setMeetingLinkValue] = useState<string | undefined>(undefined);
    const [meetingLink, setMeetingLink] = useState<string | undefined>(meetingId ? generateGoogleMeetLink(meetingId) : undefined);
    const [inviteInProgress, inviteIvaWrapper] = useSingleClickButton();

    const handleInviteIVA = inviteIvaWrapper(async () => {
        //TODO R4 verify link to server
        await new Promise(resolve => setTimeout(resolve, 1000)); // Add 1 second delay

        setMeetingLink(meetingLinkValue);
    });

    const handleChangeMeetingLink = () => {
        setMeetingLink(undefined);
        setMeetingLinkValue(undefined);
    };

    return (
        <div className={combineClassNames('', className)}>
            {!meetingLink ? (
                <>
                    <label htmlFor="meeting-id" className="k-mb-1 k-display-block">
                        Meeting ID
                    </label>
                    <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                        <Input
                            id="meeting-id"
                            className="k-flex-1"
                            placeholder="e.g. meet.google.com/izp-ppmz-bqs"
                            value={meetingLinkValue}
                            disabled={inviteInProgress}
                            onChange={e => {
                                setMeetingLinkValue(e.value);
                            }}
                        />
                        <Button onClick={handleInviteIVA} disabled={!meetingLinkValue || inviteInProgress}>
                            Invite IVA
                        </Button>
                    </StackLayout>
                </>
            ) : (
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-icp-panel k-justify-content-between k-px-4 k-py-2">
                    <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                        <AIAvatar />
                        <div>IVA is invited.</div>
                    </StackLayout>
                    <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2.5">
                        <div className="k-fs-sm">{meetingLinkValue}</div>
                        <Button onClick={handleChangeMeetingLink}>Change meeting ID</Button>
                    </StackLayout>
                </StackLayout>
            )}
        </div>
    );
}

function MeetingInstructionsView({ meetingInstructions }: { meetingInstructions: string[] }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            {meetingInstructions.map((instruction, index) => (
                <StackLayout key={index} orientation="horizontal" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-3">
                    <ArrowRightCircleIcon className="k-m-1 k-icp-icon k-icp-icon-size-6 k-flex-shrink-0" />
                    <div className="k-mt-1.5">{instruction}</div>
                </StackLayout>
            ))}
        </StackLayout>
    );
}

function MeetingDetailsPanel({ title, headerRightElement, children }: { title: string; headerRightElement?: ReactNode; children?: ReactNode }) {
    return (
        <>
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-icp-panel k-icp-panel-content k-gap-4">
                <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                    <H3 className="k-flex-1">{title}</H3>
                    {headerRightElement}
                </StackLayout>
                <div className="k-separator k-icp-component-border" />
                <div style={{ maxWidth: 640 }}>{children}</div>
            </StackLayout>
        </>
    );
}

export function OverviewTab() {
    //TODO R4: Implement dialog for human-led interviews
    // const [viewInterview, setViewInterview] = useState(false);
    const [viewScript, setViewScript] = useState(false);
    const [viewUploadedInterview, setViewUploadedInterview] = useState(false);
    const [viewUploadedScript, setViewUploadedScript] = useState(false);
    const navigate = useNavigate();
    const currentUserId = useAppSelector(state => state.user?.userId);
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canExecuteActions = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const { ideaId } = useIdeaParams();
    const { interviewId: interviewIdParam } = useParams();
    const parsedInterviewId = useMemo(() => parseInt(interviewIdParam!), [interviewIdParam]);
    const [details, setDetails] = useState<InterviewV2 | undefined>(undefined);
    const { analysis: overviewAnalysis, associateContactWithReferee } = useInterviewAnalysisContext();

    useEffect(() => {
        const handleInterviewUpdate = async (event: RealTimeUpdateInterviewEventData) => {
            if (event.interviewId !== parsedInterviewId || event.ideaId !== ideaId) return;

            const interview = await interviewsV2Service.getInterview(ideaId, parsedInterviewId);
            setDetails(interview);
        };

        realTimeUpdatesEventHub.addEventListener('interview3', 'update', handleInterviewUpdate);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview3', 'update', handleInterviewUpdate);
        };
    }, [ideaId, parsedInterviewId]);

    useEffect(() => {
        const fetchDetails = async () => {
            if (!parsedInterviewId) return;

            const interviewDetails = await interviewsV2Service.getInterview(ideaId, parsedInterviewId);
            setDetails(interviewDetails);
        };

        fetchDetails();
    }, [ideaId, parsedInterviewId]);

    if (!currentUserId || !details) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    return (
        <div className="page-content-middle page-content--xxl">
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8">
                <IntervieweeDetailsSection
                    interviewee={details.contact}
                    intervieweeName={details.intervieweeName}
                    isAnalysisPending={overviewAnalysis.status === InterviewAnalysisStatus.Pending}
                />
                <HostAndDetailsSection
                    interviewDetails={details}
                    currentUserId={currentUserId}
                    isAnalysisPending={overviewAnalysis.status === InterviewAnalysisStatus.Pending}
                    fileButtons={
                        <>
                            <TextFileButton
                                title={
                                    details.imported
                                        ? (details.detail as ImportedInterviewDetailV2).transcriptName
                                        : details.contact
                                        ? `Completed interview with ${details.contact.name}`
                                        : 'Completed interview'
                                }
                                icon={details.imported ? FileUploadedIcon : FileTranscriptIcon}
                                onClick={() => {
                                    if (details.imported) {
                                        setViewUploadedInterview(true);
                                    } else {
                                        //TODO R4: Check for human-led interviews
                                        navigate(getIVAInterviewLink(details.publicCode));
                                    }
                                }}
                            />
                            {details.scriptId && details.scriptName ? (
                                <TextFileButton title={details.scriptName} icon={ScriptIcon} onClick={() => setViewScript(true)} />
                            ) : details.imported && (details.detail as ImportedInterviewDetailV2).scriptName ? (
                                <TextFileButton
                                    title={(details.detail as ImportedInterviewDetailV2).scriptName!}
                                    icon={FileUploadedIcon}
                                    onClick={() => setViewUploadedScript(true)}
                                />
                            ) : null}
                        </>
                    }
                />

                <StackLayout orientation="horizontal" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                    <InterviewSummaryPanel icon={InsightIcon} title="Insights Summary">
                        {overviewAnalysis.insightSummaryItems.length > 0 ? (
                            <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                                {overviewAnalysis.insightSummaryItems.map(item => (
                                    <React.Fragment key={item.title}>
                                        <div>
                                            <div className="k-mb-2 k-font-semibold">{item.title}</div>
                                            <div>{item.content}</div>
                                        </div>
                                        <div className="k-separator k-icp-component-border" />
                                    </React.Fragment>
                                ))}
                                <Button themeColor="secondary" fillMode="flat" size="small" onClick={() => navigate('insights')}>
                                    View all insights
                                </Button>
                            </StackLayout>
                        ) : overviewAnalysis.status === InterviewAnalysisStatus.Pending ? (
                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-gap-4 ">
                                <div>
                                    <Skeleton shape="text" className="k-mb-2" style={{ width: '80%' }} />
                                    <div>
                                        <Skeleton shape="text" />
                                        <Skeleton shape="text" />
                                        <Skeleton shape="text" style={{ width: '60%' }} />
                                    </div>
                                </div>
                                <div className="k-separator k-icp-component-border" />
                                <div>
                                    <Skeleton shape="text" className="k-mb-2" style={{ width: '80%' }} />
                                    <div>
                                        <Skeleton shape="text" />
                                        <Skeleton shape="text" />
                                        <Skeleton shape="text" style={{ width: '50%' }} />
                                    </div>
                                </div>
                                <div className="k-separator k-icp-component-border" />
                                <div>
                                    <Skeleton shape="text" className="k-mb-2" style={{ width: '80%' }} />
                                    <div>
                                        <Skeleton shape="text" />
                                        <Skeleton shape="text" />
                                        <Skeleton shape="text" style={{ width: '80%' }} />
                                    </div>
                                </div>
                                <div className="k-separator k-icp-component-border" />
                                <Skeleton shape="rectangle" style={{ width: '108px', height: '24px' }} className="k-rounded-sm" />
                            </StackLayout>
                        ) : (
                            <div className="k-icp-subtle-text">No significant insights were identified with this interview.</div>
                        )}
                    </InterviewSummaryPanel>

                    <InterviewSummaryPanel icon={AwardIcon} title="Quality and Suggestions">
                        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                            {overviewAnalysis.qualitySummary || overviewAnalysis.status !== InterviewAnalysisStatus.Pending ? (
                                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-w-full">
                                    <ScoreRow name="Quality score" score={overviewAnalysis.qualitySummary?.score} />
                                    {overviewAnalysis.qualitySummary ? (
                                        <>
                                            <div className="max-lines-3">{overviewAnalysis.qualitySummary.text}</div>
                                            <Button themeColor="secondary" fillMode="flat" size="small" onClick={() => navigate('quality')}>
                                                View more
                                            </Button>
                                        </>
                                    ) : (
                                        <div className="k-icp-subtle-text">No quality score for this interview.</div>
                                    )}
                                </StackLayout>
                            ) : (
                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-gap-2 k-w-full">
                                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-justify-content-between">
                                        <Skeleton shape="text" style={{ width: '40%' }} />
                                        <Skeleton shape="rectangle" className="k-rounded-sm" style={{ width: 74, height: 32 }} />
                                    </StackLayout>
                                    <div className="">
                                        <Skeleton shape="text" />
                                        <Skeleton shape="text" />
                                        <Skeleton shape="text" style={{ width: '70%' }} />
                                    </div>
                                    <Skeleton shape="rectangle" style={{ width: '108px', height: '24px' }} className="k-rounded-sm" />
                                </StackLayout>
                            )}

                            <div className="k-separator k-icp-component-border" />
                            {overviewAnalysis.scriptCoverage || overviewAnalysis.status !== InterviewAnalysisStatus.Pending ? (
                                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-w-full">
                                    <ScoreRow name="Script coverage" score={overviewAnalysis.scriptCoverage?.coverage} />

                                    {overviewAnalysis.scriptCoverage?.text ? (
                                        <div>{overviewAnalysis.scriptCoverage.text}</div>
                                    ) : (
                                        <div className="k-icp-subtle-text">No interview script provided. Script coverage unavailable.</div>
                                    )}
                                </StackLayout>
                            ) : (
                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-gap-2 k-w-full">
                                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-justify-content-between">
                                        <Skeleton shape="text" style={{ width: '40%' }} />
                                        <Skeleton shape="rectangle" className="k-rounded-sm" style={{ width: 74, height: 32 }} />
                                    </StackLayout>
                                    <div>
                                        <Skeleton shape="text" />
                                        <Skeleton shape="text" />
                                        <Skeleton shape="text" style={{ width: '60%' }} />
                                    </div>
                                </StackLayout>
                            )}
                        </StackLayout>
                    </InterviewSummaryPanel>
                </StackLayout>
                {overviewAnalysis.referrals.length > 0 && (
                    <InterviewSummaryPanel icon={ReferralIcon} title="Referrals">
                        <GridLayout cols={[{ width: '1fr' }, { width: '1fr' }, { width: '1fr' }]} gap={{ rows: 16, cols: 16 }} className="k-w-full">
                            {overviewAnalysis?.referrals.map((referral, index: number) => (
                                <GridLayoutItem key={index} className="k-min-w-0">
                                    <ReferralCard
                                        ideaId={ideaId}
                                        interviewId={details.id}
                                        refereeDetails={referral}
                                        readonly={!canExecuteActions}
                                        addedReferee={referral.contact ?? undefined}
                                        onAddReferee={associateContactWithReferee}
                                    />
                                </GridLayoutItem>
                            ))}
                        </GridLayout>
                    </InterviewSummaryPanel>
                )}
                {overviewAnalysis.nextSteps.length > 0 && <NextStepsPanel nextSteps={overviewAnalysis.nextSteps} />}
            </StackLayout>

            {viewUploadedInterview && details.imported && (
                <FileViewModal
                    ideaId={ideaId}
                    interviewId={details.id}
                    name={(details.detail as ImportedInterviewDetailV2).transcriptName}
                    type="interview"
                    onClose={() => setViewUploadedInterview(false)}
                />
            )}
            {viewUploadedScript && details.imported && (details.detail as ImportedInterviewDetailV2).scriptName && (
                <FileViewModal
                    ideaId={ideaId}
                    interviewId={details.id}
                    name={(details.detail as ImportedInterviewDetailV2).scriptName!}
                    type="script"
                    onClose={() => setViewUploadedScript(false)}
                />
            )}
            {viewScript && details.scriptId && details.scriptName && (
                <InterviewScriptViewModal ideaId={ideaId} scriptId={details.scriptId} scriptName={details.scriptName} onClose={() => setViewScript(false)} />
            )}
        </div>
    );
}

export function InsightsTab() {
    const [selectedSectionId, setSelectedSectionId] = useState<number>(0);
    const { ideaId } = useIdeaParams();
    const { interviewId: interviewIdParam } = useParams();
    const [insightCategories, setInsightCategories] = useState<InterviewInsightCategory[] | undefined>(undefined);
    const { analysis } = useInterviewAnalysisContext();

    useEffect(() => {
        const fetchInterviewInsights = async () => {
            if (!interviewIdParam || analysis.status === InterviewAnalysisStatus.Pending) return;

            const interviewId = parseInt(interviewIdParam);
            if (isNaN(interviewId)) return;

            const insightCategories = await interviewsV2Service.getInterviewInsights(ideaId, interviewId);
            setInsightCategories(insightCategories);
        };

        fetchInterviewInsights();
    }, [ideaId, interviewIdParam, analysis.status]);

    if (analysis.status === InterviewAnalysisStatus.Pending) {
        return (
            <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ maxWidth: 320 }} className="k-flex-1 k-gap-2">
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                </StackLayout>
                <div className="k-separator k-icp-component-border" />
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ maxWidth: 640 }} className="-flex-2 k-gap-4">
                    <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                        <Skeleton className="k-rounded-full k-shrink-0" shape="rectangle" style={{ width: '64px', height: '64px' }} />
                        <Skeleton shape="text" className="k-flex-1 k-fs-lg" />
                    </StackLayout>
                    <div>
                        <Skeleton shape="text" />
                        <Skeleton shape="text" />
                    </div>
                    <Skeleton shape="rectangle" className="k-rounded-md" style={{ height: '176px' }} />
                </StackLayout>
            </StackLayout>
        );
    }

    if (!insightCategories) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    if (insightCategories.length === 0) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <EmptyInsightsIllustration />
                <div className="k-mt-6">No insights found</div>
            </StackLayout>
        );
    }

    const sections = insightCategories.map((category, idx) => ({ id: idx, text: category.name, summary: category.description } as Section));
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
            <SectionsView
                sectionName="Categories"
                className="k-flex-1"
                sections={sections}
                selectedSectionId={selectedSectionId}
                onSelectedSection={setSelectedSectionId}
            />
            <div className="k-separator k-icp-component-border" />
            <InsightDetails className="-flex-2" category={insightCategories[selectedSectionId]} />
        </StackLayout>
    );
}

export function QualityTab() {
    const [selectedSectionId, setSelectedSectionId] = useState<number>(0);
    const { ideaId } = useIdeaParams();
    const { interviewId: interviewIdParam } = useParams();
    const [qualityAssessmentAreas, setQualityAssessmentAreas] = useState<InterviewQualityAssessmentArea[] | undefined>(undefined);
    const { analysis } = useInterviewAnalysisContext();

    useEffect(() => {
        const fetchQualityAssessment = async () => {
            if (!interviewIdParam || analysis.status === InterviewAnalysisStatus.Pending) return;

            const interviewId = parseInt(interviewIdParam);
            if (isNaN(interviewId)) return;

            const qualityAssessmentAreas = await interviewsV2Service.getInterviewQualityAssessment(ideaId, interviewId);
            setQualityAssessmentAreas(qualityAssessmentAreas);
        };

        fetchQualityAssessment();
    }, [ideaId, interviewIdParam, analysis.status]);

    if (analysis.status === InterviewAnalysisStatus.Pending) {
        return (
            <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ maxWidth: 320 }} className="k-flex-1 k-gap-2">
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                </StackLayout>
                <div className="k-separator k-icp-component-border" />
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ maxWidth: 640 }} className="-flex-2 k-gap-4">
                    <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                        <Skeleton className="k-rounded-full k-shrink-0" shape="rectangle" style={{ width: '64px', height: '64px' }} />
                        <Skeleton shape="text" className="k-flex-1 k-fs-lg" />
                    </StackLayout>
                    <div>
                        <Skeleton shape="text" />
                        <Skeleton shape="text" />
                    </div>
                    <Skeleton shape="rectangle" className="k-rounded-md" style={{ height: '176px' }} />
                </StackLayout>

                {analysis.qualitySummary && (
                    <>
                        <div className="k-separator k-icp-component-border" />
                        <QualityNote className="k-flex-1" text={analysis.qualitySummary.text} score={analysis.qualitySummary.score} maxWidth={296} />
                    </>
                )}
            </StackLayout>
        );
    }

    if (!qualityAssessmentAreas) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    if (qualityAssessmentAreas.length === 0) {
        return analysis.qualitySummary ? (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }}>
                <QualityNote text={analysis.qualitySummary.text} score={analysis.qualitySummary.score} maxWidth={540} />
            </StackLayout>
        ) : (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <EmptyQualityIllustration />
                <div className="k-mt-6">No areas for improvement found</div>
            </StackLayout>
        );
    }

    const sections = qualityAssessmentAreas.map(
        (area, idx) => ({ id: idx, text: area.title, summary: area.text, scorePercentage: area.percentageImprovement } as Section)
    );

    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
            <SectionsView
                sectionName="Areas for improvement"
                className="k-flex-1"
                sections={sections}
                selectedSectionId={selectedSectionId}
                onSelectedSection={setSelectedSectionId}
            />
            <div className="k-separator k-icp-component-border" />
            <ImprovementAreaView className="-flex-2" area={qualityAssessmentAreas[selectedSectionId]} />
            {analysis.qualitySummary && (
                <>
                    <div className="k-separator k-icp-component-border" />
                    <QualityNote className="k-flex-1" text={analysis.qualitySummary.text} score={analysis.qualitySummary.score} maxWidth={296} />
                </>
            )}
        </StackLayout>
    );
}

function NextStepsPanel({ nextSteps }: { nextSteps: InterviewNextStep[] }) {
    const firstColumnSteps = nextSteps.slice(0, Math.ceil(nextSteps.length / 2));
    const secondColumnSteps = nextSteps.slice(Math.ceil(nextSteps.length / 2));

    return (
        <InterviewSummaryPanel icon={ListCheckIcon} title="Next steps">
            <StackLayout orientation="horizontal" align={{ horizontal: 'stretch', vertical: 'stretch' }} className="k-gap-8">
                <NextStepsColumn nextSteps={firstColumnSteps} maxWidth={secondColumnSteps.length === 0 ? '50%' : undefined} />
                {secondColumnSteps.length > 0 && (
                    <>
                        <div className="k-separator k-icp-component-border" />
                        <NextStepsColumn nextSteps={secondColumnSteps} />
                    </>
                )}
            </StackLayout>
        </InterviewSummaryPanel>
    );
}

function NextStepsColumn({ nextSteps, maxWidth }: { nextSteps: InterviewNextStep[]; maxWidth?: string }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ maxWidth: maxWidth }} className="k-gap-4">
            {nextSteps.map((step, idx) => (
                <React.Fragment key={idx}>
                    {idx > 0 && <div className="k-separator k-icp-component-border" />}
                    <div key={idx}>
                        <div className="k-mb-2 k-font-weight-semibold">{step.title}</div>
                        <div className="formatted-links-area" dangerouslySetInnerHTML={{ __html: step.text }} />
                    </div>
                </React.Fragment>
            ))}
        </StackLayout>
    );
}

function IntervieweeDetailsSection({
    interviewee,
    intervieweeName,
    isAnalysisPending
}: {
    interviewee: ReducedPerson | null;
    intervieweeName?: string | null;
    isAnalysisPending?: boolean;
}) {
    return (
        <div>
            <H3 className="!k-mb-2">Interviewee</H3>
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                {interviewee ? (
                    <>
                        <UserAvatar
                            picture={interviewee.picture || undefined}
                            colorIndex={getPreferredColorIndex(interviewee.id)}
                            initials={generateInitials(2, interviewee.firstName, interviewee.lastName)}
                        />
                        <UserDetailsText name={interviewee.name} jobTitle={interviewee.jobTitle} />
                    </>
                ) : (
                    <>
                        <UserAvatar empty={true} initials={''} className="k-icp-subtle-text" />
                        {isAnalysisPending && !intervieweeName ? (
                            <Skeleton shape="text" className="k-fs-lg" style={{ width: 320 }} />
                        ) : (
                            <strong className="k-fs-lg">{intervieweeName || 'Unknown interviewee'}</strong>
                        )}
                    </>
                )}
            </StackLayout>
        </div>
    );
}

function HostAndDetailsSection({
    interviewDetails,
    currentUserId,
    isAnalysisPending,
    fileButtons
}: {
    interviewDetails: InterviewV2;
    currentUserId: string;
    isAnalysisPending?: boolean;
    fileButtons?: ReactNode;
}) {
    const { host, aiLed, date, imported, createdBy, createdOn, updatedBy, updatedOn } = interviewDetails;

    return (
        <div className="k-icp-panel k-icp-panel-content">
            <H3 className="!k-mb-2">Host & Details</H3>
            <StackLayout orientation="horizontal" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                    <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                        {aiLed ? (
                            <>
                                <AIAvatar />
                                <span>IVA</span>
                            </>
                        ) : host ? (
                            <>
                                <UserAvatar
                                    picture={host.picture}
                                    colorIndex={getPreferredColorIndex(host.userId)}
                                    initials={generateInitials(2, host.firstName, host.lastName)}
                                />
                                <span>{getPersonFullName(host.firstName, host.lastName, host.userId === currentUserId)}</span>
                            </>
                        ) : (
                            <>
                                <UserAvatar empty={true} initials={''} className="k-icp-subtle-text" />
                                <span>Unknown Host</span>
                            </>
                        )}
                    </StackLayout>
                    <HostDetailsRow name="Created">
                        {dateTimeService.stringifyToDay(createdOn, false, true) +
                            ' by ' +
                            getPersonFullName(createdBy.firstName, createdBy.lastName, createdBy.userId === currentUserId)}
                    </HostDetailsRow>

                    {date ? (
                        <HostDetailsRow name="Interview date">{dateTimeService.stringifyToDay(date, false, true)}</HostDetailsRow>
                    ) : (
                        imported && (
                            <HostDetailsRow name="Interview date" pending={isAnalysisPending}>
                                Unknown
                            </HostDetailsRow>
                        )
                    )}
                    {updatedOn && !imported && (
                        <HostDetailsRow name="Last modified">
                            {dateTimeService.stringifyToDay(updatedOn, false, true) +
                                (updatedBy ? ' by ' + getPersonFullName(updatedBy.firstName, updatedBy.lastName, updatedBy.userId === currentUserId) : '')}
                        </HostDetailsRow>
                    )}
                </StackLayout>
                {fileButtons && (
                    <>
                        <div className="k-separator k-icp-component-border" />
                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2 k-align-self-center">
                            {fileButtons}
                        </StackLayout>
                    </>
                )}
            </StackLayout>
        </div>
    );
}

function ScoreRow({ name, score }: { name: string; score?: number }) {
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-w-full">
            <div className="k-flex-1 k-font-semibold">{name}</div>
            <ScoreView percentage={score} />
        </StackLayout>
    );
}

function HostDetailsRow({ name, children, pending }: { name: string; children: ReactNode; pending?: boolean }) {
    return (
        <span className="k-fs-sm">
            <span className="k-icp-subtle-text">{name}:</span>{' '}
            {pending ? <Skeleton className="!k-d-inline-block" shape="text" style={{ width: '55px' }} /> : children}
        </span>
    );
}

function InterviewSummaryPanel({
    title,
    icon,
    children
}: {
    title: string;
    icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string;
        }
    >;
    children: ReactNode;
}) {
    return (
        <div className="k-icp-panel k-icp-panel-content">
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3 k-mb-8">
                <RoundedIconBackground className="k-shrink-0" icon={icon} />
                <div className="k-fs-lg k-font-semibold">{title}</div>
            </StackLayout>
            {children}
        </div>
    );
}

function InsightDetails({ category, className }: { category: InterviewInsightCategory; className?: string }) {
    const groupedInsights = useMemo(() => {
        const grouped = new Map<string | undefined, InterviewInsight[]>();

        category.insights?.forEach(insight => {
            const group = insight.group ?? undefined; // Convert null to undefined
            if (!grouped.has(group)) {
                grouped.set(group, []);
            }
            grouped.get(group)!.push(insight);
        });

        return grouped;
    }, [category.insights]);

    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'stretch', vertical: 'middle' }}
            className={combineClassNames('k-gap-4', className)}
            style={{ maxWidth: 640 }}
        >
            <SummaryHeader title={category.name} summary={category.description} icon={InsightIcon} />

            {/* Display ungrouped insights first */}
            {groupedInsights.get(undefined)?.map((insight, idx) => (
                <InsightView insight={insight} key={idx} />
            ))}

            {/* Display grouped insights */}
            {Array.from(groupedInsights.entries())
                .filter(([group]) => group !== undefined)
                .map(([group, insights]) => (
                    <StackLayout key={group} orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                        <div className="k-font-weight-semibold">{group}</div>
                        {insights.map((insight, idx) => (
                            <InsightView insight={insight} key={idx} />
                        ))}
                    </StackLayout>
                ))}
        </StackLayout>
    );
}

function InsightView({ insight }: { insight: InterviewInsight }) {
    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'start', vertical: 'top' }}
            className="k-rounded-md k-p-4 k-border k-border-solid k-icp-component-border k-gap-2"
        >
            <div>{insight.insight}</div>
            {insight.quote && <QuotePanel addQuotes={!hasQuotes(insight.quote)} text={insight.quote} icon={QuoteIcon} panelClassName="k-icp-panel-base" />}
        </StackLayout>
    );
}

function ImprovementAreaView({ area, className }: { area: InterviewQualityAssessmentArea; className?: string }) {
    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'stretch', vertical: 'middle' }}
            className={combineClassNames('k-gap-4', className)}
            style={{ maxWidth: 640 }}
        >
            <SummaryHeader title={area.title} summary={area.text} icon={InsightIcon} />
            {area.recommendations.map((recommendation, idx) => (
                <RecommendationView recommendation={recommendation} key={idx} />
            ))}
        </StackLayout>
    );
}

type SegmentType = 'text' | 'said' | 'rec';
type Segment = {
    type: SegmentType;
    content: string;
};

const parseSegments = (text: string): Segment[] => {
    const segments: Segment[] = [];
    const regex = /{(said|rec)}([\s\S]*?){\/\1}/g;
    let lastIndex = 0;
    let match: RegExpExecArray | null;
    const trimText = (s: string) => s.replace(/^[,\s]+|[,\s]+$/g, '');

    try {
        while ((match = regex.exec(text)) !== null) {
            // Handle text before the tag
            const textBefore = text.slice(lastIndex, match.index);
            const trimmedTextBefore = trimText(textBefore);
            if (trimmedTextBefore !== '') {
                segments.push({ type: 'text', content: trimmedTextBefore });
            }

            segments.push({ type: match[1] as SegmentType, content: match[2] });
            lastIndex = regex.lastIndex;
        }

        // Add any remaining text as a trimmed text segment if not empty
        const remainingText = trimText(text.slice(lastIndex));
        if (remainingText !== '') {
            segments.push({ type: 'text', content: remainingText });
        }
    } catch (error) {
        return [{ type: 'text', content: text }];
    }

    return segments;
};

function RecommendationView({ recommendation }: { recommendation: InterviewQualityAssessmentRecommendation }) {
    const segments = parseSegments(recommendation.recommendation);
    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'start', vertical: 'top' }}
            className="k-rounded-md k-p-4 k-border k-border-solid k-icp-component-border k-gap-2"
        >
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-justify-content-between k-gap-2">
                <div className="k-icp-subtle-text k-text-uppercase">Recommendation</div>
                <PercentageChip percentage={recommendation.percentageImprovement} className="k-icp-bg-secondary-8" />
            </StackLayout>

            {segments.map((segment, index) => {
                if (segment.type === 'text') {
                    return <div key={index}>{segment.content}</div>;
                } else if (segment.type === 'said') {
                    return (
                        <QuotePanel
                            key={index}
                            text={segment.content}
                            icon={QuoteIcon}
                            panelClassName="k-icp-panel-base"
                            addQuotes={!hasQuotes(segment.content)}
                        />
                    );
                } else {
                    return (
                        <QuotePanel
                            key={index}
                            text={segment.content}
                            icon={SuggestionIcon}
                            panelClassName="k-icp-bg-secondary-8"
                            addQuotes={!hasQuotes(segment.content)}
                        />
                    );
                }
            })}
        </StackLayout>
    );
}
