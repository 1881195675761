import { Button } from '@progress/kendo-react-buttons';
import { TextArea, TextAreaHandle, TextAreaProps } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { forwardRef, KeyboardEvent, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useAsRef, useElementResize } from '../../hooks/commonHooks';
import { ReactComponent as MicrophoneIcon } from '../../icons/mic.svg';
import { ReactComponent as SendIcon } from '../../icons/send-alt-2.svg';
import { combineClassNames, isMobileAgent } from '../../services/common';
import { domService } from '../../services/domService';

interface ChatTextBoxProps {
    onSend: (message: string) => void;
    onResize?: (entries: ResizeObserverEntry[]) => void;
    placeholder?: string;
    className?: string;
    microphone?: boolean;
    micPaused?: boolean;
    disabled?: boolean;
    bottomLeftActionComponent?: ReactNode;
    textBoxAttributes?: TextAreaProps;
}

export interface ChatTextBoxHandle {
    focus: () => void;
}

export const ChatTextBox = forwardRef<ChatTextBoxHandle, ChatTextBoxProps>(function ChatTextBox(
    {
        microphone,
        micPaused,
        onSend,
        placeholder = 'Type a message...',
        onResize,
        className,
        disabled,
        bottomLeftActionComponent,
        textBoxAttributes
    }: ChatTextBoxProps,
    ref
) {
    const [message, setMessage] = useState('');
    const textAreaKey = useRef(0);
    const textAreaRef = useRef<TextAreaHandle>(null);
    const isMobileUserAgent = isMobileAgent;
    const { elementRef } = useElementResize<HTMLDivElement>(entries => {
        onResize?.(entries);
    });

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition, finalTranscript } = useSpeechRecognition({});
    const resetTranscriptRef = useAsRef(resetTranscript);
    const [recordingEnabled, setRecordingEnabled] = useState(false);
    const recordingEnabledRef = useAsRef(recordingEnabled);

    useEffect(() => {
        if (listening && finalTranscript) {
            setMessage(prev => prev + finalTranscript);
            resetTranscriptRef.current();
        }
    }, [finalTranscript, listening, resetTranscriptRef]);

    useEffect(() => {
        if (disabled) {
            SpeechRecognition.abortListening();
            resetTranscriptRef.current();
        }
    }, [disabled, resetTranscriptRef]);

    useEffect(() => {
        if (micPaused && listening) {
            SpeechRecognition.abortListening();
            resetTranscriptRef.current();
        } else if (!micPaused && !listening && recordingEnabledRef.current) {
            resetTranscriptRef.current();
            SpeechRecognition.startListening({ continuous: true });
        }
    }, [listening, micPaused, recordingEnabledRef, resetTranscriptRef]);

    useEffect(() => {
        if (!microphone) {
            return;
        }
        return () => {
            SpeechRecognition.abortListening();
        };
    }, [microphone]);

    useImperativeHandle(
        ref,
        () => ({
            focus: () => {
                const textAreaDomElement = textAreaRef.current?.element.current;
                if (!textAreaDomElement) return;

                domService.focusTextArea(textAreaDomElement);
            }
        }),
        []
    );

    function collectTranscriptIfListening() {
        if (listening) {
            const newMessage = message + transcript;
            resetTranscript();
            return newMessage;
        }
        return message;
    }

    const sendMessage = () => {
        const message = collectTranscriptIfListening();
        if (message.trim()) {
            onSend(message);
            setMessage('');
            if (microphone) {
                SpeechRecognition.abortListening();
                resetTranscript();
            }

            textAreaKey.current++;
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            if (e.shiftKey || isMobileUserAgent) {
                // Allow new line when Shift + Enter is pressed
                return;
            }

            e.preventDefault();
            sendMessage();
        }
    };

    const handleMicrophoneClick = () => {
        if (!listening) {
            resetTranscript();
            setRecordingEnabled(true);
            SpeechRecognition.startListening({ continuous: true });
        } else {
            setRecordingEnabled(false);
            SpeechRecognition.abortListening();
            setMessage(collectTranscriptIfListening());
        }
    };

    return (
        <div ref={elementRef} className={combineClassNames('chat-message-textbox w-full k-pos-relative', className)}>
            <TextArea
                id="chat-area"
                key={textAreaKey.current}
                ref={textAreaRef}
                onChange={e => {
                    resetTranscript();
                    setMessage(e.value);
                }}
                value={`${message}${listening ? transcript : ''}`}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                rounded={'large'}
                autoSize={true}
                disabled={disabled}
                className={combineClassNames('padded-textarea k-icp-no-focus-shadow', className)}
                {...textBoxAttributes}
            />
            {bottomLeftActionComponent && <span className="k-pos-absolute  k-z-10 k-bottom-1 k-left-2">{bottomLeftActionComponent}</span>}
            <StackLayout className="k-pos-absolute k-z-10 k-bottom-1 k-right-2 k-gap-2">
                {microphone && browserSupportsSpeechRecognition && (
                    <>
                        <Button
                            type="button"
                            fillMode="flat"
                            disabled={disabled || micPaused}
                            themeColor="secondary"
                            className={`k-icp-svg-icon-button !k-border-none !k-p-2 ${recordingEnabled ? 'k-selected' : ''}`}
                            onClick={handleMicrophoneClick}
                        >
                            <MicrophoneIcon className={`k-icp-icon k-text-secondary `} />
                        </Button>
                        <div className="k-separator k-icp-component-border" />
                    </>
                )}
                <Button
                    type="button"
                    fillMode="flat"
                    themeColor="secondary"
                    disabled={disabled || (!message.trim() && !transcript.trim())}
                    className="k-icp-svg-icon-button !k-border-none !k-p-2"
                    onClick={sendMessage}
                >
                    <SendIcon className="k-icp-icon k-icp-fill-secondary" />
                </Button>
            </StackLayout>
        </div>
    );
});
