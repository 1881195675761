import { Dialog } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { PopupPropsContext } from '@progress/kendo-react-popup';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatMessageSimple, ChatPreview } from '../../components/ai/chatView';
import LoadingIndicator from '../../components/ui/loadingIndicator';

import {
    InterviewStatusIndicator,
    IVALedChatPreview,
    ScriptQuestionCard,
    ScriptQuestionsSection,
    ScriptSection,
    StickyStamp
} from '../../components/interview2/common';
import { ChatMessageType } from '../../services/chatsService';
import { InterviewStatus, MockHumanLedChatMessages, MockNotes, MockScriptCompletedSections } from './mockData';

type TextDoc = {
    id: number;
    text: string;
};

type InterviewPreviewPageProps = {
    interviewTitle: string;
    interviewStatus: InterviewStatus;
    notes: TextDoc;
};

const MockInterviewPreviewPageProps: InterviewPreviewPageProps = {
    interviewTitle: 'Interview with John Doe',
    interviewStatus: InterviewStatus.Completed,
    notes: MockNotes
};

const MockExampleIVALedMessages: ChatMessageSimple[] = [
    {
        id: 2,
        type: ChatMessageType.Agent,
        createdOn: new Date('2024-03-20T10:00:30'),
        timeSeconds: 30,
        content:
            "Thank you very much for taking the time to speak with me today. I'm really excited to chat with you about your experience with teaching your kids real-life programming.We are conducting initial research around how and when people need or want to teach their kids programming. Specifically, I'm interested in learning about the methods and resources you use, including alternative solutions you may have tried. We want to improve our understanding of the customer experience and potentially find ways to make it less of a pain. We are just starting and are not here to pitch anything or sell anything. Our objective is to learn from you and uncover your story as if we are shooting a documentary. Does that make sense?"
    },
    {
        id: 3,
        type: ChatMessageType.User,
        createdOn: new Date('2024-03-20T10:01:44'),
        timeSeconds: 104,
        content:
            "Yeah! Thank you for reaching out and for your kind words. It's always a pleasure to share insights, especially when it comes to teaching programming to kids. I'm excited about this conversation and would be happy to dive into the approaches and experiences that have worked for me. Looking forward to exchanging ideas!",
        user: { userId: '1', emailAddress: 'john.doe@example.com', firstName: 'John', lastName: 'Doe' }
    },
    {
        id: 4,
        type: ChatMessageType.Agent,
        createdOn: new Date('2024-03-20T10:01:55'),
        timeSeconds: 115,
        content: 'When was the last time you wanted or needed to teach your kids real-life programming?'
    },
    {
        id: 5,
        type: ChatMessageType.User,
        createdOn: new Date('2024-03-20T10:02:32'),
        timeSeconds: 152,
        content:
            'Actually, just the other day! My kids were curious about how their favorite video games work, so we sat down and created a simple game using Scratch. It was a fun way to teach them logic and problem-solving while keeping it interactive and exciting!',
        user: { userId: '1', emailAddress: 'john.doe@example.com', firstName: 'John', lastName: 'Doe' }
    }
];

type InterviewData = {
    interviewStatus: InterviewStatus;
    interviewTitle: string;
    isIVALed: boolean;
    messages: ChatMessageSimple[];
};

const MOCK_IS_IVA_LED_INTERVIEW = false;

const MockInterviewData: InterviewData = {
    interviewStatus: InterviewStatus.Completed,
    interviewTitle: 'Interview with John Doe',
    isIVALed: MOCK_IS_IVA_LED_INTERVIEW,
    messages: MOCK_IS_IVA_LED_INTERVIEW ? MockExampleIVALedMessages : MockHumanLedChatMessages
};

export function InterviewPreviewPage() {
    const dialogRef = useRef<Dialog>(null);
    const navigate = useNavigate();
    const [interviewData, setInterviewData] = useState<InterviewData | undefined>(undefined);

    useEffect(() => {
        const timer = setTimeout(() => {
            setInterviewData(MockInterviewData);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const isLoading = interviewData === undefined;

    return (
        <PopupPropsContext.Provider value={p => (dialogRef.current?.element ? { ...p, appendTo: dialogRef.current?.element } : p)}>
            <Dialog
                ref={dialogRef}
                className="k-icp-dialog-maximized k-icp-dialog-no-padding k-icp-dialog-with-title-shadow"
                title={
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-flex-1">
                        <div className="k-text-ellipsis">{MockInterviewPreviewPageProps.interviewTitle}</div>
                        <InterviewStatusIndicator text={'Completed interview'} type="success" />
                    </StackLayout>
                }
                onClose={() => navigate('./../../')}
            >
                {isLoading ? (
                    <div className="k-display-flex k-justify-content-center k-align-items-center k-w-full k-h-full">
                        <LoadingIndicator size="big" />
                    </div>
                ) : interviewData.isIVALed ? (
                    <IVALedChatPreview messages={interviewData.messages} />
                ) : (
                    <HumanLedChatPreview
                        scriptSections={MockScriptCompletedSections}
                        messages={interviewData.messages}
                        notesTxt={MockInterviewPreviewPageProps.notes.text}
                    />
                )}
            </Dialog>
        </PopupPropsContext.Provider>
    );
}

function HumanLedChatPreview({ scriptSections, messages, notesTxt }: { scriptSections: ScriptSection[]; messages: ChatMessageSimple[]; notesTxt: string }) {
    const transcriptPanel = (
        <div className="k-px-4 k-pb-4">
            <StickyStamp text="Interview transcript" />
            <ChatPreview messages={messages} />
        </div>
    );

    const scriptPanel = (
        <div className="k-px-4 k-pb-4">
            <StickyStamp text="Script questions" />
            <StackLayout orientation="vertical" className="k-gap-4">
                {scriptSections.map((section, index) => (
                    <ScriptQuestionsSection key={index} section={section}>
                        {section.questions.map((question, index) => (
                            <ScriptQuestionCard key={index} status={question.status} text={question.text} />
                        ))}
                    </ScriptQuestionsSection>
                ))}
            </StackLayout>
        </div>
    );

    const notesPanel = (
        <div className="k-px-4 k-pb-4">
            <StickyStamp text="Notes" />
            <div className="k-white-space-pre-wrap">{notesTxt}</div>
        </div>
    );

    return <HumanLedChat3PanelsLayout panel1={transcriptPanel} panel2={scriptPanel} panel3={notesPanel} />;
}

function HumanLedChat3PanelsLayout({ panel1, panel2, panel3 }: { panel1: React.ReactNode; panel2: React.ReactNode; panel3: React.ReactNode }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} className="k-h-full">
            <div className="k-flex-1 k-overflow-auto">{panel1}</div>
            <div className="k-overflow-auto k-flex-1 k-icp-component-border k-icp-panel-base k-border-r k-border-r-solid k-border-l k-border-l-solid">
                {panel2}
            </div>
            <div className="k-flex-1 k-overflow-auto">{panel3}</div>
        </StackLayout>
    );
}
