import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PersonPickerInput } from '../../components/contacts/contactPicker';
import {
    allowedTextFileExtensions,
    hostValidator,
    intervieweeValidator,
    InterviewsFullScreenModal,
    InterviewTypePicker,
    maxInterviewFileSizeInBytes,
    UNKNOWN_HOST_USER,
    UnknownUser
} from '../../components/interview2/common';
import { HostsPicker } from '../../components/interview2/hostPicker';
import { FileSelector, minLengthValidator, requiredValidator, useFileSelectorValidator, ValidatedInput } from '../../components/ui/inputs';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { useInProgressOperationsTracker } from '../../hooks/commonHooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { ReducedPerson } from '../../services/contactsService';
import { Interview2Type, interviewsV2Service } from '../../services/interviewsV2Service';
import { ReducedUser } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';

const interviewTypeValidator = requiredValidator('Type');
const emptyInterviewFilesErrorMessage = 'Interview transcript is required';
export const UploadInterviewModal = () => {
    const navigate = useNavigate();
    const { ideaId } = useIdeaParams();
    const currentUser = useAppSelector(s => s.user);
    const [isSubmitInProgress, trackFormSubmit] = useInProgressOperationsTracker();

    const isLoading = !ideaId || !currentUser;

    const [interviewFileValidator, interviewFileErrors] = useFileSelectorValidator(allowedTextFileExtensions, maxInterviewFileSizeInBytes);
    const [interviewScriptValidator, interviewScriptFilesErrors] = useFileSelectorValidator(allowedTextFileExtensions, maxInterviewFileSizeInBytes);

    const interviewTranscriptValidators = useMemo(
        () => [
            interviewFileValidator,
            requiredValidator(emptyInterviewFilesErrorMessage, false),
            minLengthValidator(emptyInterviewFilesErrorMessage, 1, false)
        ],
        [interviewFileValidator]
    );

    async function onCreateInterviewSubmit(data: Record<string, any>) {
        const transcriptFile = (data.transcriptFile as File[])[0];
        const interviewType = data.interviewType as Interview2Type;
        const interviewee = data.interviewee as ReducedPerson;
        const script = (data.scriptFile as File[] | undefined)?.[0];
        const host = data.host as UnknownUser | ReducedUser;
        const hostId = host.userId !== UNKNOWN_HOST_USER.userId ? host.userId : undefined;
        const contactId = typeof interviewee === 'object' && 'id' in interviewee ? interviewee.id : undefined;

        const createdInterview = await interviewsV2Service.uploadInterview(
            ideaId,
            { hostId: hostId, type: interviewType, contactId: contactId },
            transcriptFile,
            script
        );
        navigate(`../${createdInterview.id}`);
    }

    return (
        <InterviewsFullScreenModal title="Upload an interview" onClose={() => navigate('./..')}>
            {!isLoading ? (
                <Form
                    initialValues={{
                        host: UNKNOWN_HOST_USER
                    }}
                    onSubmit={trackFormSubmit(onCreateInterviewSubmit)}
                    render={({ allowSubmit, valueGetter, onChange: onFormChange }) => {
                        return (
                            <FormElement className="k-p-4 ">
                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-10 page-content-middle">
                                    <Field
                                        name="interviewee"
                                        component={ValidatedInput}
                                        label="Interviewee"
                                        labelClassName="!k-mb-4 k-font-bold"
                                        inputType={PersonPickerInput}
                                        simplePickerEmptyText="No recent contacts found"
                                        ideaId={ideaId}
                                        simplePicker
                                        placeholder="Select or create contact..."
                                        validator={intervieweeValidator}
                                    />
                                    <Field
                                        name="interviewType"
                                        component={ValidatedInput}
                                        inputType={InterviewTypePicker}
                                        label="Interview type"
                                        labelClassName="!k-mb-4 k-h3"
                                        validator={interviewTypeValidator}
                                    />
                                    <Field
                                        name="transcriptFile"
                                        component={ValidatedInput}
                                        inputType={FileSelector}
                                        label="Transcript"
                                        labelClassName="!k-mb-1 k-font-bold"
                                        acceptFileExtensions={allowedTextFileExtensions.join(', ')}
                                        description="IVA provides better results when working with full transcripts, but will analyze all files you input and help you get the most out of every bit of data you have."
                                        validator={interviewTranscriptValidators}
                                        fileErrors={interviewFileErrors}
                                        hideErrorMessage={Boolean(interviewFileErrors)}
                                    />

                                    <Field
                                        name="scriptFile"
                                        component={ValidatedInput}
                                        inputType={FileSelector}
                                        label="Script (optional)"
                                        labelClassName="!k-mb-1 k-font-bold"
                                        acceptFileExtensions={allowedTextFileExtensions.join(', ')}
                                        description="Upload file with questions you have prepared for the interview. IVA will provide you feedback how well you have covered them during the interview."
                                        validator={interviewScriptValidator}
                                        fileErrors={interviewScriptFilesErrors}
                                        hideErrorMessage={Boolean(interviewScriptFilesErrors)}
                                    />

                                    <Field
                                        name="host"
                                        label="Interview host"
                                        labelClassName="!k-mb-1 k-font-bold"
                                        component={ValidatedInput}
                                        inputType={HostsPicker}
                                        emptyText="No hosts found"
                                        ideaId={ideaId}
                                        currentUserId={currentUser.userId}
                                        supportUnknown
                                        validator={hostValidator}
                                    />

                                    <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-4">
                                        <Button size="large" fillMode="solid" themeColor="primary" type="submit" disabled={!allowSubmit || isSubmitInProgress}>
                                            Save interview
                                        </Button>
                                        <Button size="large" fillMode="solid" themeColor="base" disabled={isSubmitInProgress} onClick={() => navigate('./..')}>
                                            Cancel
                                        </Button>
                                    </StackLayout>
                                </StackLayout>
                            </FormElement>
                        );
                    }}
                ></Form>
            ) : (
                <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-h-full k-w-full">
                    <LoadingIndicator size="big" />
                </StackLayout>
            )}
        </InterviewsFullScreenModal>
    );
};
