import { Button } from '@progress/kendo-react-buttons';
import { Avatar, Card, CardBody, CardHeader, CardTitle, StackLayout } from '@progress/kendo-react-layout';
import { Tooltip, TooltipPositionEvent } from '@progress/kendo-react-tooltip';
import { useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as OpenIcon } from '../../icons/eye.svg';
import { ReactComponent as LeaveIcon } from '../../icons/log-out.svg';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';
import { ReactComponent as InviteIcon } from '../../icons/user-add.svg';
import { IdeaViewModel } from '../../pages/startups/startupsPage';
import { getPersonFullName } from '../../services/common';
import { ideasService, Invite } from '../../services/ideasService';
import { UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { P } from '../ui/typography';
import { StartupInviteMemberModal } from './startupInviteMember';
import { StartupMembershipList } from './startupMembershipList';

const FLIPPED_TOOLTIP_CLASS_NAME = 'horizontally-flipped-tooltip';

export default function StartupCard({
    idea,
    onView,
    onSettings,
    onDelete,
    onLeave,
    onInvited
}: {
    idea: IdeaViewModel;
    onView: Function;
    onSettings?: Function;
    onDelete: Function;
    onLeave: Function;
    onInvited: (invite: Invite) => void;
}) {
    const [showInviteMembersModal, setShowInviteMembersModal] = useState(false);
    const currentUser = useAppSelector(s => s.user);

    const onTooltipPosition = (e: TooltipPositionEvent): { top: number; left: number } => {
        const calculatedPosition: { top: number; left: number } = (e.target as any).position(e.element);
        const viewPortWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        // If the tooltip goes out the viewport - flip it horizontally relative to the anchor element
        if (calculatedPosition.left + e.element.offsetWidth > viewPortWidth && e.targetElement) {
            e.element.classList.add(FLIPPED_TOOLTIP_CLASS_NAME);
            const anchorElementRect = e.targetElement.getBoundingClientRect();
            e.element.style.transformOrigin = anchorElementRect.left + anchorElementRect.width / 2 - calculatedPosition.left + 'px';
        } else {
            e.element.style.transformOrigin = '';
            e.element.classList.remove(FLIPPED_TOOLTIP_CLASS_NAME);
        }

        return calculatedPosition;
    };

    return (
        <Card orientation="horizontal" className="k-icp-interactive-card startup-card">
            <CardHeader className="k-card-media !k-pr-0">
                {idea.logo ? (
                    <Avatar rounded="full" type="image" themeColor="base" size="large">
                        <img src={idea.logo} alt={`${idea.title} logo`} />
                    </Avatar>
                ) : (
                    <Avatar rounded="full" type="text" themeColor="base" size="large" className="k-icp-avatar-simple-bg-0 k-icp-avatar-gradient-bg">
                        LOGO
                    </Avatar>
                )}
            </CardHeader>
            <CardBody>
                <div className="k-fs-xs k-text-secondary k-mb-0.5 k-text-uppercase">
                    {getPersonFullName(idea.account.owner.firstName, idea.account.owner.lastName, currentUser?.userId === idea.account.owner.userId)}
                </div>
                <CardTitle className="max-lines-2">{idea.title}</CardTitle>
                <P className="max-lines-7 !k-mb-4">{idea.description || 'Your startup idea needs a description'}</P>
                <StackLayout align={{ vertical: 'bottom', horizontal: 'start' }} className="k-justify-content-between k-gap-4 k-flex-nowrap">
                    {idea.memberships && (
                        <StartupMembershipList users={idea.memberships.map(m => m.user)} invites={idea.invites} className="startup-card-actions-area" />
                    )}

                    {idea.editedAgo && <span className="k-fs-sm k-icp-subtle-text">Edited {idea.editedAgo}</span>}
                </StackLayout>
                {idea.currentUserRole && (
                    <div className="startup-card-actions-area startup-card-options k-rounded">
                        <Tooltip position="right" onPosition={onTooltipPosition} anchorElement="target" openDelay={0}>
                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }}>
                                <Button onClick={() => onView()} fillMode="flat" title="Open" className="k-icp-svg-icon-button k-icp-tooltip-trigger">
                                    <OpenIcon className="k-icp-icon" />
                                </Button>
                                {idea.currentUserRole !== UserRole.Viewer && (
                                    <>
                                        {onSettings && (
                                            <Button
                                                onClick={() => onSettings()}
                                                fillMode="flat"
                                                title="Settings"
                                                className="k-icp-svg-icon-button k-icp-tooltip-trigger"
                                            >
                                                <SettingsIcon className="k-icp-icon" />
                                            </Button>
                                        )}

                                        <Button
                                            onClick={() => setShowInviteMembersModal(s => !s)}
                                            fillMode="flat"
                                            title="Invite"
                                            className="k-icp-svg-icon-button k-icp-tooltip-trigger"
                                        >
                                            <InviteIcon className="k-icp-icon" />
                                        </Button>
                                        {showInviteMembersModal && (
                                            <StartupInviteMemberModal
                                                ideaId={idea.uniqueId}
                                                onClose={() => setShowInviteMembersModal(false)}
                                                onInvited={onInvited}
                                                ideaTitle={idea.title}
                                                showManageLink={true}
                                                manageLinkDisabled={ideasService.isIntroChatPending(idea)}
                                            />
                                        )}
                                    </>
                                )}
                                {idea.currentUserRole === UserRole.Administrator ? (
                                    <Button onClick={() => onDelete()} fillMode="flat" title="Delete" className="k-icp-svg-icon-button k-icp-tooltip-trigger">
                                        <DeleteIcon className="k-icp-icon" />
                                    </Button>
                                ) : (
                                    <Button onClick={() => onLeave()} fillMode="flat" title="Leave" className="k-icp-svg-icon-button k-icp-tooltip-trigger">
                                        <LeaveIcon className="k-icp-icon" />
                                    </Button>
                                )}
                            </StackLayout>
                        </Tooltip>
                    </div>
                )}
            </CardBody>
        </Card>
    );
}
