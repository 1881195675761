import { StackLayout } from '@progress/kendo-react-layout';
import { Fragment, ReactElement, ReactNode, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { WithValue, combineClassNames, generateInitials, getPreferredColorIndex } from '../../../services/common';
import { ReducedPerson } from '../../../services/contactsService';
import { ScrollCancellationSource, domService } from '../../../services/domService';
import { InterviewEntry } from '../../../services/interviewsService';
import { ReducedUser } from '../../../services/usersService';
import UserAvatar from '../../user/userAvatar';

type EntryRef = { questionRef?: InterviewMessageBoxHandle; answerRef?: InterviewMessageBoxHandle };
export type InterviewEntryWithDate = WithValue<InterviewEntry, 'date'>;
export function InterviewMessageLog({
    entries,
    interviewee,
    scrollToEntryId
}: {
    entries: InterviewEntryWithDate[];
    interviewee: ReducedPerson;
    scrollToEntryId?: number;
}) {
    const entriesRefs = useRef<Partial<Record<number, EntryRef>>>({});
    const isScrollToEntryPresent = scrollToEntryId === undefined ? undefined : entries.some(e => e.id === scrollToEntryId);

    useEffect(() => {
        if (scrollToEntryId === undefined || !isScrollToEntryPresent) return;

        const entryToScrollToRef = entriesRefs.current[scrollToEntryId];
        if (!entryToScrollToRef) return;

        const elementToScrollTo = entryToScrollToRef.answerRef?.element ?? entryToScrollToRef.questionRef?.element;
        if (!elementToScrollTo) return;

        const scrollCancelation: ScrollCancellationSource = {};
        domService.scrollVerticallyIntoViewIfNeeded(elementToScrollTo, undefined, scrollCancelation);

        return () => {
            scrollCancelation.cancel = true;
        };
    }, [isScrollToEntryPresent, scrollToEntryId]);

    if (!entries.length) return null;

    function updateEntryRef(entryId: number, refKey: keyof EntryRef, ref: InterviewMessageBoxHandle | null) {
        let existingRef = entriesRefs.current[entryId];
        if (!ref) {
            if (!existingRef) return;
            if (existingRef[refKey]) delete existingRef[refKey];
            if (!existingRef.questionRef && !existingRef.answerRef) delete entriesRefs.current[entryId];

            return;
        }

        if (!existingRef) existingRef = entriesRefs.current[entryId] = {};
        existingRef[refKey] = ref;
    }

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
            {entries.map(e => (
                <Fragment key={e.id}>
                    <InterviewMessageBox ref={r => updateEntryRef(e.id, 'questionRef', r)}>{e.question.content}</InterviewMessageBox>
                    <InterviewMessageBox ref={r => updateEntryRef(e.id, 'answerRef', r)} author={interviewee} right secondary>
                        {e.answer.content}
                    </InterviewMessageBox>
                </Fragment>
            ))}
        </StackLayout>
    );
}

export type InterviewMessageBoxHandle = {
    element: HTMLElement | null;
};
export type InterviewMessageBoxProps = {
    children?: ReactNode;
    right?: boolean;
    simpleBubble?: boolean;
    secondary?: boolean;
    rightAdditionalComponent?: ReactNode;
    avatarDistance?: number;
    onMessageClick?: () => void;
    selected?: boolean;
    messageBoxClassName?: string;
    hideShadow?: boolean;
} & ({ author?: ReducedPerson | ReducedUser } | { avatar?: ReactElement });
export const InterviewMessageBox = forwardRef<InterviewMessageBoxHandle, InterviewMessageBoxProps>(function InterviewMessageBox(props, ref) {
    const wrapperElementRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
        ref,
        () => ({
            get element() {
                return wrapperElementRef.current;
            }
        }),
        []
    );

    const {
        children,
        right,
        simpleBubble,
        secondary,
        avatarDistance,
        onMessageClick,
        selected,
        messageBoxClassName,
        hideShadow,
        rightAdditionalComponent
    } = props;
    if (!children) return null;

    const avatarElement = getAvatarElement(props);
    const hasAvatar = avatarElement !== undefined;
    const wrapperClassName = right ? 'k-align-self-end' : undefined;
    const messageBoxElement = (
        <div
            ref={hasAvatar ? undefined : wrapperElementRef}
            className={combineClassNames(
                'message-box k-content k-rounded-lg k-white-space-pre-line',
                hideShadow ? undefined : 'k-icp-shadow-xs',
                secondary ? 'k-icp-bg-secondary-12' : undefined,
                simpleBubble ? undefined : right ? ' k-rounded-tr-sm' : 'k-rounded-tl-sm',
                hasAvatar ? undefined : wrapperClassName,
                onMessageClick ? 'k-cursor-pointer' : undefined,
                selected ? '!k-border-secondary' : undefined,
                messageBoxClassName
            )}
            onClick={onMessageClick}
        >
            {children}
        </div>
    );

    if (hasAvatar)
        return (
            <div
                ref={wrapperElementRef}
                className={combineClassNames(
                    'k-stack-layout k-hstack k-justify-content-start k-align-items-start k-gap-1',
                    `k-gap-${avatarDistance ?? 1}`,
                    wrapperClassName
                )}
            >
                {right ? messageBoxElement : avatarElement}
                {right ? avatarElement : messageBoxElement}
                {rightAdditionalComponent && !right && rightAdditionalComponent}
            </div>
        );

    return messageBoxElement;
});

function getAvatarElement(props: InterviewMessageBoxProps): ReactElement | undefined {
    if ('avatar' in props && props.avatar) return props.avatar;
    if ('author' in props && props.author)
        return (
            <UserAvatar
                initials={generateInitials(2, props.author.firstName, props.author.lastName)}
                picture={props.author.picture}
                colorIndex={getPreferredColorIndex('userId' in props.author ? props.author.userId : props.author.id)}
            />
        );
    return undefined;
}
