import { useNavigate } from 'react-router-dom';
import { InterviewScriptModal } from '../../components/interviewScript2/InterviewScriptModal';
import { useIdeaParams, useIntParam } from '../../hooks/routerHooks';

export const InterviewScriptPage = () => {
    const { ideaId } = useIdeaParams();
    const scriptId = useIntParam('scriptId');
    const navigate = useNavigate();
    return <InterviewScriptModal ideaId={ideaId} scriptId={scriptId} onClose={() => navigate('./..')} />;
};
