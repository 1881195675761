import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { appConfig } from '../../../config';
import { findCanvasItem, useGlobalCanvas } from '../../../hooks/canvasHooks';
import { useInsightsCatalogContextFromInsights, useInterviewInsightsCatalog, useMergedInsightsContext } from '../../../hooks/insightsHooks';
import { useInterviewHypothesesVerdicts, useInterviewInsights, useInterviewInsightsCoverage, useInterviewSections } from '../../../hooks/interviewHooks';
import { useInsightsCatalogContextFromResearch, useProblemValidationResearch, useResearchHypotheses } from '../../../hooks/researchHooks';
import { ReactComponent as WarningIcon } from '../../../icons/alert-triangle.svg';
import { ReactComponent as SuccessStatIcon } from '../../../icons/check-circle-closed.svg';
import { ReactComponent as WarningStatIcon } from '../../../icons/irrelevant-circle.svg';
import { ReactComponent as NeutralStatIcon } from '../../../icons/minus-circle.svg';
import { ReactComponent as ErrorStatIcon } from '../../../icons/x-circle.svg';
import { usePerson } from '../../../pages/contacts/viewPersonPage';
import { BoxItem, BoxType } from '../../../services/canvasService';
import { combineClassNames, getPersonFullName, toRecord } from '../../../services/common';
import { FullPerson } from '../../../services/contactsService';
import { dateTimeService } from '../../../services/dateTimeService';
import { InsightCoverage } from '../../../services/insightsService';
import { HypothesisVerdict, Interview, InterviewEntryStatus, InterviewStage, InterviewType } from '../../../services/interviewsService';
import { ReducedResearch } from '../../../services/researchService';
import { useAppSelector } from '../../../state/hooks';
import { CanvasItemSimpleView } from '../../canvas/canvasItemSimpleView';
import { CountryAndCityView } from '../../common/countries';
import { JobTitleAndCompanyView, PersonSimpleView } from '../../contacts/view';
import { H3 } from '../../ui/typography';
import {
    getInsightsCoverage,
    getInsightsForQuestion,
    groupInsightsByOrigin,
    groupInsightsCoverageByOrigin,
    isDisabledForkedItem,
    useExpandedInsightsCatalog
} from '../insights/insightsCommon';
import { OpenInterviewButton } from '../interviewMainActionButton';

export function InterviewSummaryContent({ ideaId, interviewId, interview }: { ideaId: string; interviewId: number; interview?: Interview }) {
    const interviewee = usePerson(ideaId, interview?.contact.id, `../../${appConfig.startup.defaultScreenUrl}`);
    const research = useProblemValidationResearch(ideaId, interview?.researchId);
    const { canvas } = useGlobalCanvas(ideaId, true);

    const jobToBeDone =
        !canvas.boxes || !research
            ? undefined
            : research.jobToBeDoneId === undefined
            ? null
            : findCanvasItem(canvas.boxes, BoxType.JobsToBeDone, research.jobToBeDoneId) ?? null;

    const customerSegment = !canvas.boxes || !research ? undefined : findCanvasItem(canvas.boxes, BoxType.CustomerSegments, research.customerSegmentId) ?? null;

    const interviewQuestionsStats = useInterviewQuestionsStats(ideaId, interview?.id);
    const interviewInsightsStats = useInterviewInsightsStats(ideaId, interviewId, interview?.researchId);
    const interviewHypothesesStats = useInterviewHypothesesStats(ideaId, interviewId, interview?.researchId);

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="page-content-section k-gap-10">
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1 k-gap-10">
                <InterviewStatsView
                    title="Interview"
                    warning={interviewQuestionsStats?.noStatus ? `${interviewQuestionsStats?.noStatus} pending processing questions` : undefined}
                    stats={[
                        {
                            key: 'covered',
                            quantity: interviewQuestionsStats?.Covered,
                            title: (
                                <>
                                    Covered
                                    <br />
                                    questions
                                </>
                            ),
                            icon: SuccessStatIcon,
                            iconWrapperClass: 'k-icp-bg-success-8'
                        },
                        {
                            key: 'irrelevant',
                            quantity: interviewQuestionsStats?.Irrelevant,
                            title: (
                                <>
                                    Not relevant
                                    <br />
                                    questions
                                </>
                            ),
                            icon: WarningStatIcon,
                            iconWrapperClass: 'k-icp-bg-warning-8'
                        },
                        {
                            key: 'not-asked',
                            quantity: interviewQuestionsStats?.NotCovered,
                            title: (
                                <>
                                    Not asked
                                    <br />
                                    questions
                                </>
                            ),
                            icon: NeutralStatIcon,
                            iconWrapperClass: 'k-icp-bg-dark-8'
                        }
                    ]}
                >
                    <OpenInterviewButton
                        ideaId={ideaId}
                        interviewId={interviewId}
                        fillMode="outline"
                        themeColor="secondary"
                        view={InterviewStage.PendingReview}
                        replaceHistory
                    >
                        View interview
                    </OpenInterviewButton>
                </InterviewStatsView>

                <InterviewStatsView
                    title="Insights"
                    warning={
                        interviewInsightsStats?.notProcessedQuestionsCount
                            ? `${interviewInsightsStats.notProcessedQuestionsCount} pending processing questions`
                            : undefined
                    }
                    stats={[
                        {
                            key: 'new',
                            quantity: interviewInsightsStats?.totalInsights,
                            title: (
                                <>
                                    Added
                                    <br />
                                    insights
                                </>
                            ),
                            icon: SuccessStatIcon,
                            iconWrapperClass: 'k-icp-bg-success-8'
                        },
                        {
                            key: 'n/a',
                            quantity: interviewInsightsStats?.ignoredQuestionsCount,
                            title: (
                                <>
                                    Not applicable
                                    <br />
                                    questions
                                </>
                            ),
                            icon: NeutralStatIcon,
                            iconWrapperClass: 'k-icp-bg-dark-8'
                        }
                    ]}
                >
                    <OpenInterviewButton
                        ideaId={ideaId}
                        interviewId={interviewId}
                        fillMode="outline"
                        themeColor="secondary"
                        view={InterviewStage.PendingInsightCapture}
                        replaceHistory
                    >
                        View interview insights
                    </OpenInterviewButton>
                </InterviewStatsView>

                <InterviewStatsView
                    title="Hypotheses"
                    warning={interviewHypothesesStats?.noVerdict ? `${interviewHypothesesStats.noVerdict} pending evaluation hypotheses` : undefined}
                    stats={[
                        {
                            key: 'true',
                            quantity: interviewHypothesesStats?.True,
                            title: (
                                <>
                                    True
                                    <br />
                                    hypotheses
                                </>
                            ),
                            icon: SuccessStatIcon,
                            iconWrapperClass: 'k-icp-bg-success-8'
                        },
                        {
                            key: 'false',
                            quantity: interviewHypothesesStats?.False,
                            title: (
                                <>
                                    False
                                    <br />
                                    hypotheses
                                </>
                            ),
                            icon: ErrorStatIcon,
                            iconWrapperClass: 'k-icp-bg-error-8'
                        },
                        {
                            key: 'n/a',
                            quantity: interviewHypothesesStats?.Unknown,
                            title: (
                                <>
                                    Unknown
                                    <br />
                                    hypotheses
                                </>
                            ),
                            icon: NeutralStatIcon,
                            iconWrapperClass: 'k-icp-bg-dark-8'
                        }
                    ]}
                >
                    <OpenInterviewButton
                        ideaId={ideaId}
                        interviewId={interviewId}
                        fillMode="outline"
                        themeColor="secondary"
                        view={InterviewStage.PendingHypothesesEvaluation}
                        replaceHistory
                    >
                        View interview hypotheses
                    </OpenInterviewButton>
                </InterviewStatsView>
            </StackLayout>
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4 -w2">
                <InterviewDetailsSummarySidebarPanel interview={interview} research={research} jobToBeDone={jobToBeDone} />
                <IntervieweeDetailsSummarySidebarPanel interviewee={interviewee} />
                <CustomerSegmentSidebarPanel customerSegment={customerSegment} />
            </StackLayout>
        </StackLayout>
    );
}

type InterviewQuestionsStats = Record<InterviewEntryStatus, number> & {
    noStatus: number;
};
function useInterviewQuestionsStats(ideaId: string, interviewId?: number): InterviewQuestionsStats | undefined {
    const { interviewSections } = useInterviewSections(ideaId, interviewId);

    if (!interviewSections) return undefined;

    const stats: InterviewQuestionsStats = {
        [InterviewEntryStatus.Covered]: 0,
        [InterviewEntryStatus.Irrelevant]: 0,
        [InterviewEntryStatus.NotCovered]: 0,
        noStatus: 0
    };

    for (const interviewSection of interviewSections) {
        for (const interviewEntry of interviewSection.entries) {
            if (interviewEntry.hidden) continue;
            if (!interviewEntry.status) stats.noStatus++;
            else stats[interviewEntry.status]++;
        }
    }

    return stats;
}

type InterviewInsightsStats = {
    totalInsights: number;
    ignoredQuestionsCount: number;
    notProcessedQuestionsCount: number;
};
function useInterviewInsightsStats(ideaId: string, interviewId: number, researchId?: number): InterviewInsightsStats | undefined {
    const catalog = useInterviewInsightsCatalog(InterviewType.ProblemDiscoveryInterview);
    const { insights } = useInterviewInsights(ideaId, interviewId);
    const [researchContextProperties] = useInsightsCatalogContextFromResearch(ideaId, catalog?.context, researchId);
    const insightsContextProperties = useInsightsCatalogContextFromInsights(catalog?.context, insights);
    const insightsCatalogContext = useMergedInsightsContext([insightsContextProperties, researchContextProperties]);
    const expandedInsightsCatalog = useExpandedInsightsCatalog(catalog, insightsCatalogContext);
    const [insightsCoverage] = useInterviewInsightsCoverage(ideaId, interviewId);

    if (!expandedInsightsCatalog) return undefined;

    const insightsStats: InterviewInsightsStats = {
        totalInsights: 0,
        ignoredQuestionsCount: 0,
        notProcessedQuestionsCount: 0
    };

    const insightsByOrigin = insights && groupInsightsByOrigin(insights);
    const insightsCoverageByOrigin = insightsCoverage && groupInsightsCoverageByOrigin(insightsCoverage);

    for (const section of expandedInsightsCatalog.sections) {
        const isForkedSection = 'forkingData' in section;
        const isSectionDisabled = isForkedSection && isDisabledForkedItem(section, insightsCatalogContext);
        if (isSectionDisabled) continue;

        const forkedSectionPropertyData = isForkedSection ? catalog?.context.properties.find(p => p.name === section.forkingData.propertyName) : undefined;
        for (const group of section.groups) {
            const isForkedGroup = 'forkingData' in group;
            const isGroupDisabled = isForkedGroup && isDisabledForkedItem(group, insightsCatalogContext);
            if (isGroupDisabled) continue;

            const forkedGroupPropertyData = isForkedGroup ? catalog?.context.properties.find(p => p.name === group.forkingData.propertyName) : undefined;
            const forkedInsightFilter =
                isForkedGroup && forkedGroupPropertyData
                    ? {
                          propertyType: forkedGroupPropertyData.type,
                          value: group.forkingData.value
                      }
                    : isForkedSection && forkedSectionPropertyData
                    ? {
                          propertyType: forkedSectionPropertyData.type,
                          value: section.forkingData.value
                      }
                    : undefined;
            const forkValue = isForkedGroup ? group.forkingData.value : isForkedSection ? section.forkingData.value : undefined;
            for (const question of group.questions) {
                const questionInsights =
                    insightsByOrigin && getInsightsForQuestion(insightsByOrigin, section.definition.tag, question.tag, forkedInsightFilter);
                const questionInsightsCoverage =
                    insightsCoverageByOrigin && getInsightsCoverage(insightsCoverageByOrigin, section.definition.tag, question.tag, forkValue);

                if (questionInsights && questionInsights.length) insightsStats.totalInsights += questionInsights.length;
                else if (questionInsightsCoverage && questionInsightsCoverage.coverage === InsightCoverage.NoInsights) insightsStats.ignoredQuestionsCount++;
                else insightsStats.notProcessedQuestionsCount++;
            }
        }
    }

    return insightsStats;
}

type InterviewHypothesesStats = Record<HypothesisVerdict, number> & {
    noVerdict: number;
};
function useInterviewHypothesesStats(ideaId: string, interviewId: number, researchId?: number): InterviewHypothesesStats | undefined {
    const interviewResearchHypotheses = useResearchHypotheses(ideaId, researchId);
    const [interviewHypothesesVerdicts] = useInterviewHypothesesVerdicts(ideaId, interviewId);

    if (!interviewResearchHypotheses || !interviewHypothesesVerdicts) return undefined;

    const hypothesisVerdictMap = toRecord(interviewHypothesesVerdicts, v => v.hypothesisId);

    const stats: InterviewHypothesesStats = {
        [HypothesisVerdict.True]: 0,
        [HypothesisVerdict.False]: 0,
        [HypothesisVerdict.Unknown]: 0,
        noVerdict: 0
    };

    for (const hypothesis of interviewResearchHypotheses) {
        const hypothesisVerdict = hypothesisVerdictMap[hypothesis.id];
        if (!hypothesisVerdict) stats.noVerdict++;
        else stats[hypothesisVerdict.verdict]++;
    }

    return stats;
}

function InterviewStatsView({
    title,
    stats,
    warning,
    children
}: {
    title: string;
    stats: (InterviewStatViewProps & { key: React.Key })[];
    warning?: string;
    children?: ReactNode;
}) {
    return (
        <div>
            <H3 className="!k-mb-2">{title}</H3>
            <div className="k-panel k-px-4 k-py-2 k-d-grid interview-stats-layout k-justify-content-between k-align-items-center k-gap-x-1 k-gap-y-2">
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-10">
                    {stats.map(stat => (
                        <InterviewStatView {...stat} />
                    ))}
                </StackLayout>
                {children && <div>{children}</div>}
                {warning && (
                    <StackLayout align={{ horizontal: 'center', vertical: 'top' }} className="k-row-start-2 k-p-1 k-gap-2 k-icp-bg-warning-8 k-rounded">
                        <WarningIcon className="k-icp-icon k-icp-icon-size-6" />
                        <div className="k-mt-thin">{warning}</div>
                    </StackLayout>
                )}
            </div>
        </div>
    );
}

type InterviewStatViewProps = { quantity?: number; title: ReactNode; icon: ComponentType<React.SVGProps<SVGSVGElement>>; iconWrapperClass?: string };
function InterviewStatView(props: InterviewStatViewProps) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
            <div className={combineClassNames('k-p-1 k-rounded-full', props.iconWrapperClass)}>
                <props.icon className="k-icp-icon k-icp-icon-size-6" />
            </div>
            <div className="k-display-4 k-mr-1">{props.quantity === undefined ? <Skeleton shape="text" style={{ width: 23 }} /> : props.quantity}</div>
            <div className="k-fs-sm">{props.title}</div>
        </StackLayout>
    );
}

type InterviewSummarySidebarPanelMetaData = { key: React.Key; title: string; content?: ReactNode };
function InterviewSummarySidebarPanelView({
    title,
    metaData,
    children
}: {
    title: string;
    metaData?: InterviewSummarySidebarPanelMetaData[];
    children?: ReactNode;
}) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-icp-panel-base k-rounded k-gap-2 k-p-4">
            <H3>{title}</H3>
            {children}
            {metaData &&
                metaData
                    .filter(metaData => metaData.content)
                    .map(metaData => (
                        <StackLayout key={metaData.key} align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-3">
                            <span className="k-icp-subtle-text">{metaData.title}:</span>
                            {metaData.content}
                        </StackLayout>
                    ))}
        </StackLayout>
    );
}

function InterviewSummarySidebarMetadataContentLoader() {
    return <Skeleton shape="text" style={{ width: 120 }} />;
}

function InterviewDetailsSummarySidebarPanel({
    interview,
    jobToBeDone,
    research
}: {
    interview?: Interview;
    jobToBeDone?: BoxItem | null;
    research?: ReducedResearch | null;
}) {
    const currentUserId = useAppSelector(s => s.user?.userId);

    return (
        <InterviewSummarySidebarPanelView
            title="Interview details"
            metaData={[
                {
                    key: 'date',
                    title: 'Interview date',
                    content: interview ? dateTimeService.stringifyToDay(interview.date) : <InterviewSummarySidebarMetadataContentLoader />
                },
                {
                    key: 'interviewer',
                    title: 'Interviewer',
                    content: interview ? (
                        getPersonFullName(interview.user.firstName, interview.user.lastName, interview.user.userId === currentUserId)
                    ) : (
                        <InterviewSummarySidebarMetadataContentLoader />
                    )
                },
                {
                    key: 'jtbd',
                    title: 'Job-to-be-done',
                    content: jobToBeDone === null ? null : jobToBeDone ? jobToBeDone.content : <InterviewSummarySidebarMetadataContentLoader />
                },
                {
                    key: 'research',
                    title: 'Research',
                    content:
                        research === null ? null : research ? (
                            <Link to={`../../research/${research.id}`} className="k-button-link-secondary">
                                {research.title}
                            </Link>
                        ) : (
                            <InterviewSummarySidebarMetadataContentLoader />
                        )
                }
            ]}
        />
    );
}

function IntervieweeDetailsSummarySidebarPanel({ interviewee }: { interviewee?: FullPerson }) {
    const metaData: InterviewSummarySidebarPanelMetaData[] = [];
    if (interviewee) {
        if (interviewee.jobTitle || interviewee.company)
            metaData.push({
                key: 'job',
                title: 'Job title',
                content: (
                    <JobTitleAndCompanyView
                        jobTitle={interviewee.jobTitle}
                        company={interviewee.company}
                        getCompanyLink={c => `../../contacts/companies/${c.id}`}
                    />
                )
            });

        if (interviewee.countryCode || interviewee.city)
            metaData.push({
                key: 'location',
                title: 'Country, City',
                content: <CountryAndCityView city={interviewee.city} countryCode={interviewee.countryCode} />
            });

        if (interviewee.emailAddress) {
            metaData.push({
                key: 'email',
                title: 'Email',
                content: (
                    <a href={`mailto:${interviewee.emailAddress}`} className="k-button-link-secondary">
                        {interviewee.emailAddress}
                    </a>
                )
            });
        }
    } else {
        metaData.push({
            key: 'job',
            title: 'Job title',
            content: <InterviewSummarySidebarMetadataContentLoader />
        });

        metaData.push({
            key: 'location',
            title: 'Country, City',
            content: <InterviewSummarySidebarMetadataContentLoader />
        });

        metaData.push({
            key: 'email',
            title: 'Email',
            content: <InterviewSummarySidebarMetadataContentLoader />
        });
    }

    return (
        <InterviewSummarySidebarPanelView title="Interviewee" metaData={metaData}>
            {interviewee ? (
                <PersonSimpleView person={interviewee} renderAsLink showJobTitle={false} />
            ) : (
                <Skeleton shape="rectangle" style={{ width: 200, height: 40 }} />
            )}
        </InterviewSummarySidebarPanelView>
    );
}

function CustomerSegmentSidebarPanel({ customerSegment }: { customerSegment?: BoxItem | null }) {
    if (customerSegment === null) return null;

    return (
        <InterviewSummarySidebarPanelView title="Customer segment">
            {customerSegment ? <CanvasItemSimpleView item={customerSegment} /> : <Skeleton shape="text" style={{ width: '100%' }} />}
        </InterviewSummarySidebarPanelView>
    );
}
