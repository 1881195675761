import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect } from 'react';
import { ReactComponent as AIBulb } from '../../icons/ai-bulb.svg';
import { googleTagManager } from '../../scripts/googleTagManager';
import { ChatMessageBlockType, ChatMessageBlockTypeMap, ChatMessageType } from '../../services/chatsService';
import { combineClassNames } from '../../services/common';
import { useChatContext } from '../ai/aiLedChat';
import { P } from './typography';

export enum GTM_INTRO_CHAT_TYPE {
    LOOKING_FOR_BUSINESS_IDEA = 'looking-for-business-idea',
    WITH_BUSINESS_IDEA = 'with-business-idea',
    IMPROVE_EXISTING_BUSINESS = 'improve-existing-business'
}

const GTM_INTRO_CHAT_VALUES: string[] = Object.values(GTM_INTRO_CHAT_TYPE);

export type BlockProps<TType extends ChatMessageBlockType> = {
    block: ChatMessageBlockTypeMap[TType];
    streaming: boolean;
    messageId: number | undefined;
    navigateToIdeaPath?: (pathWithinIdea: string) => void;
};

export function TextBlock({ block }: BlockProps<ChatMessageBlockType.Text>) {
    return <>{block.data}</>;
}

export function OptionsBlock({ block, messageId }: BlockProps<ChatMessageBlockType.Options>) {
    const options = block.data;
    const { messages, canSendMessage, sendMessage } = useChatContext();
    const currentMessageIndex = messageId !== undefined && messages !== undefined ? messages.findIndex(m => m.id === messageId) : undefined;
    const nextMessage =
        currentMessageIndex !== undefined && currentMessageIndex !== -1 && currentMessageIndex !== messages!.length - 1
            ? messages![currentMessageIndex + 1]
            : undefined;
    const isDisabled = nextMessage !== undefined || !canSendMessage;

    return (
        <div className="chat-options-block k-my-2">
            {options.map(option => {
                const isSelected = nextMessage && nextMessage.type !== ChatMessageType.Agent && nextMessage.content === option.content;
                return (
                    <div
                        key={option.index}
                        className={combineClassNames('k-flex-1 k-pos-relative', isDisabled ? undefined : 'k-cursor-pointer')}
                        onClick={!isDisabled ? () => sendMessage(option.content) : undefined}
                    >
                        <StackLayout
                            orientation="vertical"
                            align={{ horizontal: 'start', vertical: 'top' }}
                            className={combineClassNames(
                                'k-p-4 k-gap-2',
                                isSelected
                                    ? 'k-border k-border-solid k-rounded-lg k-border-transparent'
                                    : combineClassNames('k-icp-panel', isDisabled ? 'k-disabled' : 'highlight-border-on-hover k-icp-interactive-card-small')
                            )}
                        >
                            <AIBulb className={`k-icp-icon-size-4 ${isDisabled && !isSelected ? 'k-icp-icon' : ''}`} />
                            <p>{option.content}</p>
                        </StackLayout>
                        {isSelected && <div className="k-border k-border-solid ai-border ai-border--purple k-rounded-lg k-border-transparent" />}
                    </div>
                );
            })}
        </div>
    );
}

const goToJourneyTitle = 'Your Guided Entrepreneurial Journey is Ready';
const goToJourneyDescription = 'Explore the Icanpreneur platform, follow the structured journey, and start turning your vision into reality.';
const goToJourneyButtonText = 'Take me to my journey';
export function GoToJourneyBlock({ streaming, navigateToIdeaPath }: BlockProps<ChatMessageBlockType.GoToJourney>) {
    return (
        <ActionCard
            disabled={streaming}
            title={goToJourneyTitle}
            description={goToJourneyDescription}
            buttonText={goToJourneyButtonText}
            onClick={() => navigateToIdeaPath?.('/journey')}
        />
    );
}

const canvasTitle = goToJourneyButtonText;
const canvasDescription = goToJourneyDescription;
const canvasButtonText = goToJourneyButtonText;
export function CanvasBlock({ streaming, navigateToIdeaPath }: BlockProps<ChatMessageBlockType.Canvas>) {
    return (
        <ActionCard
            disabled={streaming || !navigateToIdeaPath}
            title={canvasTitle}
            description={canvasDescription}
            buttonText={canvasButtonText}
            onClick={() => navigateToIdeaPath?.('/journey')}
        />
    );
}

export function TrackingBlock({ block, streaming }: BlockProps<ChatMessageBlockType.Tracking>) {
    const trackingEvent = block.data.toLowerCase();
    const reportTracking = streaming && GTM_INTRO_CHAT_VALUES.includes(trackingEvent);
    useEffect(() => {
        if (!reportTracking) return;

        googleTagManager.reportChatOptionSelectedEvent(trackingEvent);
    }, [reportTracking, trackingEvent]);

    return null;
}

interface ActionCardProps {
    title: string;
    description: string;
    buttonText: string;
    disabled?: boolean;
    onClick?: () => void;
}

function ActionCard({ title, description, buttonText, onClick, disabled }: ActionCardProps) {
    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'start', vertical: 'top' }}
            style={{ maxWidth: 414 }}
            className="k-icp-panel k-p-4 k-gap-4 k-mt-4 k-mb-2"
        >
            <div>
                <div className="k-font-semibold k-mb-1">{title}</div>
                <P>{description}</P>
            </div>
            <Button disabled={disabled} themeColor="primary" onClick={onClick}>
                {buttonText}
            </Button>
        </StackLayout>
    );
}

export const messageBlockTextExtractors: {
    [T in ChatMessageBlockType]: ((block: ChatMessageBlockTypeMap[T]) => string | undefined) | null;
} = {
    [ChatMessageBlockType.Text]: textBlock => textBlock.data,
    [ChatMessageBlockType.Options]: optionsBlock => optionsBlock.data.map(option => `Option ${option.index}: ${option.content}`).join('; '),
    [ChatMessageBlockType.Canvas]: () => `${canvasTitle}\n${canvasDescription}`,
    [ChatMessageBlockType.GoToJourney]: () => `${goToJourneyTitle}\n${goToJourneyDescription}`,
    [ChatMessageBlockType.Tracking]: null,
    [ChatMessageBlockType.Progress]: null
};
