import { Card, CardBody, CardHeader, CardSubtitle, StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, ReactElement } from 'react';
import { ReactComponent as CompanyIcon } from '../../icons/briefcase.svg';
import { ReactComponent as GlobeIcon } from '../../icons/globe.svg';
import { ReactComponent as LinkedInIcon } from '../../icons/linkedin.svg';
import { ReactComponent as MailIcon } from '../../icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as UsersIcon } from '../../icons/users.svg';
import { combineClassNames, generateInitials, getPreferredColorIndex } from '../../services/common';
import { Company, Person } from '../../services/contactsService';
import { BoundDropDownButton, DropDownButtonItem } from '../common/boundDropDownButton';
import UserAvatar from '../user/userAvatar';

export type InterviewReferredContact = {
    firstName: string | null;
    lastName: string | null;
    emailAddress: string | null;
    phoneNumber: string | null;
    linkedIn: string | null;
    country: string | null;
    city: string | null;
    jobTitle: string | null;
    company: string | null;
};

export function PersonDetailsCard({
    name,
    emailAddress,
    phoneNumber,
    linkedIn,
    jobTitle,
    company,
    actions,
    disabled,
    dashed
}: {
    name: string;
    emailAddress?: string;
    phoneNumber?: string;
    linkedIn?: string;
    jobTitle?: string;
    company?: string;
    actions?: DropDownButtonItem[];
    dashed?: boolean;
    disabled?: boolean;
}) {
    const details: ReactElement[] = [];
    if (emailAddress) {
        details.push(
            <ContactCardDetail key="email" Icon={MailIcon} smallDetails>
                {emailAddress}
            </ContactCardDetail>
        );
    }

    if (phoneNumber) {
        details.push(
            <ContactCardDetail key="phone" Icon={PhoneIcon} smallDetails>
                {phoneNumber}
            </ContactCardDetail>
        );
    }

    if (company)
        details.push(
            <ContactCardDetail key="company" Icon={CompanyIcon} smallDetails>
                {company}
            </ContactCardDetail>
        );

    if (linkedIn) {
        details.push(
            <ContactCardDetail key="linkedIn" Icon={LinkedInIcon} smallDetails>
                {linkedIn}
            </ContactCardDetail>
        );
    }

    return <ContactCard dashed={dashed} title={name} subTitle={jobTitle} detailsElements={details} actions={actions} disabled={disabled} />;
}

export function PersonCard({ person }: { person: Person }) {
    const details: ReactElement[] = [];
    if (person.emailAddress)
        details.push(
            <ContactCardDetail key="email" Icon={MailIcon}>
                {person.emailAddress}
            </ContactCardDetail>
        );

    if (person.phoneNumber)
        details.push(
            <ContactCardDetail key="phone" Icon={PhoneIcon}>
                {person.phoneNumber}
            </ContactCardDetail>
        );

    if (person.companyName)
        details.push(
            <ContactCardDetail key="company" Icon={CompanyIcon}>
                {person.companyName}
            </ContactCardDetail>
        );

    return (
        <ContactCard
            avatar={
                <UserAvatar
                    picture={person.picture}
                    initials={generateInitials(2, person.firstName, person.lastName)}
                    colorIndex={getPreferredColorIndex(person.id)}
                />
            }
            title={person.name}
            subTitle={person.jobTitle}
            detailsElements={details}
        />
    );
}

export function CompanyCard({ company }: { company: Company }) {
    const details: ReactElement[] = [];
    if (company.website)
        details.push(
            <ContactCardDetail key="website" Icon={GlobeIcon}>
                {company.website}
            </ContactCardDetail>
        );

    const hasContactsInCompany = (company.peopleCount ?? 0) > 0;
    details.push(
        <ContactCardDetail key="contacts" Icon={UsersIcon}>
            <span className={hasContactsInCompany ? undefined : 'k-icp-subtle-text'}>
                {hasContactsInCompany ? (company.peopleCount === 1 ? '1 contact' : `${company.peopleCount} contacts`) : 'No contacts yet'}
            </span>
        </ContactCardDetail>
    );

    const companyNameParts = company.name.split(' ');

    return (
        <ContactCard
            avatar={
                <UserAvatar
                    picture={company.picture}
                    initials={generateInitials(2, companyNameParts[0], companyNameParts[1])}
                    colorIndex={getPreferredColorIndex(company.id)}
                />
            }
            title={company.name}
            detailsElements={details}
        />
    );
}

function ContactCard({
    avatar,
    title,
    subTitle,
    detailsElements,
    actions,
    dashed = false,
    disabled = false
}: {
    avatar?: ReactElement;
    title: string;
    subTitle?: string;
    detailsElements?: (ReactElement | undefined)[];
    actions?: DropDownButtonItem[];
    dashed?: boolean;
    disabled?: boolean;
}) {
    const hasDetails = detailsElements && detailsElements.length > 0;

    return (
        <Card className={combineClassNames('k-gap-3', dashed ? 'k-icp-dashed-border k-icp-no-shadow' : 'k-icp-interactive-card')}>
            <CardHeader className={combineClassNames('!k-border-bottom-0', hasDetails ? '!k-pb-0' : undefined)}>
                <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4 k-justify-content-between">
                    <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                        {avatar}
                        <div>
                            <strong className="k-fs-lg">{title}</strong>
                            {subTitle && <CardSubtitle className="!k-mt-0">{subTitle}</CardSubtitle>}
                        </div>
                    </StackLayout>
                    {actions && (
                        <BoundDropDownButton
                            disabled={disabled}
                            items={actions}
                            buttonClass="!k-min-w-0 k-icp-svg-icon-button"
                            popupSettings={{
                                anchorAlign: { horizontal: 'right', vertical: 'bottom' },
                                popupAlign: { horizontal: 'right', vertical: 'top' }
                            }}
                            fillMode="flat"
                            size="small"
                            text={<PlusIcon className="k-icp-icon" />}
                        />
                    )}
                </StackLayout>
            </CardHeader>
            {hasDetails && (
                <>
                    <div className="k-px-4">
                        <div className="full-horizontal-separator" />
                    </div>
                    <CardBody className="!k-pt-0">
                        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-1">
                            {detailsElements}
                        </StackLayout>
                    </CardBody>
                </>
            )}
        </Card>
    );
}

function ContactCardDetail({
    Icon,
    children,
    smallDetails
}: {
    Icon: ComponentType<React.SVGProps<SVGSVGElement>>;
    children?: ReactElement | string;
    smallDetails?: boolean;
}) {
    if (!children) return null;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
            <Icon className="k-shrink-0 k-icp-icon k-icp-icon-size-4" />
            <span className={combineClassNames('-bw', smallDetails ? 'k-fs-sm' : undefined)}>{children}</span>
        </StackLayout>
    );
}
