import { appConfig } from '../config';
import { Chat } from './chatsService';
import { ErrorWithOperationDisplayName } from './common';
import { Person, ReducedPerson } from './contactsService';
import { dateTimeService } from './dateTimeService';
import { HttpServiceBase, PagedResponse, RequestMethod } from './httpServiceBase';
import { ReducedUser } from './usersService';

export enum Interview2Type {
    ProblemDiscoveryAndValidation = 'ProblemDiscoveryAndValidation',
    WinLoss = 'WinLoss',
    BuyerPersona = 'BuyerPersona',
    Other = 'Other'
}

export enum InterviewStageV2 {
    NotStarted = 'NotStarted',
    InProgress = 'InProgress',
    Completed = 'Completed'
}

export enum InterviewSortOrderV2 {
    IntervieweeAsc = 'IntervieweeAsc',
    IntervieweeDesc = 'IntervieweeDesc',
    TypeAsc = 'TypeAsc',
    TypeDesc = 'TypeDesc',
    DateAsc = 'DateAsc',
    DateDesc = 'DateDesc'
}

export enum InterviewExecutionTypeV2 {
    IvaChat = 'IvaChat',
    InPersonMeeting = 'InPersonMeeting',
    OnlineMeeting = 'OnlineMeeting',
    Import = 'Import'
}

export enum InterviewAnalysisStatus {
    NotStarted = 'NotStarted',
    Pending = 'Pending',
    Complete = 'Complete',
    Error = 'Error'
}

export type UploadInterviewDataV2 = {
    type: Interview2Type;
    contactId?: number;
    hostId?: string;
};

export type UploadMultipleInterviewsDataV2 = {
    type: Interview2Type;
    hostId?: string;
};

export type MinimalInterviewAnalysisV2 = {
    qualityScore: number | null;
    scriptCoverage: number | null;
    status: InterviewAnalysisStatus;
};

export type MinimalInterviewV2 = {
    id: number;
    title: string;
    type: Interview2Type;
    stage: InterviewStageV2;
    aiLed: boolean;
    imported: boolean;
    date: Date | null;
    intervieweeName: string | null;
    contact: ReducedPerson | null;
    host: ReducedUser | null;
    analysis: MinimalInterviewAnalysisV2 | null;
};

export type InterviewDetailV2Base = {
    id: number;
    type: InterviewExecutionTypeV2;
};

export type ImportedInterviewDetailV2 = InterviewDetailV2Base & {
    transcriptName: string;
    scriptName: string | null;
};

export type InPersonInterviewDetailV2 = InterviewDetailV2Base & {
    scriptId: number;
    transcriptId: number;
};

export type OnlineMeetingInterviewDetailV2 = InterviewDetailV2Base & {
    meetingCode: string;
    scriptId: number;
    transcriptId: number;
};

export type IvaChatInterviewDetailV2 = InterviewDetailV2Base & {
    scriptId: number;
    scriptName: string;
    chat?: Chat | null;
    publicCode: string;
    percentCompleted: number;
};

export type InterviewV2 = MinimalInterviewV2 & {
    createdBy: ReducedUser;
    createdOn: Date;
    updatedBy: ReducedUser | null;
    updatedOn: Date | null;
    publicCode: string;
    meetingCode: string | null;
    //this script is only for scripts entities in the platform (uploaded files are detail properties of imported interviews)
    scriptId: number | null;
    scriptName: string | null;
    detail?: InterviewDetailV2Base;
};

export type InterviewsV2 = PagedResponse<'interviews', MinimalInterviewV2>;

export type InterviewInsightSummaryItem = {
    title: string;
    content: string;
};

export type InterviewQualitySummary = {
    score: number;
    text: string;
};

export type InterviewScriptCoverage = {
    coverage: number;
    text: string;
};

export type InterviewNextStep = {
    title: string;
    text: string;
};

export type InterviewReferredContact = {
    id: number;
    firstName: string | null;
    lastName: string | null;
    emailAddress: string | null;
    phoneNumber: string | null;
    linkedIn: string | null;
    country: string | null;
    city: string | null;
    jobTitle: string | null;
    company: string | null;
    contact: Person | null;
};

export type InterviewInsight = {
    insight: string;
    group: string | null;
    quote: string | null;
};

export type InterviewInsightCategory = {
    name: string;
    description: string;
    insights: InterviewInsight[];
};

export type InterviewQualityAssessmentRecommendation = {
    recommendation: string;
    percentageImprovement: number;
};

export type InterviewQualityAssessmentArea = {
    title: string;
    text: string;
    percentageImprovement: number;
    recommendations: InterviewQualityAssessmentRecommendation[];
};

export type InterviewAnalysis = {
    status: InterviewAnalysisStatus;
    insightSummaryItems: InterviewInsightSummaryItem[];
    qualitySummary: InterviewQualitySummary | null;
    scriptCoverage: InterviewScriptCoverage | null;
    nextSteps: InterviewNextStep[];
    referrals: InterviewReferredContact[];
};

class InterviewsV2Service extends HttpServiceBase {
    constructor() {
        super('/api/interviews3');
    }

    private static ensureMinimalInterviewDateFields(interview: MinimalInterviewV2): MinimalInterviewV2 {
        dateTimeService.ensureDateField(interview, 'date');
        return interview;
    }

    private static ensureInterviewDateFields(interview: InterviewV2): InterviewV2 {
        dateTimeService.ensureDateField(interview, 'date');
        dateTimeService.ensureDateField(interview, 'createdOn');
        dateTimeService.ensureDateField(interview, 'updatedOn');
        return interview;
    }

    @ErrorWithOperationDisplayName('Get interviews')
    getInterviews(
        ideaId: string,
        orderBy?: InterviewSortOrderV2,
        afterId?: number,
        skip?: number,
        take?: number,
        stage?: InterviewStageV2
    ): Promise<InterviewsV2> {
        const queryParams: URLSearchParams = new URLSearchParams();
        this.addQueryParamIfPresent(queryParams, 'orderBy', orderBy);
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());
        this.addQueryParamIfPresent(queryParams, 'afterId', afterId?.toString());

        if (stage) {
            queryParams.append('stage', stage);
        }

        return this.performRequest<InterviewsV2>({
            path: `/${ideaId}/interviews`,
            queryParams
        }).then(response => {
            response.interviews.forEach(InterviewsV2Service.ensureMinimalInterviewDateFields);
            return response;
        });
    }

    @ErrorWithOperationDisplayName('Create interview')
    createInterview(ideaId: string, contactId: number, scriptId: number, aiLed?: boolean, hostId?: string): Promise<InterviewV2> {
        return this.performRequest<InterviewV2>({
            path: `/${ideaId}/interviews`,
            method: RequestMethod.POST,
            body: {
                contactId,
                scriptId,
                aiLed,
                hostId
            }
        }).then(InterviewsV2Service.ensureInterviewDateFields);
    }

    @ErrorWithOperationDisplayName('Upload interview')
    uploadInterview(ideaId: string, data: UploadInterviewDataV2, file: File, script?: File): Promise<InterviewV2> {
        const formData = new FormData();
        Object.entries(data).forEach(
            ([key, value]) => value !== undefined && value !== null && formData.append(key, typeof value === 'number' ? value.toString() : value)
        );

        formData.append('file', file);

        if (script) {
            formData.append('script', script);
        }

        return this.performRequest<InterviewV2>({
            path: `/${ideaId}/interviews/upload`,
            method: RequestMethod.POST,
            body: formData
        }).then(InterviewsV2Service.ensureInterviewDateFields);
    }

    @ErrorWithOperationDisplayName('Upload multiple interviews')
    uploadInterviews(ideaId: string, data: UploadMultipleInterviewsDataV2, files: File[], script?: File): Promise<InterviewV2[]> {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => value !== undefined && value !== null && formData.append(key, value));

        files.forEach(file => formData.append('files', file));
        if (script) {
            formData.append('script', script);
        }

        return this.performRequest<InterviewV2[]>({
            path: `/${ideaId}/interviews/multi-upload`,
            method: RequestMethod.POST,
            body: formData
        }).then(interviews => interviews.map(InterviewsV2Service.ensureInterviewDateFields));
    }

    @ErrorWithOperationDisplayName('Get interview')
    getInterview(ideaId: string, interviewId: number): Promise<InterviewV2> {
        return this.performRequest<InterviewV2>({
            path: `/${ideaId}/interviews/${interviewId}`
        }).then(InterviewsV2Service.ensureInterviewDateFields);
    }

    @ErrorWithOperationDisplayName('Update interview')
    updateInterview(
        ideaId: string,
        interviewId: number,
        aiLed?: boolean,
        hostId?: string | null,
        scriptId?: number,
        meetingCode?: string
    ): Promise<InterviewV2> {
        return this.performRequest<InterviewV2>({
            path: `/${ideaId}/interviews/${interviewId}`,
            method: RequestMethod.PATCH,
            body: {
                aiLed,
                hostId,
                scriptId,
                meetingCode
            }
        }).then(InterviewsV2Service.ensureInterviewDateFields);
    }

    @ErrorWithOperationDisplayName('Delete interview')
    async deleteInterview(ideaId: string, interviewId: number): Promise<void> {
        await this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/interviews/${interviewId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Restore interview')
    restoreInterview(ideaId: string, interviewId: number): Promise<InterviewV2> {
        return this.performRequest<InterviewV2>({
            path: `/${ideaId}/interviews/${interviewId}/restore`,
            method: RequestMethod.POST
        }).then(InterviewsV2Service.ensureInterviewDateFields);
    }

    @ErrorWithOperationDisplayName('Get interview script text')
    async getInterviewScriptText(ideaId: string, interviewId: number): Promise<string> {
        const response = await this.performRequest<{ text: string }>({
            path: `/${ideaId}/interviews/${interviewId}/script-file/text`
        });
        return response.text;
    }

    @ErrorWithOperationDisplayName('Get interview transcript text')
    async getInterviewTranscriptText(ideaId: string, interviewId: number): Promise<string> {
        const response = await this.performRequest<{ text: string }>({
            path: `/${ideaId}/interviews/${interviewId}/transcript-file/text`
        });
        return response.text;
    }

    @ErrorWithOperationDisplayName('Get interview analysis overview')
    async getInterviewAnalysisOverview(ideaId: string, interviewId: number): Promise<InterviewAnalysis> {
        return await this.performRequest<InterviewAnalysis>({
            path: `/${ideaId}/interviews/${interviewId}/analysis/overview`
        });
    }

    @ErrorWithOperationDisplayName('Get interview insights')
    async getInterviewInsights(ideaId: string, interviewId: number): Promise<InterviewInsightCategory[]> {
        return await this.performRequest<InterviewInsightCategory[]>({
            path: `/${ideaId}/interviews/${interviewId}/analysis/insights`
        });
    }

    @ErrorWithOperationDisplayName('Get interview quality assessment')
    async getInterviewQualityAssessment(ideaId: string, interviewId: number): Promise<InterviewQualityAssessmentArea[]> {
        return this.performRequest<InterviewQualityAssessmentArea[]>({
            path: `/${ideaId}/interviews/${interviewId}/analysis/quality`
        });
    }

    @ErrorWithOperationDisplayName('Get referred contact')
    async getReferredContact(ideaId: string, interviewId: number, referralId: number): Promise<InterviewReferredContact> {
        return this.performRequest<InterviewReferredContact>({
            path: `/${ideaId}/interviews/${interviewId}/analysis/referredContact/${referralId}`
        });
    }

    @ErrorWithOperationDisplayName('Associate contact with referral')
    async associateContactWithReferral(ideaId: string, interviewId: number, referralId: number, contactId: number): Promise<InterviewReferredContact> {
        return this.performRequest<InterviewReferredContact>({
            path: `/${ideaId}/interviews/${interviewId}/analysis/referredContact/${referralId}`,
            method: RequestMethod.POST,
            body: {
                contactId
            }
        });
    }
}

export const interviewsV2Service = new InterviewsV2Service();

export type PublicInterview = {
    id: number;
    title: string;
    ideaTitle: string;
    scriptDurationInMinutes?: number | null;
    type: Interview2Type;
    stage: InterviewStageV2;
    publicCode: string;
    detail?: IvaChatInterviewDetailV2 | null;
};

class PublicInterviewsService extends HttpServiceBase {
    constructor() {
        super('/api/interviews3');
    }

    @ErrorWithOperationDisplayName('Get interview')
    getInterview(publicCode: string): Promise<PublicInterview> {
        return this.performRequest({
            path: `/iva-led-interviews/${publicCode}`
        });
    }

    @ErrorWithOperationDisplayName('Start interview')
    startInterview(publicCode: string, recaptchaToken?: string | null): Promise<PublicInterview> {
        return this.performRequest({
            path: `/iva-led-interviews/${publicCode}/start`,
            method: RequestMethod.POST,
            headers: recaptchaToken ? { [appConfig.recaptchaTokenHeaderName]: recaptchaToken } : undefined
        });
    }

    // @ErrorWithOperationDisplayName('Complete interview')
    // completeInterview(publicCode: string): Promise<PublicInterview> {

    // }
}

export const publicInterviewsService = new PublicInterviewsService();
