import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { ReactNode, useLayoutEffect, useRef } from 'react';
import { useAsRef } from '../../hooks/commonHooks';
import { NewItemButtonDropDownFooter, itemRenderFromCallback, listNoDataRenderWithText, useFilterItems } from './dropDownCommon';

export function RemoteDataComboBox<
    TKeyField extends keyof TData & string,
    TTextField extends keyof TData & string,
    TData extends { [Property in TTextField]: string } & { [Property in TKeyField]: PropertyKey },
    TValue extends { [Property in TTextField]: string } & { [Property in TKeyField]: PropertyKey }
>({
    id,
    dataItemKey,
    textField,
    fetchItems,
    placeholder,
    value,
    onChange,
    emptyListText,
    renderItem,
    minFilterLength = 3,
    insertItemText,
    onInsertItem,
    onClose,
    disabled,
    valid,
    className
}: {
    id?: string;
    dataItemKey: TKeyField;
    textField: TTextField;
    fetchItems: (filter: string) => Promise<TData[]>;
    placeholder?: string;
    value?: TValue | null;
    onChange?: (e: { value: TData | undefined | null }) => void;
    emptyListText?: string;
    renderItem?: (item: TData) => ReactNode;
    minFilterLength?: number;
    insertItemText?: string;
    onInsertItem?: (text?: string) => void;
    onClose?: () => void;
    disabled?: boolean;
    valid?: boolean;
    className?: string;
}) {
    const [items, isLoading, filterItems] = useFilterItems(fetchItems, minFilterLength);
    const lastFilterValueRef = useRef<string>();
    const comboBoxRef = useRef<ComboBox>(null);
    const valueRef = useAsRef(value);
    const insertItemRef = useAsRef(onInsertItem);

    const itemRender = itemRenderFromCallback(renderItem);
    const listNoDataRender = listNoDataRenderWithText(emptyListText, isLoading);

    function onFilterItems(e: ComboBoxFilterChangeEvent) {
        const filterValue: string = e.filter.value;
        lastFilterValueRef.current = filterValue;
        filterItems(filterValue);
    }

    useLayoutEffect(() => {
        if (!comboBoxRef.current?.element) return;

        const element = comboBoxRef.current.element;

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Enter' && comboBoxRef.current?.state.opened) {
                e.preventDefault();
            }
        };

        const handleKeyUp = (e: KeyboardEvent) => {
            if (e.key === 'Enter' && insertItemRef.current && lastFilterValueRef.current) {
                if (!valueRef.current) {
                    insertItemRef.current(lastFilterValueRef.current);
                }
            }
        };

        element.addEventListener('keydown', handleKeyDown);
        element.addEventListener('keyup', handleKeyUp);

        return () => {
            element.removeEventListener('keydown', handleKeyDown);
            element.removeEventListener('keyup', handleKeyUp);
        };
    }, [insertItemRef, valueRef]);

    return (
        <ComboBox
            ref={comboBoxRef}
            id={id}
            dataItemKey={dataItemKey}
            textField={textField}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            itemRender={renderItem ? itemRender : undefined}
            loading={isLoading}
            data={items}
            filterable={true}
            onFilterChange={onFilterItems}
            onOpen={e => {
                // open from the drop down arrow
                if (e.syntheticEvent.type === 'click') filterItems(value ? value[textField] : '', true);
            }}
            listNoDataRender={listNoDataRender}
            footer={
                insertItemText ? (
                    <NewItemButtonDropDownFooter newItemText={insertItemText} onClick={onInsertItem && (() => onInsertItem(lastFilterValueRef.current))} />
                ) : (
                    undefined
                )
            }
            onClose={onClose}
            disabled={disabled}
            valid={valid}
            className={className}
        />
    );
}
