import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Skeleton } from '@progress/kendo-react-indicators';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Company, contactsService, ContactsSortBy, Person, ReducedPerson } from '../../services/contactsService';
import { RemoteDataComboBox } from '../common/remoteDataComboBox';
import { ValidationScope, ValidationScopeHandle, ValidationUnit } from '../common/validation';
import { requiredValidator } from '../ui/inputs';
import { ContactSimpleSelector } from './contactSimpleSelector';
import { CreateCompanyDialog, CreatePersonDialog } from './edit';
import { CompanySimpleView, PersonSimpleView } from './view';

const MAX_SINGLE_PICKER_CONTACTS = 7;

export function PersonPickerInput({
    ideaId,
    value,
    onChange,
    simplePicker,
    simplePickerEmptyText,
    excludedPeopleIds,
    placeholder,
    insertItem = true
}: {
    ideaId: string;
    value?: Person | ReducedPerson | null;
    onChange?: (e: { value: Person | undefined | null }) => void;
    excludedPeopleIds?: number[];
    simplePicker?: boolean;
    insertItem?: boolean;
    placeholder?: string;
    simplePickerEmptyText?: string;
}) {
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const createPersonDataRef = useRef<{ firstName: string; lastName?: string }>();
    const [contacts, setContacts] = useState<Person[] | undefined>(undefined);

    const loadContactsForSimplePicker = useCallback(async () => {
        if (!simplePicker) return;
        return contactsService
            .getPeople(ideaId, ContactsSortBy.Recent, undefined, undefined, MAX_SINGLE_PICKER_CONTACTS)
            .then(people =>
                excludedPeopleIds && excludedPeopleIds.length ? people.people.filter(person => !excludedPeopleIds.includes(person.id)) : people.people
            )
            .then(setContacts);
    }, [excludedPeopleIds, ideaId, simplePicker]);

    useEffect(() => {
        loadContactsForSimplePicker();
    }, [loadContactsForSimplePicker]);

    return (
        <>
            {showCreateDialog && (
                <CreatePersonDialog
                    ideaId={ideaId}
                    onCancel={() => setShowCreateDialog(false)}
                    initialData={createPersonDataRef.current}
                    onCreated={newPerson => {
                        setShowCreateDialog(false);
                        loadContactsForSimplePicker();
                        onChange?.({ value: newPerson });
                    }}
                />
            )}
            <RemoteDataComboBox
                dataItemKey="id"
                textField="name"
                placeholder={placeholder ?? 'Add details...'}
                value={value}
                onChange={onChange}
                emptyListText="No contacts found."
                renderItem={p => <PersonSimpleView person={p} showJobTitle={true} />}
                fetchItems={filter =>
                    contactsService
                        .getPeople(
                            ideaId,
                            filter ? ContactsSortBy.Alphabetical : ContactsSortBy.Recent,
                            filter ? { search: filter } : undefined,
                            undefined,
                            filter ? undefined : 5
                        )
                        .then(people =>
                            excludedPeopleIds && excludedPeopleIds.length
                                ? people.people.filter(person => !excludedPeopleIds.includes(person.id))
                                : people.people
                        )
                }
                insertItemText={insertItem ? 'New contact' : undefined}
                onInsertItem={text => {
                    if (text && text !== value?.name) {
                        createPersonDataRef.current = contactsService.parsePersonNamesFromString(text);
                    } else createPersonDataRef.current = undefined;
                    setShowCreateDialog(true);
                }}
            />
            {simplePicker && (
                <div className="k-mt-6">
                    <div>Recent contacts</div>
                    {contacts ? (
                        <ContactSimpleSelector
                            className="k-mt-1"
                            selectedContactId={value?.id}
                            contacts={contacts}
                            emptyText={simplePickerEmptyText}
                            onSelectedContact={person => onChange?.({ value: person as Person })}
                        />
                    ) : (
                        <Skeleton style={{ height: '92px' }} />
                    )}
                </div>
            )}
        </>
    );
}

export function CompanyPicker({
    id,
    ideaId,
    value,
    onChange,
    allowCreate,
    placeholder,
    onClose
}: {
    id?: string;
    ideaId: string;
    value?: Company | null;
    onChange?: (e: { value: Company | undefined | null }) => void;
    allowCreate?: boolean;
    placeholder?: string;
    onClose?: () => void;
}) {
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const createCompanyNameRef = useRef<string>();

    return (
        <>
            {showCreateDialog && (
                <CreateCompanyDialog
                    ideaId={ideaId}
                    onCancel={() => setShowCreateDialog(false)}
                    initialName={createCompanyNameRef.current}
                    onCreated={newCompany => {
                        setShowCreateDialog(false);
                        onChange?.({ value: newCompany });
                    }}
                />
            )}
            <RemoteDataComboBox
                id={id}
                dataItemKey="id"
                textField="name"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                emptyListText="No companies found."
                renderItem={c => <CompanySimpleView company={c} />}
                fetchItems={filter =>
                    contactsService
                        .getCompanies(
                            ideaId,
                            filter ? ContactsSortBy.Alphabetical : ContactsSortBy.Recent,
                            filter ? filter : undefined,
                            undefined,
                            filter ? undefined : 5
                        )
                        .then(c => c.companies)
                }
                insertItemText={allowCreate ? 'New company' : undefined}
                onInsertItem={
                    allowCreate
                        ? text => {
                              createCompanyNameRef.current = text !== value?.name ? text || undefined : undefined;
                              setShowCreateDialog(true);
                          }
                        : undefined
                }
                onClose={onClose}
            />
        </>
    );
}

export const contactPickerValidator = requiredValidator('Contact');

export function ContactPickerDialog({
    title,
    ideaId,
    placeholder,
    onSave,
    onCancel
}: {
    title: string;
    ideaId: string;
    placeholder?: string;
    onSave?: (contact: Person) => void;
    onCancel?: () => void;
}) {
    const [selectedContact, setSelectedContact] = useState<Person | undefined | null>();
    const validationScopeRef = useRef<ValidationScopeHandle | null>(null);
    return (
        <ValidationScope ref={validationScopeRef}>
            <Dialog title={title} width={640} onClose={onCancel}>
                <div className="k-mb-4 k-font-weight-bold">Search for a contact or select one from the suggested list</div>
                <ValidationUnit key={'contact'} name={'contact'} value={selectedContact} validator={contactPickerValidator}>
                    {(errorMessage, onParameterValidationChange) => (
                        <>
                            <PersonPickerInput
                                ideaId={ideaId}
                                simplePicker
                                value={selectedContact}
                                insertItem={false}
                                placeholder={placeholder}
                                onChange={contact => {
                                    onParameterValidationChange(contact.value);
                                    setSelectedContact(contact.value);
                                }}
                            />
                            {errorMessage && <div className="k-mt-2 k-text-error">{errorMessage}</div>}
                        </>
                    )}
                </ValidationUnit>
                <DialogActionsBar layout="center">
                    <Button
                        themeColor="primary"
                        onClick={() => {
                            validationScopeRef.current?.validate();
                            if (validationScopeRef.current?.isValid) onSave?.(selectedContact!);
                        }}
                    >
                        Save
                    </Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActionsBar>
            </Dialog>
        </ValidationScope>
    );
}

export function CompanyIdPicker({
    id,
    ideaId,
    value,
    onChange,
    placeholder,
    onClose
}: {
    id?: string;
    ideaId: string;
    value?: number;
    onChange?: (e: { value: number | undefined }) => void;
    placeholder?: string;
    onClose?: () => void;
}) {
    const [company, setCompany] = useState<Company | null>();
    const companyIdRef = useRef(company?.id);
    companyIdRef.current = company?.id;
    const requestedCompanyId = useRef<number>();
    useEffect(() => {
        if (value === companyIdRef.current) return;
        if (!value) {
            if (companyIdRef.current) setCompany(undefined);

            return;
        }

        if (value === requestedCompanyId.current) return;
        requestedCompanyId.current = value;

        contactsService.getCompanyById(ideaId, value).then(c => {
            if (c.id !== requestedCompanyId.current) return;
            setCompany(c);
        });
    }, [ideaId, value]);

    return (
        <CompanyPicker
            id={id}
            ideaId={ideaId}
            value={company}
            onChange={
                onChange
                    ? e => {
                          setCompany(e.value);
                          onChange({ value: e.value?.id });
                      }
                    : undefined
            }
            allowCreate={false}
            placeholder={placeholder}
            onClose={onClose}
        />
    );
}
