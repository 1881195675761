import { dateTimeService } from './dateTimeService';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';

export type Timer = {
    id: number;
    isRunning: boolean;
    elapsedSeconds: number;
    started?: Date | null;
    alarmSeconds: number;
};

class TimersService extends HttpServiceBase {
    constructor() {
        super('/api/timing');
    }

    private static ensureDateTypes(timer: Timer): Timer {
        dateTimeService.ensureDateField(timer, 'started');

        return timer;
    }

    getTimer(ideaId: string, timerId: number): Promise<Timer> {
        // TODO: Remove mock data when API is ready
        const mockTimer: Timer = {
            id: timerId,
            isRunning: false,
            elapsedSeconds: 0,
            started: null,
            alarmSeconds: 1500 // 25 minutes in seconds
        };
        return Promise.resolve(mockTimer);

        // Original implementation:
        // return this.performRequest<Timer>({
        //     path: `/${ideaId}/timers/${timerId}`
        // }).then(TimersService.ensureDateTypes);
    }

    startTimer(ideaId: string, timerId: number): Promise<Timer> {
        return this.performRequest<Timer>({
            path: `/${ideaId}/timers/${timerId}/start`,
            method: RequestMethod.POST
        }).then(TimersService.ensureDateTypes);
    }

    pauseTimer(ideaId: string, timerId: number): Promise<Timer> {
        return this.performRequest<Timer>({
            path: `/${ideaId}/timers/${timerId}/pause`,
            method: RequestMethod.POST
        }).then(TimersService.ensureDateTypes);
    }

    resetTimer(ideaId: string, timerId: number): Promise<Timer> {
        return this.performRequest<Timer>({
            path: `/${ideaId}/timers/${timerId}/reset`,
            method: RequestMethod.POST
        }).then(TimersService.ensureDateTypes);
    }
}

export const timersService = new TimersService();
