import { useCallback, useEffect, useState } from 'react';
import { InterviewStageV2, InterviewsV2, interviewsV2Service } from '../services/interviewsV2Service';
import { RealTimeUpdateInterviewEventData, realTimeUpdatesEventHub } from '../services/realTimeUpdatesService';

export function useInterviews(ideaId: string, interviewStage?: InterviewStageV2, skip?: number, pageSize?: number) {
    const [interviews, setInterviews] = useState<InterviewsV2 | undefined>(undefined);

    const fetchInterviews = useCallback(() => {
        interviewsV2Service.getInterviews(ideaId, undefined, undefined, skip, pageSize, interviewStage).then(interviews => {
            setInterviews(interviews);
        });
    }, [ideaId, interviewStage, pageSize, skip]);

    const updateSingleInterview = useCallback(
        async (interviewId: number) => {
            const updatedInterview = await interviewsV2Service.getInterview(ideaId, interviewId);
            setInterviews(prev => {
                if (!prev) return prev;
                const index = prev.interviews.findIndex(item => item.id === updatedInterview.id);
                if (index !== -1) {
                    const newInterviews = [...prev.interviews];
                    newInterviews[index] = updatedInterview;
                    return { ...prev, interviews: newInterviews };
                }
                return prev;
            });
        },
        [ideaId]
    );

    useEffect(() => {
        fetchInterviews();
    }, [fetchInterviews]);

    useEffect(() => {
        const handleSingleInterviewUpdateEvent = (e: RealTimeUpdateInterviewEventData) => {
            if (e.ideaId !== ideaId) return;
            updateSingleInterview(e.interviewId);
        };

        const handleInterviewsRefresh = (e: RealTimeUpdateInterviewEventData) => {
            if (e.ideaId !== ideaId) return;
            fetchInterviews();
        };

        realTimeUpdatesEventHub.addEventListener('interview3', 'update', handleSingleInterviewUpdateEvent);
        realTimeUpdatesEventHub.addEventListener('interview3', 'analysisUpdate', handleSingleInterviewUpdateEvent);
        realTimeUpdatesEventHub.addEventListener('interview3', 'add', handleInterviewsRefresh);
        realTimeUpdatesEventHub.addEventListener('interview3', 'delete', handleInterviewsRefresh);
        realTimeUpdatesEventHub.addEventListener('interview3', 'restore', handleInterviewsRefresh);
        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview3', 'update', handleSingleInterviewUpdateEvent);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'analysisUpdate', handleSingleInterviewUpdateEvent);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'add', handleInterviewsRefresh);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'delete', handleInterviewsRefresh);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'restore', handleInterviewsRefresh);
        };
    }, [fetchInterviews, ideaId, updateSingleInterview]);

    return {
        interviews
    };
}
