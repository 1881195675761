import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AILabel } from '../../components/ai/aiLabel';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as UploadIcon } from '../../icons/upload.svg';

import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { canvasItemsZoneDependent } from '../../components/canvas/canvasItemsZone';
import { PersonPickerInput } from '../../components/contacts/contactPicker';
import {
    IVAUser,
    IVA_USER,
    InterviewsFullScreenModal,
    UNKNOWN_HOST_USER,
    UnknownUser,
    hostValidator,
    interviewTypeToIllustrationUrlMap,
    interviewTypeToLabelMap,
    intervieweeValidator,
    scriptValidator
} from '../../components/interview2/common';
import { HostsPicker } from '../../components/interview2/hostPicker';
import { InterviewScriptModal } from '../../components/interviewScript2/InterviewScriptModal';
import { ValidatedInput } from '../../components/ui/inputs';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { PredefinedAction, PredefinedActionsList } from '../../components/ui/predefinedActions';
import { useInProgressOperationsTracker } from '../../hooks/commonHooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { ReducedPerson } from '../../services/contactsService';
import { InterviewScript2, interviewScripts2Service } from '../../services/interviewScripts2Service';
import { Interview2Type, interviewsV2Service } from '../../services/interviewsV2Service';
import { ReducedUser } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { InterviewScriptSelector } from '../scripts/interviewScriptSelector';
import { CreateInterviewScriptModal, CreateInterviewScriptModalConfigProps } from '../scripts/interviewScriptsPage';

export const CreateInterviewPage = canvasItemsZoneDependent(function CreateInterviewPage() {
    const navigate = useNavigate();
    const { ideaId } = useIdeaParams();
    const currentUserId = useAppSelector(s => s.user?.userId);
    const [createScriptModalData, setCreateScriptModalData] = useState<CreateInterviewScriptModalConfigProps | undefined>(undefined);
    const [isSubmitInProgress, trackFormSubmit] = useInProgressOperationsTracker();
    const [showEditScriptModalId, setShowEditScriptModalId] = useState<number | undefined>(undefined);
    const formRef = useRef<Form>(null);

    const isLoading = !ideaId;

    const prefillScript = async (scriptId: number) => {
        const script = await interviewScripts2Service.getInterviewScript(ideaId, scriptId);
        formRef.current?.valueSetter('script', script);
    };

    async function onCreateInterviewSubmit(data: Record<string, any>) {
        const interviewee = data.interviewee as ReducedPerson;
        const script = data.script as InterviewScript2;
        const host = data.host as IVAUser | UnknownUser | ReducedUser;
        const aiLed = host.userId === IVA_USER.userId;
        const hostId = host.userId !== IVA_USER.userId && host.userId !== UNKNOWN_HOST_USER.userId ? host.userId : undefined;

        const createdInterview = await interviewsV2Service.createInterview(ideaId, interviewee.id, script.id, aiLed, hostId);
        navigate(`../${createdInterview.id}`);
    }

    return (
        <InterviewsFullScreenModal title="New interview" onClose={() => navigate('./..')}>
            {!isLoading ? (
                <Form
                    ref={formRef}
                    initialValues={{
                        host: IVA_USER
                    }}
                    onSubmit={trackFormSubmit(onCreateInterviewSubmit)}
                    render={({ allowSubmit, onChange: onFormChange }) => {
                        return (
                            <FormElement className="k-p-4">
                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-10 page-content-middle">
                                    <Field
                                        name="interviewee"
                                        label="Interviewee"
                                        labelClassName="!k-mb-4 k-font-bold"
                                        component={ValidatedInput}
                                        inputType={PersonPickerInput}
                                        simplePickerEmptyText="No contacts found"
                                        ideaId={ideaId}
                                        simplePicker
                                        placeholder="Select or create contact..."
                                        validator={intervieweeValidator}
                                    />
                                    <div>
                                        <Field
                                            name="script"
                                            label="Script"
                                            labelClassName="!k-mb-4 k-font-bold"
                                            component={ValidatedInput}
                                            inputType={InterviewScriptSelector}
                                            ideaId={ideaId}
                                            validator={scriptValidator}
                                            placeholder="Select from existing scripts..."
                                        />

                                        <div className="k-mt-6">Or create new script</div>
                                        <CreateNewScriptOptions
                                            disabled={!!isSubmitInProgress}
                                            onAction={type => {
                                                if (type === 'blank') {
                                                    setCreateScriptModalData({});
                                                } else if (type === 'file') {
                                                    setCreateScriptModalData({ import: true });
                                                } else {
                                                    setCreateScriptModalData({ automaticGenerationType: type });
                                                }
                                            }}
                                        />
                                    </div>

                                    <Field
                                        name="host"
                                        label="Interview host"
                                        labelClassName="!k-mb-4 k-font-bold"
                                        component={ValidatedInput}
                                        inputType={HostsPicker}
                                        otherHostsComingSoon
                                        emptyText="No hosts found"
                                        addIva
                                        ideaId={ideaId}
                                        currentUserId={currentUserId}
                                        validator={hostValidator}
                                    />
                                    <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-4">
                                        <Button size="large" fillMode="solid" themeColor="primary" type="submit" disabled={!allowSubmit || isSubmitInProgress}>
                                            Create interview
                                        </Button>
                                        <Button size="large" fillMode="solid" themeColor="base" disabled={isSubmitInProgress} onClick={() => navigate('./..')}>
                                            Cancel
                                        </Button>
                                    </StackLayout>
                                </StackLayout>
                            </FormElement>
                        );
                    }}
                ></Form>
            ) : (
                <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-h-full k-w-full">
                    <LoadingIndicator size="big" />
                </StackLayout>
            )}
            {createScriptModalData && (
                <CreateInterviewScriptModal
                    ideaId={ideaId}
                    {...createScriptModalData}
                    onCreated={s => {
                        setCreateScriptModalData(undefined);
                        setShowEditScriptModalId(s.id);
                    }}
                    onCancel={() => setCreateScriptModalData(undefined)}
                />
            )}
            {showEditScriptModalId && (
                <InterviewScriptModal
                    ideaId={ideaId}
                    scriptId={showEditScriptModalId}
                    onClose={async () => {
                        if (showEditScriptModalId) {
                            await prefillScript(showEditScriptModalId);
                        }
                        setShowEditScriptModalId(undefined);
                    }}
                />
            )}
        </InterviewsFullScreenModal>
    );
});

function CreateNewScriptOptions({ onAction, disabled }: { onAction: (type: 'blank' | 'file' | Interview2Type) => void; disabled: boolean }) {
    return (
        <PredefinedActionsList className="k-mt-1">
            <PredefinedAction text="Create script from blank" disabled={disabled} textClassName="k-fs-sm !k-font-normal" action={() => onAction('blank')}>
                <PlusIcon width="32" height="32" className="k-icp-icon k-my-4 k-mx-auto" />
            </PredefinedAction>
            <PredefinedAction text="Create script from file" disabled={disabled} textClassName="k-fs-sm !k-font-normal" action={() => onAction('file')}>
                <UploadIcon width="32" height="32" className="k-icp-icon k-my-4 k-mx-auto" />
            </PredefinedAction>

            {[Interview2Type.ProblemDiscoveryAndValidation, Interview2Type.BuyerPersona, Interview2Type.WinLoss].map(interviewType => {
                const interviewTypeLabel = interviewTypeToLabelMap[interviewType];
                return (
                    <PredefinedAction
                        disabled={disabled}
                        key={interviewType}
                        text={`Get an IVA generated ${interviewTypeLabel} interview script`}
                        cardClassName="k-pos-relative k-p-4"
                        textClassName="k-fs-sm !k-font-normal"
                        action={() => onAction(interviewType)}
                    >
                        <img
                            src={interviewTypeToIllustrationUrlMap[interviewType]}
                            alt={interviewTypeLabel}
                            width="48"
                            height="48"
                            className="k-display-block k-mx-auto"
                        />
                        <AILabel className="k-pos-absolute k-top-1 k-left-1">Create with IVA</AILabel>
                    </PredefinedAction>
                );
            })}
        </PredefinedActionsList>
    );
}
