import { Fade, Reveal } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import React, {
    ComponentType,
    createContext,
    forwardRef,
    ReactElement,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useStickToBottom } from 'use-stick-to-bottom';
import { AIAvatar } from '../../components/ai/aiAvatar';
import { ChatTextBox, ChatTextBoxHandle } from '../../components/ai/chatTextBox';
import { InterviewMessageBox } from '../../components/interview/entries/interviewMessageLog';
import {
    BlockProps,
    CanvasBlock,
    GoToJourneyBlock,
    messageBlockTextExtractors,
    OptionsBlock,
    TextBlock,
    TrackingBlock
} from '../../components/ui/chatComponents';
import { SvgIconButtonContent } from '../../components/ui/svgIconButtonContent';
import { TypeText } from '../../components/ui/typeText';
import { TypeComponents } from '../../components/ui/typingComponents';
import { TypingDots } from '../../components/ui/typingDots';
import UserAvatar from '../../components/user/userAvatar';
import { ResponsiveGroup, useAsRef, useResponsiveLayout } from '../../hooks/commonHooks';
import { SpeechSynthesisContext, useSpeechSynthesis, useSpeechSynthesisContext } from '../../hooks/speechSynthesis';
import { ReactComponent as ArrowBottomIcon } from '../../icons/arrow-down.svg';
import { ReactComponent as SpeakerIcon } from '../../icons/volume-2.svg';
import {
    AgentChatMessage,
    ChatMessage,
    ChatMessageBlock,
    ChatMessageBlockType,
    ChatMessageType,
    ContactChatMessage,
    UserChatMessage
} from '../../services/chatsService';
import { combineClassNames, generateInitials, getPreferredColorIndex, WithOptional } from '../../services/common';
import { domService } from '../../services/domService';
import { useAppDispatch } from '../../state/hooks';
import { addErrorNotification, addNotification } from '../../state/notifications/platformNotificationsSlice';

export const blockComponentMap: {
    [T in ChatMessageBlockType]: ComponentType<BlockProps<T>> | null;
} = {
    [ChatMessageBlockType.Text]: TextBlock,
    [ChatMessageBlockType.Options]: OptionsBlock,
    [ChatMessageBlockType.Canvas]: CanvasBlock,
    [ChatMessageBlockType.GoToJourney]: GoToJourneyBlock,
    [ChatMessageBlockType.Tracking]: TrackingBlock,
    [ChatMessageBlockType.Progress]: null
};

const SCROLL_CUSTOM_ANIMATION_PARAMS = {
    damping: 0.8, //default 0.7
    stiffness: 0.07, //default 0.05
    mass: 1.25 //default 1.25
};

interface ChatContextType {
    canSendMessage: boolean;
    sendMessage: (message: string) => void;
    messages: ChatMessage[] | undefined;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export function ChatProvider({
    canSendMessage,
    sendMessage,
    messages,
    children
}: {
    canSendMessage: boolean;
    sendMessage: (message: string) => void;
    messages: ChatMessage[] | undefined;
    children: ReactNode;
}) {
    const chatValue = useMemo(
        () => ({
            canSendMessage,
            sendMessage,
            messages
        }),
        [canSendMessage, messages, sendMessage]
    );
    return <ChatContext.Provider value={chatValue}>{children}</ChatContext.Provider>;
}

export function useChatContext() {
    const context = useContext(ChatContext);
    if (context === undefined) {
        throw new Error('useChatContext must be used within a ChatProvider');
    }
    return context;
}

const SECONDS_TO_WAIT_FOR_FIX = 180;

interface AILedChatComponentProps<TSendMessageContext> {
    messages?: ChatMessage[];
    animateIntro?: boolean;
    addTalking?: boolean;
    addListening?: boolean;
    readonly?: boolean;
    hideMessageInput?: boolean;
    onSendMessage?: (message: string, setContext: (context: TSendMessageContext) => void) => AsyncIterable<ChatMessageBlock>;
    onMessageComplete?: (context: TSendMessageContext | undefined) => void;
    generateFixMessage?: (setContext: (context: TSendMessageContext) => void) => AsyncIterable<ChatMessageBlock>;
}

export function AILedChatComponent<TSendMessageContext>({
    messages,
    animateIntro,
    addTalking,
    addListening,
    readonly,
    hideMessageInput,
    onSendMessage,
    onMessageComplete,
    generateFixMessage
}: AILedChatComponentProps<TSendMessageContext>) {
    const initialAnimationMessageRef = useRef<ChatMessageBoxHandle>(null);
    const firstSentenceElementRef = useRef<HTMLSpanElement>(null);
    const secondSentenceElementRef = useRef<HTMLSpanElement>(null);
    const introAnimationRef = useRef<IvaIntroAnimationHandle>(null);
    const streamingMessageContextRef = useRef<TSendMessageContext>();
    const [streamingMessage, setStreamingMessage] = useState<ChatMessageBlock[]>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [typingEnded, setTypingEnded] = useState(true);
    const [startMorph, setStartMorph] = useState<boolean>();
    const [introAnimationHidden, setIntroAnimationHidden] = useState<boolean>();
    const [fullIntroFinished, setFullIntroFinished] = useState(false);
    const [initialAnimationFinished, setInitialAnimationFinished] = useState(false);
    const [streamingEnded, setStreamingEnded] = useState(false);
    const responsiveGroup = useResponsiveLayout();
    const [isAutospeakEnabled, setIsAutospeakEnabled] = useState(false);
    const isMobile = responsiveGroup === ResponsiveGroup.xs;

    const introAnimationData = animateIntro ? resolveIntroAnimationData(messages) : undefined;
    const hasIntroAnimation = introAnimationData || (animateIntro && !messages);
    const triggerInitialTyping = messages?.length === 1;
    const hasInitialAnimation = hasIntroAnimation || triggerInitialTyping;

    const { scrollRef, contentRef, scrollToBottom, isAtBottom } = useStickToBottom({
        initial: 'instant' as ScrollBehavior,
        resize: 'instant' as ScrollBehavior
    });
    const textboxRef = useRef<ChatTextBoxHandle>(null);
    const isLastMessageFromUser = messages !== undefined && messages.length > 0 && messages[messages.length - 1].type !== ChatMessageType.Agent;

    const speech = useSpeechSynthesis();
    const shouldAddSpeakers = addListening && speech.isAvailable;

    const processMessageStream = useCallback(
        async function processMessageStream(getStream: (setContext: (context: TSendMessageContext) => void) => AsyncIterable<ChatMessageBlock>) {
            streamingMessageContextRef.current = undefined;
            const messageStream = getStream(c => (streamingMessageContextRef.current = c));
            setStreamingMessage([]);
            setTypingEnded(false);
            setStreamingEnded(false);
            try {
                for await (const chunk of messageStream) {
                    setStreamingMessage(msgs => [...(msgs ?? []), chunk]);
                }
            } catch (e) {
                console.error(e);
                dispatch(addErrorNotification('Error processing message. Please try again in a few minutes.'));
            }
            setStreamingEnded(true);
        },
        [dispatch]
    );

    useEffect(() => {
        if (!generateFixMessage || !isLastMessageFromUser) return;

        if (!messages?.length) return;
        const lastMessage = messages[messages.length - 1];
        const lastMessageTime = lastMessage.updatedOn ?? lastMessage.createdOn;
        if (!lastMessageTime) return;
        const messageTime = lastMessageTime.getTime();
        if (Date.now() - messageTime < SECONDS_TO_WAIT_FOR_FIX * 1000) return;

        processMessageStream(generateFixMessage);
    }, [messages, isLastMessageFromUser, generateFixMessage, processMessageStream]);

    function finishInitialAnimation() {
        setTimeout(() => setInitialAnimationFinished(true), 500);
    }

    useBlockElementMorph(introAnimationRef.current?.avatarElement, initialAnimationMessageRef.current?.agentAvatarRef?.current, !startMorph, () => {
        setIntroAnimationHidden(true);
        if (introAnimationData?.restOfTheIntro) setTimeout(() => setFullIntroFinished(true), 300);
        else finishInitialAnimation();
    });
    useTextElementMorph(introAnimationRef.current?.titleElement, firstSentenceElementRef.current, !startMorph);
    useTextElementMorph(introAnimationRef.current?.subtitleElement, secondSentenceElementRef.current, !startMorph);

    useLayoutEffect(() => {
        if (!typingEnded || (hasInitialAnimation && !initialAnimationFinished) || isLastMessageFromUser) return;
        textboxRef.current?.focus();
    }, [isLastMessageFromUser, typingEnded, hasInitialAnimation, initialAnimationFinished]);

    function handleSendMessage(message: string) {
        if (!message || !onSendMessage) return;

        return processMessageStream(setContext => onSendMessage(message, setContext));
    }

    const canSendMessage = typingEnded && !isLastMessageFromUser && !readonly;
    const showInitialAnimation = hasInitialAnimation && !initialAnimationFinished;
    return (
        <SpeechSynthesisContext.Provider value={speech}>
            <ChatProvider canSendMessage={canSendMessage} sendMessage={handleSendMessage} messages={messages}>
                <StackLayout orientation="vertical" align={{ vertical: 'top', horizontal: 'stretch' }} className="k-h-full ">
                    <Helmet>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=1.0, minimum-scale=1.0, maximum-scale=1.0" />
                    </Helmet>

                    <div className="k-overflow-auto k-flex-1 k-px-4 -sg-s" ref={scrollRef}>
                        <div
                            ref={contentRef}
                            className={combineClassNames(
                                'k-stack-layout k-vstack k-align-items-stretch k-gap-4 page-content-middle page-content--xxl k-py-6',
                                isMobile ? 'k-min-h-full k-justify-content-end' : 'k-justify-content-start'
                            )}
                        >
                            {showInitialAnimation ? (
                                <ChatMessageBox
                                    ref={initialAnimationMessageRef}
                                    message={messages?.[0] ?? { type: ChatMessageType.Agent }}
                                    loading={hasIntroAnimation ? fullIntroFinished && introAnimationData?.restOfTheIntro !== undefined : triggerInitialTyping}
                                >
                                    {hasIntroAnimation ? (
                                        introAnimationData && (
                                            <>
                                                <span ref={firstSentenceElementRef}>{introAnimationData.firstIntroSentence}</span>
                                                {introAnimationData.secondIntroSentence && (
                                                    <span ref={secondSentenceElementRef}>{introAnimationData.secondIntroSentence}</span>
                                                )}
                                                {introAnimationData.restOfTheIntro && fullIntroFinished && (
                                                    <ChatMessageContent
                                                        content={introAnimationData.restOfTheIntro}
                                                        streaming={false}
                                                        messageId={messages?.[0]?.id}
                                                        typing
                                                        onTypingEnd={finishInitialAnimation}
                                                        keepFirstTextBlockWhiteSpaces
                                                    />
                                                )}
                                            </>
                                        )
                                    ) : triggerInitialTyping ? (
                                        <ChatMessageContent
                                            content={messages[0].type === ChatMessageType.Agent ? messages[0].blocks : messages[0].content}
                                            streaming={false}
                                            messageId={messages[0].id}
                                            typing
                                            onTypingEnd={finishInitialAnimation}
                                        />
                                    ) : (
                                        undefined
                                    )}
                                </ChatMessageBox>
                            ) : (
                                messages && (
                                    <>
                                        {messages.map((message, msgIndex) => {
                                            const isLastMessage = msgIndex === messages.length - 1;
                                            return (
                                                <ChatMessageBox
                                                    key={message.id}
                                                    message={message}
                                                    enableSpeaking={shouldAddSpeakers}
                                                    autoSpeak={isAutospeakEnabled && message.type === ChatMessageType.Agent && isLastMessage}
                                                >
                                                    <ChatMessageContent
                                                        content={message.type === ChatMessageType.Agent ? message.blocks : message.content}
                                                        streaming={false}
                                                        messageId={message.id}
                                                        navigateToIdeaPath={ideaPath => navigate(`..${ideaPath}`)}
                                                    />
                                                </ChatMessageBox>
                                            );
                                        })}
                                        {isLastMessageFromUser && (
                                            <ChatMessageBox message={{ type: ChatMessageType.Agent, blocks: streamingMessage }} loading>
                                                <ChatMessageContent
                                                    content={streamingMessage}
                                                    streaming
                                                    messageId={undefined}
                                                    typing
                                                    onTypingEnd={() => {
                                                        setTypingEnded(true);
                                                        onMessageComplete?.(streamingMessageContextRef.current);
                                                        setStreamingMessage(undefined);
                                                        streamingMessageContextRef.current = undefined;
                                                    }}
                                                    typingWaitForMore={!streamingEnded}
                                                />
                                            </ChatMessageBox>
                                        )}
                                    </>
                                )
                            )}
                        </div>
                    </div>

                    {!showInitialAnimation && messages && (
                        <div className="k-pos-relative">
                            <div className="k-px-4 k-overflow-auto -sg-s">
                                {!hideMessageInput && (
                                    <ChatTextBox
                                        microphone={addTalking}
                                        micPaused={!typingEnded || readonly || isLastMessageFromUser || speech.isSpeaking}
                                        ref={textboxRef}
                                        disabled={!canSendMessage}
                                        onResize={() => {
                                            scrollToBottom({ animation: 'instant' as ScrollBehavior });
                                        }}
                                        textBoxAttributes={{ rows: isMobile ? 1 : 2 }}
                                        className={`${isMobile ? 'k-mb-2' : 'k-mb-6'} page-content-middle page-content--xxl`}
                                        onSend={handleSendMessage}
                                        bottomLeftActionComponent={
                                            shouldAddSpeakers && (
                                                <SpeakerButtonComponent
                                                    onSpeakerClick={() => {
                                                        !isAutospeakEnabled &&
                                                            dispatch(
                                                                addNotification({
                                                                    content: 'Responses from IVA will be read out.'
                                                                })
                                                            );
                                                        setIsAutospeakEnabled(!isAutospeakEnabled);
                                                    }}
                                                    selected={isAutospeakEnabled}
                                                    disabled={!canSendMessage}
                                                />
                                            )
                                        }
                                    />
                                )}
                            </div>
                            {!isAtBottom && (
                                <div className="chat-to-bottom-arrow">
                                    <Button
                                        type="button"
                                        fillMode="solid"
                                        themeColor="light"
                                        rounded="full"
                                        size="large"
                                        selected
                                        className="!k-p-2 !k-icp-shadow-sm !k-min-w-0 "
                                        onClick={() => scrollToBottom({ animation: { ...SCROLL_CUSTOM_ANIMATION_PARAMS } })}
                                    >
                                        <SvgIconButtonContent icon={ArrowBottomIcon}></SvgIconButtonContent>
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}

                    {hasIntroAnimation && !introAnimationHidden && (
                        <IvaIntroAnimation
                            ref={introAnimationRef}
                            title={introAnimationData?.firstIntroSentence}
                            subtitle={introAnimationData?.secondIntroSentence}
                            onCompleted={() => setTimeout(() => setStartMorph(true), 1000)}
                            isMobile={isMobile}
                        />
                    )}
                </StackLayout>
            </ChatProvider>
        </SpeechSynthesisContext.Provider>
    );
}

function resolveIntroAnimationData(
    messages: AILedChatComponentProps<unknown>['messages']
): { firstIntroSentence: string; secondIntroSentence?: string; restOfTheIntro?: ChatMessageBlock[] } | undefined {
    if (!messages || messages.length !== 1) return undefined;
    const firstMessage = messages[0];
    if (firstMessage.type !== ChatMessageType.Agent) return undefined;
    const firstBlock = firstMessage.blocks[0];
    if (!firstBlock || firstBlock.type !== ChatMessageBlockType.Text) return undefined;

    const secondBlock = firstMessage.blocks[1];
    const isSecondBlockText = secondBlock !== undefined && secondBlock.type === ChatMessageBlockType.Text;
    const messagesToSlice = isSecondBlockText ? 2 : 1;

    return {
        firstIntroSentence: firstBlock.data.trim() + ' ',
        secondIntroSentence: isSecondBlockText ? secondBlock.data.trim() + ' ' : undefined,
        restOfTheIntro: firstMessage.blocks.length > messagesToSlice ? firstMessage.blocks.slice(messagesToSlice) : undefined
    };
}

function groupMessageBlocks(blocks: ChatMessageBlock[]) {
    const result: (ChatMessageBlock | ChatMessageBlock[])[] = [];
    let currentTextGroup: ChatMessageBlock[] = [];

    blocks.forEach(block => {
        if (block.type === ChatMessageBlockType.Text) {
            currentTextGroup.push(block);
        } else {
            if (currentTextGroup.length > 0) {
                result.push(currentTextGroup);
                currentTextGroup = [];
            }
            result.push(block);
        }
    });

    if (currentTextGroup.length > 0) {
        result.push(currentTextGroup);
    }

    return result;
}

function trimAndCombineTextBlocks(textBlock: ChatMessageBlock[], trimStart?: boolean) {
    const combinedText = textBlock
        .filter(b => b.type === ChatMessageBlockType.Text)
        .map(b => b.data)
        .join('');

    return trimStart ? combinedText.trimStart() : combinedText;
}

type NotPersistedChatMessage =
    | WithOptional<Omit<AgentChatMessage, 'id' | 'createdOn' | 'updatedOn'>, 'blocks'>
    | Omit<UserChatMessage, 'id' | 'createdOn' | 'updatedOn'>
    | Omit<ContactChatMessage, 'id' | 'createdOn' | 'updatedOn'>;
type ChatMessageBoxProps = {
    message: ChatMessage | NotPersistedChatMessage;
    children?: ReactNode;
    enableSpeaking?: boolean;
    autoSpeak?: boolean;
    loading?: boolean;
};
type ChatMessageBoxHandle = {
    agentAvatarRef: React.RefObject<HTMLDivElement | null>;
};
const ChatMessageBox = forwardRef<ChatMessageBoxHandle, ChatMessageBoxProps>(function ChatMessageBox(
    { message, children, enableSpeaking, autoSpeak, loading },
    ref
) {
    const agentAvatarRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(
        ref,
        () => ({
            agentAvatarRef
        }),
        []
    );

    const isUserMessage = message.type !== ChatMessageType.Agent;
    const hasOptions = message && message.type === ChatMessageType.Agent && message.blocks && message.blocks.some(b => b.type === ChatMessageBlockType.Options);
    const canSpeakMessage = enableSpeaking && !isUserMessage && message !== undefined;
    const messageText = canSpeakMessage ? getMessageText(message) : undefined;
    const messageAuthor = isUserMessage ? (message.type === ChatMessageType.User ? message.user : message.contact) : undefined;
    return (
        <InterviewMessageBox
            rightAdditionalComponent={canSpeakMessage && messageText && <SpeakerComponent speakText={messageText} autoStart={autoSpeak} />}
            right={isUserMessage}
            avatar={
                isUserMessage ? (
                    messageAuthor ? (
                        <UserAvatar
                            className="k-shrink-0"
                            initials={generateInitials(2, messageAuthor.firstName, messageAuthor.lastName)}
                            picture={messageAuthor.picture}
                            colorIndex={getPreferredColorIndex('userId' in messageAuthor ? messageAuthor.userId : messageAuthor.id)}
                        />
                    ) : (
                        undefined
                    )
                ) : (
                    <AIAvatar animate={loading} ref={agentAvatarRef} className="k-shrink-0" />
                )
            }
            hideShadow
            messageBoxClassName={combineClassNames(isUserMessage ? 'k-icp-panel-base' : '!k-pr-0', hasOptions ? 'k-w-full' : '-maxw-160')}
        >
            {children}
        </InterviewMessageBox>
    );
});

function getMessageText(message: ChatMessage | NotPersistedChatMessage) {
    if ('content' in message) return message.content;
    if (!message.blocks) return undefined;

    const textFragments: string[] = [];
    for (const messageBlock of message.blocks) {
        const blockTextExtractor = messageBlockTextExtractors[messageBlock.type];
        if (!blockTextExtractor) continue;
        const blockText = blockTextExtractor(messageBlock as any);
        if (!blockText) continue;
        textFragments.push(blockText);
    }

    return textFragments.length ? textFragments.join('\n') : undefined;
}

function ChatMessageContent(
    props: {
        content: string | ChatMessageBlock[] | undefined;
        streaming: boolean;
        messageId: number | undefined;
        navigateToIdeaPath?: (pathWithinIdea: string) => void;
        keepFirstTextBlockWhiteSpaces?: boolean;
    } & ({ typing?: false } | { typing: true; onTypingEnd?: () => void; typingWaitForMore?: boolean })
) {
    let children: string | (string | ReactElement)[] | undefined;
    if (props.content === undefined) children = undefined;
    else if (typeof props.content === 'string') children = props.content;
    else {
        const groupedTextBlocks = groupMessageBlocks(props.content);
        const messageElements: (ReactElement | string)[] = [];
        let isFirstTextGroup = true;
        for (let elementIndex = 0; elementIndex < groupedTextBlocks.length; elementIndex++) {
            const blockOrTextGroup = groupedTextBlocks[elementIndex];
            if (Array.isArray(blockOrTextGroup)) {
                const textContent = trimAndCombineTextBlocks(blockOrTextGroup, !props.keepFirstTextBlockWhiteSpaces && isFirstTextGroup);
                isFirstTextGroup = false;
                messageElements.push(props.typing ? textContent : <React.Fragment key={elementIndex}>{textContent}</React.Fragment>);
                continue;
            }

            const BlockComponent = blockComponentMap[blockOrTextGroup.type];
            if (!BlockComponent) continue;

            const blockProps: BlockProps<ChatMessageBlockType> = {
                block: blockOrTextGroup,
                messageId: props.messageId,
                streaming: props.streaming,
                navigateToIdeaPath: props.navigateToIdeaPath
            };

            messageElements.push(<BlockComponent key={elementIndex} {...(blockProps as any)} />);
        }
        children = messageElements;
    }

    if (props.typing) {
        return children ? (
            <TypeComponents onTypeEnd={props.onTypingEnd} waitForMore={props.typingWaitForMore}>
                {children}
            </TypeComponents>
        ) : (
            <TypingDots />
        );
    }

    return children ? <>{children}</> : null;
}

function SpeakerComponent({ speakText, autoStart }: { speakText: string; autoStart?: boolean }) {
    const { isAvailable: speechAvailable, speak, stop } = useSpeechSynthesisContext();
    const [isSpeakingCurrentText, setIsSpeakingCurrentText] = useState<boolean>();

    const speakCurrentText = useCallback(() => {
        if (isSpeakingCurrentText) {
            stop();
            setIsSpeakingCurrentText(false);
        } else {
            speak(
                speakText,
                () => setIsSpeakingCurrentText(false),
                () => setIsSpeakingCurrentText(false)
            );
            setIsSpeakingCurrentText(true);
        }
    }, [isSpeakingCurrentText, speak, speakText, stop]);

    const autoSpeakStartedRef = useRef<boolean>();
    useEffect(() => {
        if (!autoStart || autoSpeakStartedRef.current) return;
        autoSpeakStartedRef.current = true;
        speakCurrentText();
    }, [autoStart, speakCurrentText]);

    return speechAvailable ? <SpeakerButtonComponent onSpeakerClick={speakCurrentText} selected={isSpeakingCurrentText} /> : null;
}

function SpeakerButtonComponent({ onSpeakerClick, selected, disabled }: { onSpeakerClick: () => void; selected?: boolean; disabled?: boolean }) {
    return (
        <Button
            type="button"
            fillMode="flat"
            className="k-icp-svg-icon-button !k-border-none !k-p-2"
            togglable
            selected={selected}
            disabled={disabled}
            onClick={onSpeakerClick}
        >
            <SpeakerIcon className="k-icp-icon" />
        </Button>
    );
}

type IvaIntroAnimationProps = { title?: string; subtitle?: string; onCompleted?: () => void; isMobile?: boolean };
type IvaIntroAnimationHandle = { avatarElement: HTMLElement | null; titleElement: HTMLElement | null; subtitleElement: HTMLElement | null };

const IvaIntroAnimation = forwardRef<IvaIntroAnimationHandle, IvaIntroAnimationProps>(function IvaIntroAnimation(
    { title, subtitle, onCompleted, isMobile },
    ref
) {
    const [showContent, setShowContent] = useState(false);
    const [showAvatarBorder, setShowAvatarBorder] = useState(false);
    const [showText, setShowText] = useState(false);
    const [typeTitle, setTypeTitle] = useState(false);
    const [typeSubtitle, setTypeSubtitle] = useState(false);

    useEffect(() => {
        const showContentTimeoutId = setTimeout(() => setShowContent(true), 800);
        return () => clearTimeout(showContentTimeoutId);
    }, []);

    const avatarRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);
    const subtitleRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
        ref,
        () => ({
            get avatarElement() {
                return avatarRef.current;
            },
            get titleElement() {
                return titleRef.current;
            },
            get subtitleElement() {
                return subtitleRef.current;
            }
        }),
        []
    );

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-absolute k-inset-0 k-icp-bg-component k-px-4">
            <Fade transitionEnterDuration={300} onEntered={() => setTimeout(() => setShowAvatarBorder(true), 800)} className="k-overflow-visible">
                {showContent && (
                    <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }}>
                        <AIAvatar
                            ref={avatarRef}
                            size={isMobile ? 128 : 184}
                            borderSize="k-border-2"
                            animate={!showText}
                            iconSize={isMobile ? 80 : 120}
                            hideBorder={!showAvatarBorder}
                            onAnimationIteration={() => title && setShowText(true)}
                        />
                        <Reveal transitionEnterDuration={100} onEntered={() => setTypeTitle(true)}>
                            {showText && (
                                <StackLayout
                                    orientation="vertical"
                                    align={{ horizontal: 'center', vertical: 'top' }}
                                    className="k-text-center k-pt-12 k-gap-1.5"
                                >
                                    {title && (
                                        <h1 ref={titleRef} className="k-h1 ai-text-color">
                                            <TypeText
                                                mode="letter"
                                                reserveSpace
                                                paused={!typeTitle}
                                                onTypeEnd={() => (subtitle ? setTypeSubtitle(true) : onCompleted?.())}
                                            >
                                                {title}
                                            </TypeText>
                                        </h1>
                                    )}
                                    {subtitle && (
                                        <h2 ref={subtitleRef} className="k-h2">
                                            <TypeText mode="letter" reserveSpace paused={!typeSubtitle} onTypeEnd={onCompleted}>
                                                {subtitle}
                                            </TypeText>
                                        </h2>
                                    )}
                                </StackLayout>
                            )}
                        </Reveal>
                    </StackLayout>
                )}
            </Fade>
        </StackLayout>
    );
});

const morphAnimationDuration = 600;
function useBlockElementMorph(avatarSourceElement?: HTMLElement | null, avatarTargetElement?: HTMLElement | null, paused?: boolean, onEnd?: () => void) {
    const onEndRef = useAsRef(onEnd);
    useEffect(() => {
        if (paused || !avatarSourceElement || !avatarTargetElement) return;

        avatarSourceElement.style.transition = `all ${morphAnimationDuration}ms`;
        avatarSourceElement.style.scale = String(avatarTargetElement.clientWidth / avatarSourceElement.clientWidth);

        avatarSourceElement.style.zIndex = '1';
        avatarSourceElement.style.transformOrigin = '0 0';
        const sourceElementOffset = domService.getRelativeOffset(avatarSourceElement, avatarTargetElement.offsetParent);
        avatarSourceElement.style.translate = `${avatarTargetElement.offsetLeft - sourceElementOffset.left}px ${avatarTargetElement.offsetTop -
            sourceElementOffset.top}px`;

        onEndRef.current && avatarSourceElement.addEventListener('transitionend', onEndRef.current, { once: true });
    }, [avatarSourceElement, avatarTargetElement, onEndRef, paused]);
}

function useTextElementMorph(textSourceElement?: HTMLElement | null, textTargetElement?: HTMLElement | null, paused?: boolean) {
    useEffect(() => {
        if (paused || !textSourceElement || !textTargetElement) return;

        const textElementWrapper = document.createElement('div');
        textElementWrapper.style.position = 'fixed';
        // Multiple transitions are defined to avoid breaking the text on multiple lines while animating the size of the element.
        textElementWrapper.style.transition = `all ${morphAnimationDuration}ms ease-out, width ${morphAnimationDuration /
            2}ms ease-in ${morphAnimationDuration / 2}ms, paddingInline ${morphAnimationDuration / 2}ms ease-in ${morphAnimationDuration /
            2}ms, border ${morphAnimationDuration / 2}ms ease-in ${morphAnimationDuration / 2}ms`;

        const textElementSourceWrapperRect = textSourceElement.getBoundingClientRect();
        textElementWrapper.style.top = textElementSourceWrapperRect.top + 'px';
        textElementWrapper.style.left = textElementSourceWrapperRect.left + 'px';
        textElementWrapper.style.width = textElementSourceWrapperRect.width + 'px';
        textElementWrapper.style.height = textElementSourceWrapperRect.height + 'px';
        textElementWrapper.style.padding = '0px';
        textElementWrapper.style.borderColor = 'transparent';

        const sourceTextElementCopy = textSourceElement.cloneNode(true) as HTMLElement;
        sourceTextElementCopy.style.display = 'inline';
        sourceTextElementCopy.style.transition = `all ${morphAnimationDuration}ms ease-out, margin-left ${morphAnimationDuration /
            2}ms ease-in ${morphAnimationDuration / 2}ms`;
        textElementWrapper.appendChild(sourceTextElementCopy);

        const textElementWrapperParent = textTargetElement.offsetParent ?? document.body;
        textElementWrapperParent.appendChild(textElementWrapper);

        textSourceElement.style.visibility = 'hidden';

        // The timeout is added to avoid flickering when the element is removed
        textElementWrapper.addEventListener('transitionend', () => setTimeout(() => textElementWrapperParent.removeChild(textElementWrapper), 20), {
            once: true
        });

        window.requestAnimationFrame(() => {
            const textTargetElementParent = textTargetElement.parentElement;
            if (!textTargetElementParent) throw new Error();

            const textElementTargetWrapperRect = textTargetElementParent.getBoundingClientRect();
            textElementWrapper.style.top = textElementTargetWrapperRect.top + 'px';
            textElementWrapper.style.left = textElementTargetWrapperRect.left + 'px';
            textElementWrapper.style.width = textElementTargetWrapperRect.width + 'px';
            textElementWrapper.style.height = textElementTargetWrapperRect.height + 'px';

            const textTargetElementParentComputedStyles = window.getComputedStyle(textTargetElementParent);
            textElementWrapper.style.padding = textTargetElementParentComputedStyles.padding;
            textElementWrapper.style.border = textTargetElementParentComputedStyles.border;
            textElementWrapper.style.borderColor = 'transparent';

            const textTargetElementParentOffsetLeft = textTargetElementParent.offsetLeft;
            // Although the offsetLeft is rounded to whole number we are not using textTargetElement.getBoundingClientRect().left to get the textTargetElement left offset,
            // because if the inline element is on multiple lines, the offsetLeft returns the position of the first character on the first row but getBoundingClientRect().left
            // returns the offset of the bounding box of all lines which is incorrect for ou
            const textTargetElementOffsetLeft = domService.getRelativeOffset(textTargetElement, textTargetElementParent.offsetParent).left;

            const textTargetElementComputedStyles = window.getComputedStyle(textTargetElement);
            sourceTextElementCopy.style.font = textTargetElementComputedStyles.font;
            sourceTextElementCopy.style.color = textTargetElementComputedStyles.color;
            sourceTextElementCopy.style.marginLeft =
                textTargetElementOffsetLeft -
                textTargetElementParentOffsetLeft -
                parseFloat(textTargetElementParentComputedStyles.paddingLeft) -
                parseFloat(textTargetElementParentComputedStyles.borderLeftWidth) +
                'px';
        });
    }, [paused, textSourceElement, textTargetElement]);
}
