import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import speechSynthesisVoices from '../data/speechSynthesisVoices/en.json';

interface SpeechOptions {
    voiceOptions?: VoiceData;
    volume?: number;
    lang?: string;
    gender?: string;
}

interface VoiceData {
    voice: SpeechSynthesisVoice;
    rate?: number;
    pitch?: number;
    provider?: string;
}

interface UseSpeechSynthesisReturn {
    voices: SpeechSynthesisVoice[];
    speak: (text: string, onEnd?: () => void, onError?: (errorCode: SpeechSynthesisErrorCode) => void) => void;
    stop: () => void;
    isAvailable: boolean;
    isSpeaking: boolean;
}

const DEFAULT_OPTIONS: Required<Omit<SpeechOptions, 'voiceOptions' | 'lang'>> = {
    volume: 1,
    gender: 'female'
};

type QualityLevel = 'veryHigh' | 'high' | 'normal' | 'low';
const qualityOrder: Record<QualityLevel, number> = { veryHigh: 4, high: 3, normal: 2, low: 1 };

const findPreferredVoice = (voices: SpeechSynthesisVoice[], lang: string = 'en-US', gender: string = 'female') => {
    const filteredVoiceDefinitions = speechSynthesisVoices.voices.filter(v => v.language === lang && v.gender === gender);

    // Sort voices by quality, prioritizing higher quality voices
    const sortedVoices = filteredVoiceDefinitions.sort((a, b) => {
        const aHighestQuality = Math.max(...a.quality.map(q => qualityOrder[q as QualityLevel] || 0));
        const bHighestQuality = Math.max(...b.quality.map(q => qualityOrder[q as QualityLevel] || 0));

        if (bHighestQuality !== aHighestQuality) {
            return bHighestQuality - aHighestQuality;
        }

        return 0;
    });

    for (const voiceDef of sortedVoices) {
        const match = voices.find(voice => voice.name === voiceDef.name || (voiceDef.altNames && voiceDef.altNames.includes(voice.name)));
        if (match) return match;
    }

    return undefined;
};

const getPreferredVoiceData = (voice: SpeechSynthesisVoice) => {
    const voiceDefinition = speechSynthesisVoices.voices.find(v => v.name === voice.name);
    if (!voiceDefinition) return undefined;
    const provider =
        voiceDefinition.name.toLocaleLowerCase().includes('google') || voiceDefinition.altNames?.some(name => name.toLocaleLowerCase().includes('google'))
            ? 'google'
            : undefined;
    return { rate: voiceDefinition.rate, pitch: voiceDefinition.pitch, provider };
};

const TALKING_FIX_GOOGLE_INTERVAL = 10000;

export const useSpeechSynthesis = (options: SpeechOptions = {}): UseSpeechSynthesisReturn => {
    const timerGoogleFreezingTalkingFix = useRef<NodeJS.Timeout>();
    const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const voiceDataRef = useRef<VoiceData>();

    const settings = { ...DEFAULT_OPTIONS, ...options };

    function isSpeechSynthesisAvailable() {
        return typeof window !== 'undefined' && window.speechSynthesis !== undefined;
    }

    const isAvailable = useMemo(() => {
        return isSpeechSynthesisAvailable() && isInitialized && voices.length > 0;
    }, [isInitialized, voices.length]);

    // Initialize speech synthesis and load voices
    useEffect(() => {
        if (!isSpeechSynthesisAvailable()) return;

        const synth = window.speechSynthesis;

        const loadVoices = () => {
            const availableVoices = synth.getVoices();

            let selectedVoice: VoiceData | undefined = undefined;
            if (settings.voiceOptions) {
                selectedVoice = settings.voiceOptions;
            } else {
                const preferredVoice = findPreferredVoice(availableVoices, settings.lang, settings.gender);
                const preferredVoiceData = preferredVoice ? getPreferredVoiceData(preferredVoice) : undefined;
                const preferredVoiceState: VoiceData | undefined = preferredVoice
                    ? { voice: preferredVoice, rate: preferredVoiceData?.rate, pitch: preferredVoiceData?.pitch, provider: preferredVoiceData?.provider }
                    : undefined;

                selectedVoice = preferredVoiceState;
            }
            voiceDataRef.current = selectedVoice;
            setVoices(availableVoices);
            setIsInitialized(availableVoices.length > 0);
        };

        loadVoices();

        if (synth.onvoiceschanged !== undefined) {
            synth.addEventListener('voiceschanged', loadVoices);
        }

        return () => {
            synth.removeEventListener('voiceschanged', loadVoices);
            synth.cancel();
        };
    }, [settings.gender, settings.lang, settings.voiceOptions]);

    const stopGoogleFreezingTalkingFix = useCallback(function() {
        if (!timerGoogleFreezingTalkingFix.current) return;
        clearInterval(timerGoogleFreezingTalkingFix.current);
        timerGoogleFreezingTalkingFix.current = undefined;
    }, []);

    const speak = useCallback(
        (text: string, onEnd?: () => void, onError?: (errorCode: SpeechSynthesisErrorCode) => void) => {
            const synth = window.speechSynthesis;
            if (!text) return;

            // Cancel any ongoing speech
            synth.cancel();
            stopGoogleFreezingTalkingFix();

            const utterance = new SpeechSynthesisUtterance(text);
            utterance.volume = settings.volume;
            utterance.voice = voiceDataRef.current?.voice || null;
            utterance.rate = voiceDataRef.current?.rate || 1;
            utterance.pitch = voiceDataRef.current?.pitch || 1;

            if (settings.lang) utterance.lang = settings.lang;

            utterance.onstart = () => {
                setIsSpeaking(true);
            };

            utterance.onend = () => {
                stopGoogleFreezingTalkingFix();

                setIsSpeaking(false);
                onEnd?.();
            };

            utterance.onerror = event => {
                setIsSpeaking(false);
                onError?.(event.error);
            };

            synth.speak(utterance);

            if (voiceDataRef.current?.provider === 'google') {
                timerGoogleFreezingTalkingFix.current = setInterval(() => {
                    window.speechSynthesis.pause();
                    window.speechSynthesis.resume();
                }, TALKING_FIX_GOOGLE_INTERVAL);
            }
        },
        [settings.lang, settings.volume, stopGoogleFreezingTalkingFix]
    );

    const stop = useCallback(() => {
        window.speechSynthesis.cancel();
        stopGoogleFreezingTalkingFix();
    }, [stopGoogleFreezingTalkingFix]);

    return {
        voices,
        isAvailable,
        isSpeaking,
        speak,
        stop
    };
};

// Create the context
export const SpeechSynthesisContext = createContext<ReturnType<typeof useSpeechSynthesis> | null>(null);

interface SpeechSynthesisProviderProps {
    children: React.ReactNode;
    options?: SpeechOptions;
}

export const SpeechSynthesisProvider: React.FC<SpeechSynthesisProviderProps> = ({ children, options }) => {
    const speech = useSpeechSynthesis(options);

    return <SpeechSynthesisContext.Provider value={speech}>{children}</SpeechSynthesisContext.Provider>;
};

// Custom hook to use the speech synthesis context
export const useSpeechSynthesisContext = () => {
    const context = useContext(SpeechSynthesisContext);
    if (!context) {
        throw new Error('useSpeechSynthesisContext must be used within a SpeechSynthesisProvider');
    }
    return context;
};
